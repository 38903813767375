import { BetaSchemaForm, FormInstance } from '@ant-design/pro-components';
import { Button, Card, message, Popconfirm, Spin, Dropdown, Menu } from 'antd';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fields as eventFields } from '../../data/events';
import { updateEvent } from '../../methods/events';
import { defaultFormProps } from '../../utils/defaultFormProps';
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Event } from '../../globals';
import showEditActions from '../../utils/showEditActions';
import type { MenuProps } from 'antd';
import { API } from 'aws-amplify';

type Props = {
  event: Event;
};

const EventCard: React.FC<Props> = ({ event }) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <EditOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            setMenuVisible(false);
            setFormModalVisible(true);

            e.stopPropagation();
          }}
        >
          Edit
        </a>
      ),
    },
    {
      key: '2',
      danger: true,
      label: (
        <Popconfirm
          title="Are you sure?"
          onConfirm={async () => {
            await API.del('GooseRest', `/team/remove-event`, {
              body: { eventId: event.id },
            });
            await updateEvent({
              ...event,
              deletedAt: new Date().toISOString(),
            });

            message.success('Event Removed');
          }}
        >
          <a>Remove</a>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
    },
  ];
  return (
    <Card
      hoverable
      onClick={(e) => {
        e.stopPropagation();
        // dispatch(updateCurrentGroup(group));
        history.push(`/events/${event.id}/groups`);
      }}
      // title={event.title}
      // extra={showEditActions() && extra}
      // actions={[
      //   <ArrowRightOutlined
      //     onClick={() => {
      //       history.push(`/events/${event.id}/groups`);
      //     }}
      //   />,
      // ]}
      key={event.id}
      bordered={false}
      style={{ textAlign: 'left', minHeight: 150 }}
    >
      <h3
        style={{
          fontFamily: 'Segoe UI Bold',
          fontSize: 22,
          marginBottom: 2,
        }}
      >
        {event.title}
      </h3>
      {event.startDate && (
        <p>
          {moment(event.startDate, 'DD/MM/YYYY HH:mm').format("Do MMMM 'YY")}
        </p>
      )}
      {typeof event.groupCount === 'undefined' && <Spin />}
      {typeof event.groupCount !== 'undefined' && (
        <span style={{ color: '#888' }}>
          {event.groupCount} Group{event.groupCount === 1 ? '' : 's'}
        </span>
      )}
      {showEditActions() && (
        <div
          style={{
            position: 'absolute',
            top: 12,
            right: 12,
            display: 'block',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Dropdown
            menu={{ items }}
            open={menuVisible}
            trigger={['click']}
            placement="bottomLeft"
            onOpenChange={setMenuVisible}
            arrow
          >
            <Button shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
          <BetaSchemaForm
            {...defaultFormProps(formRef, formLoading)}
            formRef={formRef}
            visible={formModalVisible}
            onVisibleChange={(isVisible: boolean) => {
              setFormModalVisible(isVisible);
            }}
            onFinish={async (values: Event) => {
              setFormLoading(true);
              await updateEvent({ ...event, ...values });
              setTimeout(() => {
                setFormModalVisible(false);
                setFormLoading(false);
                message.success('Event Updated');
              }, 500);
            }}
            columns={
              eventFields({ values: event, loading: formLoading }) as any
            }
          />
        </div>
      )}
      {/* <p>96 People</p> */}
    </Card>
  );
};

export default EventCard;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from './store';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: 'f42c60dcd8de7b44a1d635f4e4cc415c',
  plugins: [new BugsnagPluginReact()],
  appVersion: require('../package.json').version,
  onError: function (event) {
    if (localStorage.getItem('goose:user')) {
      const user = JSON.parse(localStorage.getItem('goose:user') as string);
      event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
    }
  },
});

const ErrorBoundary: any =
  Bugsnag?.getPlugin('react')?.createErrorBoundary(React);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

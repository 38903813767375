import DataTable from '../../components/DataTable';

import { columns as teamColumns, fields as teamFields } from '../../data/team';
import FormButton from '../../components/FormButton';
import { addTeamMember, updateTeamMember } from '../../methods/team';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updateTeam, upsertTeamMember } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { PlusOutlined } from '@ant-design/icons';

import { PageHeader } from '@ant-design/pro-layout';

import { Button, Form, Input, message, RadioChangeEvent } from 'antd';
import { Radio, Space, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getProfile } from '../../graphql/queries';
import { Profile } from '../../API';
import { Optional } from '../../globals';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../reducers/userSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 10 },
};

const UpdateProfileScene: React.FC = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const { profile } = user;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const now = new Date().toISOString();
      const userProfile: Profile = {
        ...profile,
        firstName: values.firstName,
        lastName: values.lastName,
        updatedAt: now,
      };

      updateTeamMember(userProfile);

      dispatch(updateProfile(userProfile));

      setLoading(false);

      message.success('Profile Updated');
    } catch (e: any) {
      setLoading(false);
      message.error(e.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={profile}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <br />
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateProfileScene;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const getGroupCategory = /* GraphQL */ `query GetGroupCategory($id: ID!) {
  getGroupCategory(id: $id) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupCategoryQueryVariables,
  APITypes.GetGroupCategoryQuery
>;
export const listGroupCategories = /* GraphQL */ `query ListGroupCategories(
  $filter: ModelGroupCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupCategoriesQueryVariables,
  APITypes.ListGroupCategoriesQuery
>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const getProfileEvent = /* GraphQL */ `query GetProfileEvent($id: ID!) {
  getProfileEvent(id: $id) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileEventQueryVariables,
  APITypes.GetProfileEventQuery
>;
export const listProfileEvents = /* GraphQL */ `query ListProfileEvents(
  $filter: ModelProfileEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfileEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfileEventsQueryVariables,
  APITypes.ListProfileEventsQuery
>;
export const getLoginChallengeToken = /* GraphQL */ `query GetLoginChallengeToken($id: ID!) {
  getLoginChallengeToken(id: $id) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLoginChallengeTokenQueryVariables,
  APITypes.GetLoginChallengeTokenQuery
>;
export const listLoginChallengeTokens = /* GraphQL */ `query ListLoginChallengeTokens(
  $filter: ModelLoginChallengeTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listLoginChallengeTokens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      createdAt
      expireAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLoginChallengeTokensQueryVariables,
  APITypes.ListLoginChallengeTokensQuery
>;
export const groupsByEventId = /* GraphQL */ `query GroupsByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  groupsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupsByEventIdQueryVariables,
  APITypes.GroupsByEventIdQuery
>;
export const profileEventsByOwnerId = /* GraphQL */ `query ProfileEventsByOwnerId(
  $owner: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProfileEventFilterInput
  $limit: Int
  $nextToken: String
) {
  profileEventsByOwnerId(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfileEventsByOwnerIdQueryVariables,
  APITypes.ProfileEventsByOwnerIdQuery
>;
export const getSlot = /* GraphQL */ `query GetSlot($id: ID!) {
  getSlot(id: $id) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSlotQueryVariables, APITypes.GetSlotQuery>;
export const listSlots = /* GraphQL */ `query ListSlots(
  $filter: ModelSlotFilterInput
  $limit: Int
  $nextToken: String
) {
  listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSlotsQueryVariables, APITypes.ListSlotsQuery>;
export const slotsByPersonId = /* GraphQL */ `query SlotsByPersonId(
  $personId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSlotFilterInput
  $limit: Int
  $nextToken: String
) {
  slotsByPersonId(
    personId: $personId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SlotsByPersonIdQueryVariables,
  APITypes.SlotsByPersonIdQuery
>;
export const slotsByGroupId = /* GraphQL */ `query SlotsByGroupId(
  $groupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSlotFilterInput
  $limit: Int
  $nextToken: String
) {
  slotsByGroupId(
    groupId: $groupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SlotsByGroupIdQueryVariables,
  APITypes.SlotsByGroupIdQuery
>;
export const slotsByEventId = /* GraphQL */ `query SlotsByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSlotFilterInput
  $limit: Int
  $nextToken: String
) {
  slotsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SlotsByEventIdQueryVariables,
  APITypes.SlotsByEventIdQuery
>;
export const getPerson = /* GraphQL */ `query GetPerson($id: ID!) {
  getPerson(id: $id) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPersonQueryVariables, APITypes.GetPersonQuery>;
export const listPeople = /* GraphQL */ `query ListPeople(
  $filter: ModelPersonFilterInput
  $limit: Int
  $nextToken: String
) {
  listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPeopleQueryVariables,
  APITypes.ListPeopleQuery
>;
export const getFlight = /* GraphQL */ `query GetFlight($id: ID!) {
  getFlight(id: $id) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFlightQueryVariables, APITypes.GetFlightQuery>;
export const listFlights = /* GraphQL */ `query ListFlights(
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      flightType
      leavingFrom
      arrivingAt
      departureDate
      arrivalDate
      flightNumber
      includeInTransferList
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightsQueryVariables,
  APITypes.ListFlightsQuery
>;
export const flightsBySlotId = /* GraphQL */ `query FlightsBySlotId(
  $slotId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  flightsBySlotId(
    slotId: $slotId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      flightType
      leavingFrom
      arrivingAt
      departureDate
      arrivalDate
      flightNumber
      includeInTransferList
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FlightsBySlotIdQueryVariables,
  APITypes.FlightsBySlotIdQuery
>;
export const flightsByGroupId = /* GraphQL */ `query FlightsByGroupId(
  $groupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  flightsByGroupId(
    groupId: $groupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      flightType
      leavingFrom
      arrivingAt
      departureDate
      arrivalDate
      flightNumber
      includeInTransferList
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FlightsByGroupIdQueryVariables,
  APITypes.FlightsByGroupIdQuery
>;
export const flightsByEventId = /* GraphQL */ `query FlightsByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  flightsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      flightType
      leavingFrom
      arrivingAt
      departureDate
      arrivalDate
      flightNumber
      includeInTransferList
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FlightsByEventIdQueryVariables,
  APITypes.FlightsByEventIdQuery
>;
export const getAccommodation = /* GraphQL */ `query GetAccommodation($id: ID!) {
  getAccommodation(id: $id) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccommodationQueryVariables,
  APITypes.GetAccommodationQuery
>;
export const listAccommodations = /* GraphQL */ `query ListAccommodations(
  $filter: ModelAccommodationFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccommodations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      roomType
      notes
      checkinDate
      checkoutDate
      copiedFromFlightId
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccommodationsQueryVariables,
  APITypes.ListAccommodationsQuery
>;
export const accommodationBySlotId = /* GraphQL */ `query AccommodationBySlotId(
  $slotId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccommodationFilterInput
  $limit: Int
  $nextToken: String
) {
  accommodationBySlotId(
    slotId: $slotId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      roomType
      notes
      checkinDate
      checkoutDate
      copiedFromFlightId
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccommodationBySlotIdQueryVariables,
  APITypes.AccommodationBySlotIdQuery
>;
export const accommodationByGroupId = /* GraphQL */ `query AccommodationByGroupId(
  $groupId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccommodationFilterInput
  $limit: Int
  $nextToken: String
) {
  accommodationByGroupId(
    groupId: $groupId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      roomType
      notes
      checkinDate
      checkoutDate
      copiedFromFlightId
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccommodationByGroupIdQueryVariables,
  APITypes.AccommodationByGroupIdQuery
>;
export const accommodationByEventId = /* GraphQL */ `query AccommodationByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccommodationFilterInput
  $limit: Int
  $nextToken: String
) {
  accommodationByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      slotId
      slot {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      roomType
      notes
      checkinDate
      checkoutDate
      copiedFromFlightId
      createdAt
      updatedAt
      deletedAt
      createdBy
      lastUpdatedBy
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccommodationByEventIdQueryVariables,
  APITypes.AccommodationByEventIdQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listProfiles = /* GraphQL */ `query ListProfiles(
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      role
      emailWaitingVerification {
        id
        token
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      eventIds
      events {
        items {
          id
          owner
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesQueryVariables,
  APITypes.ListProfilesQuery
>;
export const getVerificationToken = /* GraphQL */ `query GetVerificationToken($id: ID!) {
  getVerificationToken(id: $id) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVerificationTokenQueryVariables,
  APITypes.GetVerificationTokenQuery
>;
export const listVerificationTokens = /* GraphQL */ `query ListVerificationTokens(
  $filter: ModelVerificationTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listVerificationTokens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVerificationTokensQueryVariables,
  APITypes.ListVerificationTokensQuery
>;
export const verificationByToken = /* GraphQL */ `query VerificationByToken(
  $token: String!
  $sortDirection: ModelSortDirection
  $filter: ModelVerificationTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  verificationByToken(
    token: $token
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VerificationByTokenQueryVariables,
  APITypes.VerificationByTokenQuery
>;

import { ProFormColumnsType } from '@ant-design/pro-components';
import { Accommodation, Flight, Person, Slot } from '../API';
import type { ColumnsType } from 'antd/es/table';

import FormButton from '../components/FormButton';
import DeleteButton from '../components/DeleteButton';
import { removePerson, updatePerson } from '../methods/people';
import { DataType } from '../globals';
import { Link, useHistory } from 'react-router-dom';
import { Button, Space, Tag } from 'antd';
import showEditActions from '../utils/showEditActions';
import { getColumnSearchProps } from '../utils/getColumnSearchProps';
import FileUpload from '../components/FileUpload';
import ImagePreview from '../components/ImagePreview';
import { defaultSpecificationItem } from '../utils/exportConfig';
import _ from 'lodash';
const STRING_TO_FORCE_TO_END = 'xxxxxxxxxxxxxxxxx';

type DataItem = {
  name: string;
  state: string;
};

type Props = {
  values?: Person | null;
  loading: boolean;
  data?: any;
  options?: any;
};

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  values,
  loading,
  data,
  options,
}) => [
  {
    title: 'Title',
    dataIndex: 'title',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.title,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.firstName,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.lastName,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Publications',
    dataIndex: 'publications',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.publications,
    },
  },
  {
    title: 'Country',
    dataIndex: 'country',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.country,
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.city,
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.phone,
    },
  },

  {
    title: 'Email',
    dataIndex: 'email',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.email,
    },
  },

  {
    title: 'Passport',
    dataIndex: 'passportFileUrl',
    initialValue: values?.passportFileUrl,
    renderFormItem: (schema, config) => <FileUpload directory="passports" />,
    transform: (value) => {
      return { passportFileUrl: value };
    },
    width: 'md',
    colProps: {
      xs: 24,
      md: 12,
    },
  },
  {
    title: 'Driving License',
    dataIndex: 'drivingLicenseFileUrl',
    initialValue: values?.drivingLicenseFileUrl,
    renderFormItem: (schema, config) => (
      <FileUpload directory="driving-licenses" />
    ),
    transform: (value) => {
      return { drivingLicenseFileUrl: value };
    },
    width: 'md',
    colProps: {
      xs: 24,
      md: 12,
    },
  },
  {
    title: 'Dietary Requirements',
    dataIndex: 'dietaryRequirements',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.dietaryRequirements,
    },
  },
  {
    title: 'Vaccination Status',
    dataIndex: 'vaccinationStatus',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.vaccinationStatus,
    },
  },
];

export const columns: () => ColumnsType<DataType> = () => {
  const items: ColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: ['title'],
      ...getColumnSearchProps(['title'], 'Title'),
      sorter: (a: any, b: any) =>
        (a.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.title || STRING_TO_FORCE_TO_END
        ),
      width: 100,
    },
    {
      title: 'Full Name',
      ...getColumnSearchProps(null, 'Full Name'),
      onFilter: (value: any, record: any) => {
        return `${record.firstName} ${record.lastName}`
          .toLowerCase()
          .replace(/ /g, '')
          .includes((value as string).toLowerCase().replace(/ /g, ''));
      },
      sorter: (a: any, b: any) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        ),

      width: 200,
      render(value, record: any, index) {
        return (
          <Link to={`/people/${record?.id}`}>
            {record?.firstName} {record?.lastName}
          </Link>
        );
      },
    },
    {
      title: 'Publications',
      dataIndex: 'publications',
      ...getColumnSearchProps(['publications'], 'Publications'),
      sorter: (a: any, b: any) =>
        (a.publications || STRING_TO_FORCE_TO_END).localeCompare(
          b.publications || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      ...getColumnSearchProps(['country'], 'Country'),
      sorter: (a: any, b: any) =>
        (a.country || STRING_TO_FORCE_TO_END).localeCompare(
          b.country || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'City',
      dataIndex: 'city',
      ...getColumnSearchProps(['city'], 'City'),
      sorter: (a: any, b: any) =>
        (a.city || STRING_TO_FORCE_TO_END).localeCompare(
          b.city || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      ...getColumnSearchProps(['email'], 'Email Address'),
      sorter: (a: any, b: any) =>
        (a.email || STRING_TO_FORCE_TO_END).localeCompare(
          b.email || STRING_TO_FORCE_TO_END
        ),
      width: 250,
      render(email) {
        return email ? <a href={`mailto:${email}`}>{email}</a> : null;
      },
    },
    {
      title: 'Mobile No.',
      dataIndex: 'phone',
      ...getColumnSearchProps(['phone'], 'Mobile No.'),
      sorter: (a: any, b: any) =>
        (a.phone || STRING_TO_FORCE_TO_END).localeCompare(
          b.phone || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'Events',
      dataIndex: ['slots', 'items'],
      render(value, record, index) {
        let events = null;
        if ('slots' in record) {
          const uniqueEvents = _.uniqBy(record?.slots?.items.filter(o => !o?.deletedAt), 'eventId');
          events = uniqueEvents?.map((slot: any) => {
            return (
              <Tag key={slot.id}>
                <Link to={`/events/${slot.eventId}/groups`}>
                  {slot.event?.title}
                </Link>
              </Tag>
            );
          });
        }

        return <div>{events}</div>;
      },
      ...getColumnSearchProps(['slots', 'items'], 'Events', {
        onFilter: (value: any, record: any) => {
          let foundMatch = false;
          record?.slots?.items?.filter((o:Slot) => !o?.deletedAt)?.forEach((slot: Slot) => {
            if (slot.event?.title) {
              if (
                slot.event?.title
                  .toString()
                  .toLowerCase()
                  .includes((value as string).toLowerCase())
              ) {
                foundMatch = true;
              }
            }
          });

          return foundMatch;
        },
      }),
      width: 300,
    },
    {
      title: 'Passport',
      dataIndex: 'passportFileUrl',
      render(value) {
        if (!value) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImagePreview src={value} />
          </div>
        );
      },
      width: 150,
    },
    {
      title: 'Driving License',
      dataIndex: 'drivingLicenseFileUrl',
      render(value) {
        if (!value) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImagePreview src={value} />
          </div>
        );
      },
      width: 200,
    },
    {
      title: 'Dietary Requirements',
      dataIndex: 'dietaryRequirements',
      ...getColumnSearchProps(
        ['person', 'dietaryRequirements'],
        'Dietary Requirements'
      ),
      sorter: (a: any, b: any) =>
        (a.dietaryRequirements || STRING_TO_FORCE_TO_END).localeCompare(
          b.dietaryRequirements || STRING_TO_FORCE_TO_END
        ),
      width: 220,
    },
    {
      title: 'Vaccination Status',
      dataIndex: 'vaccinationStatus',
      ...getColumnSearchProps(
        ['person', 'vaccinationStatus'],
        'Vaccination Status'
      ),
      sorter: (a: any, b: any) =>
        (a.vaccinationStatus || STRING_TO_FORCE_TO_END).localeCompare(
          b.vaccinationStatus || STRING_TO_FORCE_TO_END
        ),
      width: 220,
    },
  ];

  if (showEditActions()) {
    items.push({
      key: 'operation',
      fixed: 'right',
      width: 102,
      render: (item) => {
        return (
          <Space style={{ float: 'right' }}>
            <FormButton
              fields={fields}
              item={item}
              successMessage="Person updated"
              action={updatePerson}
            />
            <DeleteButton
              item={item}
              successMessage="Person removed"
              action={removePerson}
            />
          </Space>
        );
      },
    });
  }

  return items;
};

export const specification = [
  { key: 'title', label: 'Title', width: 40 },
  { key: 'firstName', label: 'First Name', width: 40 },
  { key: 'lastName', label: 'Last Name', width: 40 },
  { key: 'publications', label: 'Publications', width: 40 },
  { key: 'country', label: 'Country', width: 40 },
  { key: 'city', label: 'City', width: 40 },
  { key: 'email', label: 'Email Address', width: 40 },
  { key: 'phone', label: 'Mobile No.', width: 40 },
  { key: 'dietaryRequirements', label: 'Dietary Requirements', width: 40 },
  { key: 'vaccinationStatus', label: 'Vaccination Status', width: 40 },
];

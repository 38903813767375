import { PageHeader } from '@ant-design/pro-layout';
import { Button, notification, Space } from 'antd';

import DataTable from '../../components/DataTable';

import { columns as teamColumns, fields as teamFields } from '../../data/team';
import FormButton from '../../components/FormButton';
import { addTeamMember } from '../../methods/team';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updateTeam, upsertTeamMember } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { PlusOutlined } from '@ant-design/icons';
import { onUpdateAnyProfile } from '../../graphql/subscriptions';

type Props = {
  match: any;
};

const TeamScene: React.FC<Props> = (props) => {
  const events = useAppSelector((state) => state.events);
  const { tablesConfigs } = events;

  return (
    <ReduxDataProvider
      query="listProfiles"
      updateAction={updateTeam as any}
      upsertAction={upsertTeamMember}
      subscription={onUpdateAnyProfile}
      stateSelector="team"
    >
      {({ data, loading }) => (
        <PageHeader>
          <Space align="center">
            <h1 style={{ margin: 0, padding: 0, fontSize: '1.5em' }}>Team</h1>

            {showEditActions() && (
              <FormButton
                fields={teamFields}
                successMessage="Team Member created"
                action={addTeamMember}
                renderButton={(setFormModalVisible) => (
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setFormModalVisible(true);
                    }}
                  />
                )}
              />
            )}
          </Space>
          <br />
          <br />
          <div className="site-card-wrapper">
            <DataTable
              columns={teamColumns()}
              data={!data.hasFetched ? [] : data?.items}
              tableConfig={tablesConfigs.people}
              tableConfigKey="team"
              loading={loading}
            />
          </div>
        </PageHeader>
      )}
    </ReduxDataProvider>
  );
};

export default TeamScene;

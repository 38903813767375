import { PageHeader } from '@ant-design/pro-layout';
import { Button, Spin, Tabs, Space, Collapse, Table, Segmented } from 'antd';
import { Route, Switch, useHistory } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import type { ColumnsType } from 'antd/es/table';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import {
  columns as slotColumns,
  fields as slotFields,
  specification as slotSpecification,
  transformKeys as slotTransformKeys,
} from '../../data/slots';
import { fields as peopleFields } from '../../data/people';
import {
  columns as accommodationColumns,
  fields as accommodationFields,
  specification as accommodationSpecification,
  transformKeys as accommodationTransformKeys,
} from '../../data/accommodation';
import {
  columns as flightColumns,
  fields as flightFields,
  specification as flightSpecification,
  transformKeys as flightTransformKeys,
  transferSpecification,
  transferTransformKeys,
} from '../../data/flights';
import FormButton from '../../components/FormButton';
import { addFlight } from '../../methods/flights';
import React, { useEffect, useState } from 'react';
import { getGroupById } from '../../methods/groups';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  updateCurrentGroup,
  updateCurrentGroupAccomodation,
  updateCurrentGroupFlights,
  updateCurrentGroupSlots,
  upsertAccommodationItem,
  upsertFlightItem,
  upsertSlotItem,
} from '../../reducers/eventsSlice';
import {
  onUpdateAnyFlight,
  onUpdateAnySlot,
  onUpdateAnyAccommodation,
} from '../../graphql/subscriptions';
import { addSlot } from '../../methods/slots';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import showEditActions from '../../utils/showEditActions';
import { addAccommodation } from '../../methods/accommodation';
import moment from 'moment';
import PersonScene from '../PersonScene';

import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { addPerson } from '../../methods/people';
import { exportAccommodationData, exportData } from '../../utils/exportData';
import {
  accommodationByGroupId,
  flightsByGroupId,
  slotsByGroupId,
} from '../../graphql/queries';
import Loading from '../../components/Loading';
interface DataType {
  key: string;
  date: string;
  people: number;
}
const { Panel } = Collapse;
const { TabPane } = Tabs;

type Props = {
  match: any;
};

const GroupsScene: React.FC<Props> = React.memo((props) => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState<any>();

  useEffect(() => {
    const fetchGroup = async () => {
      const groupItem = await getGroupById(props.match.params.id);
      dispatch(updateCurrentGroup(groupItem));
    };
    fetchGroup();
  }, [props.match.params.id]);

  const events = useAppSelector((state) => state.events);
  const { currentEvent, currentGroup, tablesConfigs, searchQueries } = events;
  const history = useHistory();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push(`/events/${currentEvent?.id}/groups`)}
        title={
          <h2
            style={{
              fontFamily: 'Segoe UI Bold',
              margin: 0,
              lineHeight: 2,
              fontSize: 26,
            }}
          >
            {currentGroup?.title}
          </h2>
        }
        extra={
          currentTab === 'flights' ? (
            <Space wrap>
              <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    loading={loading}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    onClick={async () => {
                      setLoading(true);
                      const data = {
                        queryProps: {
                          query: flightsByGroupId,
                          variables: {
                            groupId: currentGroup?.id,
                          },
                        },
                        specification: transferSpecification,
                        transformKeys: transferTransformKeys,
                        name: `Transfer Schedule`,
                      };

                      await exportData({
                        pages: [data],
                        fileName: `${currentGroup?.title}-transfer-schedule.xlsx`,
                        isTransferList: true,
                      });
                      setLoading(false);
                    }}
                  >
                    Export Transfer List
                  </Button>
                )}
              </Loading>
              {/* <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    loading={loading}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    onClick={async () => {
                      setLoading(true);
                      const data = {
                        queryProps: {
                          query: flightsByGroupId,
                          variables: {
                            groupId: currentGroup?.id,
                          },
                        },
                        specification: transferSpecification,
                        transformKeys: transferTransformKeys,
                        name: `Transfer Schedule`,
                      };

                      await exportData({
                        pages: [data],
                        fileName: `${currentGroup?.title}-transfer-schedule-yvr.xlsx`,
                        isTransferList: true,
                        leavingFrom: 'YVR',
                        arrivingAt: 'YVR'
                      });
                      setLoading(false);
                    }}
                  >
                    Export Transfer List (YVR)
                  </Button>
                )}
              </Loading> */}
              <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    loading={loading}
                    onClick={async () => {
                      setLoading(true);
                      const pages = [
                        {
                          queryProps: {
                            query: flightsByGroupId,
                            variables: {
                              groupId: currentGroup?.id,
                            },
                          },
                          name: currentGroup?.title,
                          specification: flightSpecification,
                          transformKeys: flightTransformKeys,
                        },
                      ];
                      await exportData({
                        pages,
                        fileName: `${currentGroup?.title}-flights.xlsx`,
                      });
                      setLoading(false);
                    }}
                  >
                    Export Flights
                  </Button>
                )}
              </Loading>
            </Space>
          ) : currentTab === 'people' ? (
            <Space>
              <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    loading={loading}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    onClick={async () => {
                      setLoading(true);
                      const pages = [
                        {
                          queryProps: {
                            query: slotsByGroupId,
                            variables: {
                              groupId: currentGroup?.id,
                            },
                          },
                          name: currentGroup?.title,
                          specification: slotSpecification,
                          transformKeys: slotTransformKeys,
                        },
                      ];
                      await exportData({
                        pages,
                        fileName: `${currentGroup?.title}-slots.xlsx`,
                      });
                      setLoading(false);
                    }}
                  >
                    Export People List
                  </Button>
                )}
              </Loading>
            </Space>
          ) : (
            <Space>
              <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    loading={loading}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    onClick={async () => {
                      setLoading(true);
                      const pages = [
                        {
                          queryProps: {
                            query: accommodationByGroupId,
                            variables: {
                              groupId: currentGroup?.id,
                            },
                          },
                          name: currentGroup?.title,
                          specification: accommodationSpecification,
                          transformKeys: accommodationTransformKeys,
                        },
                      ];
                      await exportAccommodationData(
                        pages,
                        `${currentGroup?.title}-accommodation.xlsx`
                      );
                      setLoading(false);
                    }}
                  >
                    Export Accommodation List
                  </Button>
                )}
              </Loading>
            </Space>
          )
        }
        style={{ padding: 0 }}
      />
      <Switch>
        <Route
          path="/events/:eventId/groups/:groupId/:tab?"
          render={({ match, history }) => {
            if (!currentTab) {
              setCurrentTab(match.params.tab || 'people');
            }

            let operations = null;

            if (showEditActions()) {
              operations = <Spin />;

              if (currentTab === 'people') {
                operations = (
                  <Space>
                    <FormButton
                      fields={peopleFields}
                      successMessage="Person created"
                      action={addPerson}
                      renderButton={(setFormModalVisible) => (
                        <Button
                          onClick={() => {
                            setFormModalVisible(true);
                          }}
                          icon={<PlusOutlined />}
                          // type="primary"
                          size="large"
                        >
                          Add Person
                        </Button>
                      )}
                    />
                    <FormButton
                      fields={({ values, ...rest }) => {
                        return slotFields({
                          initialValues: { ...values, groupId: match.params.groupId },
                          ...rest,
                        });
                      }}
                      data={{ isCreateForm: true, eventId: match.params.eventId}}
                      successMessage="Slot created"
                      options={{
                        groups: currentEvent?.groups?.items,
                      }}
                      action={(values) =>
                        addSlot({
                          ...values,
                          eventId: match.params.eventId,
                          groupId: match.params.groupId,
                        })
                      }
                      renderButton={(setFormModalVisible) => (
                        <Button
                          onClick={() => {
                            setFormModalVisible(true);
                          }}
                          icon={<PlusOutlined />}
                          type="primary"
                          size="large"
                          // shape="round"
                        >
                          Add Slot
                        </Button>
                      )}
                    />
                  </Space>
                );
              }

              if (currentTab === 'flights') {
                operations = (
                  <FormButton
                    fields={({ values, loading, data, options }) =>
                      flightFields({
                        initialValues: {
                          groupId: match.params.groupId,
                        },
                        loading,
                        data,
                        options,
                      })
                    }
                    options={{
                      groups: currentEvent?.groups?.items,
                    }}
                    successMessage="Flight created"
                    action={(values) =>
                      addFlight({
                        ...values,
                        eventId: match.params.eventId,
                        groupId: match.params.groupId,
                      })
                    }
                    data={{ isCreateForm: true, eventId: match.params.eventId}}
                    renderButton={(setFormModalVisible) => (
                      <Button
                        onClick={() => {
                          setFormModalVisible(true);
                        }}
                        icon={<PlusOutlined />}
                        type="primary"
                        size="large"
                      >
                        Add Flight
                      </Button>
                    )}
                  />
                );
              }

              if (currentTab === 'accommodation') {
                operations = (
                  <FormButton
                    fields={({ loading, data, options }) =>
                      accommodationFields({
                        initialValues: { groupId: match.params.groupId },
                        loading,
                        data,
                        options
                      })
                    }
                    options={{
                      groups: currentEvent?.groups?.items,
                    }}
                    successMessage="Accommodation created"
                    action={(values) =>
                      addAccommodation({
                        ...values,
                        eventId: match.params.eventId,
                        groupId: match.params.groupId,
                      })
                    }
                    renderButton={(setFormModalVisible) => (
                      <Button
                        onClick={() => {
                          setFormModalVisible(true);
                        }}
                        icon={<PlusOutlined />}
                        type="primary"
                        size="large"
                      >
                        Add Accommodation
                      </Button>
                    )}
                  />
                );
              }
            }
            const contractedDayColumns: ColumnsType<DataType> = [
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                // render: (text) => <a>{text}</a>,
              },
              {
                title: 'People',
                dataIndex: 'people',
                key: 'people',
              },
            ];

            return (
              <Tabs
                defaultActiveKey={match.params.tab}
                tabBarExtraContent={operations}
                onChange={(key) => {
                  // history.push(
                  //   `/events/${match.params.eventId}/groups/${match.params.groupId}/${key}`
                  // );
                  window.history.replaceState(
                    null,
                    document.title,
                    `/events/${match.params.eventId}/groups/${match.params.groupId}/${key}`
                  );
                  setCurrentTab(key);
                }}
              >
                <TabPane
                  tab="Slots"
                  key="people"
                  style={{ padding: 0, paddingTop: 16 }}
                >
                  <ReduxDataProvider
                    query="slotsByGroupId"
                    variables={{ groupId: match.params.groupId }}
                    updateAction={updateCurrentGroupSlots as any}
                    upsertAction={upsertSlotItem}
                    stateSelector="currentGroup.slots"
                    include={['groups']}
                  >
                    {({ data, loading }) => (
                      <DataTable
                        columns={slotColumns({
                          eventId: match.params.eventId,
                          groupId: match.params.groupId,
                          currentView: 'grouped',
                          options: { groups: currentEvent?.groups?.items },
                        })}
                        data={data?.items}
                        tableConfig={tablesConfigs.slots}
                        tableConfigKey="slots"
                        loading={loading}
                      />
                    )}
                  </ReduxDataProvider>
                </TabPane>
                <TabPane
                  tab="Flights &amp; Transfers"
                  key="flights"
                  style={{ padding: 0, paddingTop: 16 }}
                >
                  <ReduxDataProvider
                    query="flightsByGroupId"
                    variables={{ groupId: match.params.groupId }}
                    updateAction={updateCurrentGroupFlights as any}
                    upsertAction={upsertFlightItem}
                    stateSelector="currentGroup.flights"
                    include={['groups']}
                  >
                    {({ data, loading }) => (
                      <>
                        <DataTable
                          header={(total) => (
                            <div className="tableHeader">
                              Outbound ({total})
                            </div>
                          )}
                          columns={flightColumns({
                            currentView: 'grouped',
                            options: { groups: currentEvent?.groups?.items },
                          })}
                          data={data?.items?.filter(
                            (o: any) => o.flightType === 'outbound'
                          )}
                          tableConfig={tablesConfigs.outboundFlights}
                          tableConfigKey="outboundFlights"
                          loading={loading}
                        />

                        <DataTable
                          header={(total) => (
                            <div className="tableHeader">Return ({total})</div>
                          )}
                          columns={flightColumns({
                            currentView: 'grouped',
                            options: { groups: currentEvent?.groups?.items },
                          })}
                          data={data?.items?.filter(
                            (o: any) => o.flightType === 'return'
                          )}
                          tableConfig={tablesConfigs.returnFlights}
                          tableConfigKey="returnFlights"
                          loading={loading}
                        />
                      </>
                    )}
                  </ReduxDataProvider>
                </TabPane>
                <TabPane
                  tab="Accommodation"
                  key="accommodation"
                  style={{ padding: 0, paddingTop: 16 }}
                >
                  <ReduxDataProvider
                    query="accommodationByGroupId"
                    variables={{ groupId: match.params.groupId }}
                    updateAction={updateCurrentGroupAccomodation as any}
                    upsertAction={upsertAccommodationItem}
                    stateSelector="currentGroup.accommodation"
                    include={['groups']}
                  >
                    {({ data, loading, dateTotals, contractedTotal }) => {
                      const contractedDaydata: DataType[] = Object.keys(
                        dateTotals
                      ).map((dateKey, i) => ({
                        key: i + 'key',
                        date: moment(dateKey).format('dddd Do MMM YY'),
                        people: dateTotals[dateKey],
                      }));

                      return (
                        <>
                          <DataTable
                            columns={accommodationColumns({
                              currentView: 'grouped',
                              options: { groups: currentEvent?.groups?.items },
                            })}
                            data={data?.items}
                            tableConfig={tablesConfigs.slots}
                            tableConfigKey="accommodation"
                            loading={loading}
                          />
                          <Collapse>
                            <Panel
                              header="Room Nights"
                              key="1"
                              extra={
                                <span style={{ fontFamily: 'Segoe UI Bold' }}>
                                  Total: {contractedTotal}
                                </span>
                              }
                            >
                              <Table
                                columns={contractedDayColumns}
                                dataSource={contractedDaydata}
                              />
                            </Panel>
                          </Collapse>
                        </>
                      );
                    }}
                  </ReduxDataProvider>
                </TabPane>
                {/* <TabPane tab="Checks" key="checks" /> */}
              </Tabs>
            );
          }}
        />
      </Switch>
    </>
  );
});

export default GroupsScene;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateProfileWithId = /* GraphQL */ `subscription OnUpdateProfileWithId($id: ID!) {
  onUpdateProfileWithId(id: $id) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProfileWithIdSubscriptionVariables,
  APITypes.OnUpdateProfileWithIdSubscription
>;
export const onUpdateAnyEvent = /* GraphQL */ `subscription OnUpdateAnyEvent {
  onUpdateAnyEvent {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyEventSubscriptionVariables,
  APITypes.OnUpdateAnyEventSubscription
>;
export const onUpdateEventWithId = /* GraphQL */ `subscription OnUpdateEventWithId($id: ID!) {
  onUpdateEventWithId(id: $id) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventWithIdSubscriptionVariables,
  APITypes.OnUpdateEventWithIdSubscription
>;
export const onUpdateAnyGroup = /* GraphQL */ `subscription OnUpdateAnyGroup {
  onUpdateAnyGroup {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyGroupSubscriptionVariables,
  APITypes.OnUpdateAnyGroupSubscription
>;
export const onUpdateAnySlot = /* GraphQL */ `subscription OnUpdateAnySlot {
  onUpdateAnySlot {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnySlotSubscriptionVariables,
  APITypes.OnUpdateAnySlotSubscription
>;
export const onUpdateAnyPerson = /* GraphQL */ `subscription OnUpdateAnyPerson {
  onUpdateAnyPerson {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyPersonSubscriptionVariables,
  APITypes.OnUpdateAnyPersonSubscription
>;
export const onBatchPeopleAdded = /* GraphQL */ `subscription OnBatchPeopleAdded {
  onBatchPeopleAdded {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnBatchPeopleAddedSubscriptionVariables,
  APITypes.OnBatchPeopleAddedSubscription
>;
export const onBatchFlightsAdded = /* GraphQL */ `subscription OnBatchFlightsAdded {
  onBatchFlightsAdded {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnBatchFlightsAddedSubscriptionVariables,
  APITypes.OnBatchFlightsAddedSubscription
>;
export const onBatchAccommodationAdded = /* GraphQL */ `subscription OnBatchAccommodationAdded {
  onBatchAccommodationAdded {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnBatchAccommodationAddedSubscriptionVariables,
  APITypes.OnBatchAccommodationAddedSubscription
>;
export const onUpdateAnyProfile = /* GraphQL */ `subscription OnUpdateAnyProfile {
  onUpdateAnyProfile {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyProfileSubscriptionVariables,
  APITypes.OnUpdateAnyProfileSubscription
>;
export const onUpdateAnyFlight = /* GraphQL */ `subscription OnUpdateAnyFlight {
  onUpdateAnyFlight {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyFlightSubscriptionVariables,
  APITypes.OnUpdateAnyFlightSubscription
>;
export const onUpdateAnyAccommodation = /* GraphQL */ `subscription OnUpdateAnyAccommodation {
  onUpdateAnyAccommodation {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnyAccommodationSubscriptionVariables,
  APITypes.OnUpdateAnyAccommodationSubscription
>;
export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
  onCreateEvent(filter: $filter) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEventSubscriptionVariables,
  APITypes.OnCreateEventSubscription
>;
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
  onUpdateEvent(filter: $filter) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventSubscriptionVariables,
  APITypes.OnUpdateEventSubscription
>;
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
  onDeleteEvent(filter: $filter) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEventSubscriptionVariables,
  APITypes.OnDeleteEventSubscription
>;
export const onCreateGroupCategory = /* GraphQL */ `subscription OnCreateGroupCategory(
  $filter: ModelSubscriptionGroupCategoryFilterInput
) {
  onCreateGroupCategory(filter: $filter) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupCategorySubscriptionVariables,
  APITypes.OnCreateGroupCategorySubscription
>;
export const onUpdateGroupCategory = /* GraphQL */ `subscription OnUpdateGroupCategory(
  $filter: ModelSubscriptionGroupCategoryFilterInput
) {
  onUpdateGroupCategory(filter: $filter) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupCategorySubscriptionVariables,
  APITypes.OnUpdateGroupCategorySubscription
>;
export const onDeleteGroupCategory = /* GraphQL */ `subscription OnDeleteGroupCategory(
  $filter: ModelSubscriptionGroupCategoryFilterInput
) {
  onDeleteGroupCategory(filter: $filter) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupCategorySubscriptionVariables,
  APITypes.OnDeleteGroupCategorySubscription
>;
export const onCreateGroup = /* GraphQL */ `subscription OnCreateGroup($filter: ModelSubscriptionGroupFilterInput) {
  onCreateGroup(filter: $filter) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupSubscriptionVariables,
  APITypes.OnCreateGroupSubscription
>;
export const onUpdateGroup = /* GraphQL */ `subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
  onUpdateGroup(filter: $filter) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupSubscriptionVariables,
  APITypes.OnUpdateGroupSubscription
>;
export const onDeleteGroup = /* GraphQL */ `subscription OnDeleteGroup($filter: ModelSubscriptionGroupFilterInput) {
  onDeleteGroup(filter: $filter) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupSubscriptionVariables,
  APITypes.OnDeleteGroupSubscription
>;
export const onCreateProfileEvent = /* GraphQL */ `subscription OnCreateProfileEvent(
  $filter: ModelSubscriptionProfileEventFilterInput
) {
  onCreateProfileEvent(filter: $filter) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProfileEventSubscriptionVariables,
  APITypes.OnCreateProfileEventSubscription
>;
export const onUpdateProfileEvent = /* GraphQL */ `subscription OnUpdateProfileEvent(
  $filter: ModelSubscriptionProfileEventFilterInput
) {
  onUpdateProfileEvent(filter: $filter) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProfileEventSubscriptionVariables,
  APITypes.OnUpdateProfileEventSubscription
>;
export const onDeleteProfileEvent = /* GraphQL */ `subscription OnDeleteProfileEvent(
  $filter: ModelSubscriptionProfileEventFilterInput
) {
  onDeleteProfileEvent(filter: $filter) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProfileEventSubscriptionVariables,
  APITypes.OnDeleteProfileEventSubscription
>;
export const onCreateLoginChallengeToken = /* GraphQL */ `subscription OnCreateLoginChallengeToken(
  $filter: ModelSubscriptionLoginChallengeTokenFilterInput
) {
  onCreateLoginChallengeToken(filter: $filter) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLoginChallengeTokenSubscriptionVariables,
  APITypes.OnCreateLoginChallengeTokenSubscription
>;
export const onUpdateLoginChallengeToken = /* GraphQL */ `subscription OnUpdateLoginChallengeToken(
  $filter: ModelSubscriptionLoginChallengeTokenFilterInput
) {
  onUpdateLoginChallengeToken(filter: $filter) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLoginChallengeTokenSubscriptionVariables,
  APITypes.OnUpdateLoginChallengeTokenSubscription
>;
export const onDeleteLoginChallengeToken = /* GraphQL */ `subscription OnDeleteLoginChallengeToken(
  $filter: ModelSubscriptionLoginChallengeTokenFilterInput
) {
  onDeleteLoginChallengeToken(filter: $filter) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLoginChallengeTokenSubscriptionVariables,
  APITypes.OnDeleteLoginChallengeTokenSubscription
>;
export const onCreateSlot = /* GraphQL */ `subscription OnCreateSlot($filter: ModelSubscriptionSlotFilterInput) {
  onCreateSlot(filter: $filter) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSlotSubscriptionVariables,
  APITypes.OnCreateSlotSubscription
>;
export const onUpdateSlot = /* GraphQL */ `subscription OnUpdateSlot($filter: ModelSubscriptionSlotFilterInput) {
  onUpdateSlot(filter: $filter) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSlotSubscriptionVariables,
  APITypes.OnUpdateSlotSubscription
>;
export const onDeleteSlot = /* GraphQL */ `subscription OnDeleteSlot($filter: ModelSubscriptionSlotFilterInput) {
  onDeleteSlot(filter: $filter) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSlotSubscriptionVariables,
  APITypes.OnDeleteSlotSubscription
>;
export const onCreatePerson = /* GraphQL */ `subscription OnCreatePerson($filter: ModelSubscriptionPersonFilterInput) {
  onCreatePerson(filter: $filter) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePersonSubscriptionVariables,
  APITypes.OnCreatePersonSubscription
>;
export const onUpdatePerson = /* GraphQL */ `subscription OnUpdatePerson($filter: ModelSubscriptionPersonFilterInput) {
  onUpdatePerson(filter: $filter) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePersonSubscriptionVariables,
  APITypes.OnUpdatePersonSubscription
>;
export const onDeletePerson = /* GraphQL */ `subscription OnDeletePerson($filter: ModelSubscriptionPersonFilterInput) {
  onDeletePerson(filter: $filter) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePersonSubscriptionVariables,
  APITypes.OnDeletePersonSubscription
>;
export const onCreateFlight = /* GraphQL */ `subscription OnCreateFlight($filter: ModelSubscriptionFlightFilterInput) {
  onCreateFlight(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlightSubscriptionVariables,
  APITypes.OnCreateFlightSubscription
>;
export const onUpdateFlight = /* GraphQL */ `subscription OnUpdateFlight($filter: ModelSubscriptionFlightFilterInput) {
  onUpdateFlight(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightSubscriptionVariables,
  APITypes.OnUpdateFlightSubscription
>;
export const onDeleteFlight = /* GraphQL */ `subscription OnDeleteFlight($filter: ModelSubscriptionFlightFilterInput) {
  onDeleteFlight(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlightSubscriptionVariables,
  APITypes.OnDeleteFlightSubscription
>;
export const onCreateAccommodation = /* GraphQL */ `subscription OnCreateAccommodation(
  $filter: ModelSubscriptionAccommodationFilterInput
) {
  onCreateAccommodation(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccommodationSubscriptionVariables,
  APITypes.OnCreateAccommodationSubscription
>;
export const onUpdateAccommodation = /* GraphQL */ `subscription OnUpdateAccommodation(
  $filter: ModelSubscriptionAccommodationFilterInput
) {
  onUpdateAccommodation(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccommodationSubscriptionVariables,
  APITypes.OnUpdateAccommodationSubscription
>;
export const onDeleteAccommodation = /* GraphQL */ `subscription OnDeleteAccommodation(
  $filter: ModelSubscriptionAccommodationFilterInput
) {
  onDeleteAccommodation(filter: $filter) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccommodationSubscriptionVariables,
  APITypes.OnDeleteAccommodationSubscription
>;
export const onCreateProfile = /* GraphQL */ `subscription OnCreateProfile($filter: ModelSubscriptionProfileFilterInput) {
  onCreateProfile(filter: $filter) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProfileSubscriptionVariables,
  APITypes.OnCreateProfileSubscription
>;
export const onUpdateProfile = /* GraphQL */ `subscription OnUpdateProfile($filter: ModelSubscriptionProfileFilterInput) {
  onUpdateProfile(filter: $filter) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProfileSubscriptionVariables,
  APITypes.OnUpdateProfileSubscription
>;
export const onDeleteProfile = /* GraphQL */ `subscription OnDeleteProfile($filter: ModelSubscriptionProfileFilterInput) {
  onDeleteProfile(filter: $filter) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProfileSubscriptionVariables,
  APITypes.OnDeleteProfileSubscription
>;
export const onCreateVerificationToken = /* GraphQL */ `subscription OnCreateVerificationToken(
  $filter: ModelSubscriptionVerificationTokenFilterInput
) {
  onCreateVerificationToken(filter: $filter) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVerificationTokenSubscriptionVariables,
  APITypes.OnCreateVerificationTokenSubscription
>;
export const onUpdateVerificationToken = /* GraphQL */ `subscription OnUpdateVerificationToken(
  $filter: ModelSubscriptionVerificationTokenFilterInput
) {
  onUpdateVerificationToken(filter: $filter) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVerificationTokenSubscriptionVariables,
  APITypes.OnUpdateVerificationTokenSubscription
>;
export const onDeleteVerificationToken = /* GraphQL */ `subscription OnDeleteVerificationToken(
  $filter: ModelSubscriptionVerificationTokenFilterInput
) {
  onDeleteVerificationToken(filter: $filter) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVerificationTokenSubscriptionVariables,
  APITypes.OnDeleteVerificationTokenSubscription
>;

import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { RouteComponentProps } from 'react-router-dom';
import { authorizeByToken } from '../../utils/authorizeByToken';
import Bugsnag from '@bugsnag/js';
import { getTeamMember } from '../../methods/team';
import { updateProfile } from '../../reducers/userSlice';
import { useDispatch } from 'react-redux';
import { Flex, Modal, Spin } from 'antd';

import { CloseCircleTwoTone } from '@ant-design/icons';

const VerifyAuthScene = (props: RouteComponentProps<{ token: string }>) => {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState('Verifying');

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { authData, userConfig } = await API.post('GooseRest', '/auth/verify', {
          body: {
            token: props.match.params.token,
          },
        });

        if (!authData) {
          Modal.error({
            title: 'Invalid token',
            content: (
              <p>
                The token you provided is invalid.
                <br />
                Please log in again.
              </p>
            ),
            onOk: (close) => {
              window.location.href = '/';
            },
          });
        } else {
          const loggedUser = await authorizeByToken(authData, userConfig);
          setStatus('Logging in');
          Bugsnag.leaveBreadcrumb('Succesful login', {
            username: loggedUser.attributes?.sub,
          });
          localStorage.setItem(
            'goose:userToken',
            loggedUser.signInUserSession.accessToken.jwtToken
          );

          const profile = await getTeamMember(loggedUser.attributes?.sub);
          Bugsnag.leaveBreadcrumb('Got profile', {
            profile: JSON.stringify(profile, null, 2),
          });

          dispatch(updateProfile(profile));

          props.history.push('/');
        }
      } catch (error: any) {
        console.error(error);
      }
    };
    verifyUser();
  }, []);

  return (
    <Flex
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <>
        <Spin size="large"></Spin>
        <br />
        <h1 style={{ margin: 0, padding: 0, fontSize: '1.5em' }}>{status}</h1>
      </>
    </Flex>
  );
};

export default VerifyAuthScene;

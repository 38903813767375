import { FormInstance } from '@ant-design/pro-components';
import { Ref } from 'react';

export const defaultFormProps: (formRef: any, formLoading: boolean) => any = (
  formRef,
  formLoading
) => ({
  autoFocusFirstInput: true,
  submitter: {
    searchConfig: {
      submitText: 'Save',
      resetText: 'Close',
    },
    //   submitButtonProps: {
    //     disabled:formLoading
    //   },
  },
  layoutType: 'ModalForm',
  rowProps: {
    gutter: [16, 16],
  },
  colProps: {
    span: 12,
  },
  grid: false,
  modalProps: {
    afterClose: () => {
      // formRef?.resetFields();
    },
  },
});

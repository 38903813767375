import { BetaSchemaForm, FormInstance } from '@ant-design/pro-components';
import { Button, Card, message, Popconfirm, Dropdown, Menu } from 'antd';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Group } from '../../API';
import { fields } from '../../data/groups';
import { updateGroup } from '../../methods/groups';
import { defaultFormProps } from '../../utils/defaultFormProps';
import {
  ArrowRightOutlined,
  SearchOutlined,
  SmileOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { updateCurrentGroup } from '../../reducers/eventsSlice';
import showEditActions from '../../utils/showEditActions';
import type { MenuProps } from 'antd';

type Props = {
  group: Group;
  count?: number;
  slotCount?: number;
  peopleCount?: number;
};

const GroupCard: React.FC<Props> = ({
  group,
  count = 0,
  slotCount,
  peopleCount,
}) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <EditOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            setMenuVisible(false);
            setFormModalVisible(true);

            e.stopPropagation();
          }}
        >
          Edit
        </a>
      ),
    },
    {
      key: '2',
      danger: true,
      label: (
        <Popconfirm
          onOpenChange={() => {
            setMenuVisible(false);
          }}
          title="Are you sure?"
          onConfirm={async () => {
            await updateGroup({
              ...group,
              deletedAt: new Date().toISOString(),
            });

            message.success('Group Removed');
          }}
        >
          <a>Remove</a>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <Card
      // extra={showEditActions() && extra}
      // actions={[
      //   <ArrowRightOutlined
      //     onClick={() => {
      //       dispatch(updateCurrentGroup(group));
      //       history.push(`/events/${group.eventId}/groups/${group.id}`);
      //     }}
      //   />,
      // ]}
      key={group.id}
      hoverable
      onClick={(e) => {
        e.stopPropagation();
        dispatch(updateCurrentGroup(group));
        history.push(`/events/${group.eventId}/groups/${group.id}`);
      }}
      // bordered={false}
      style={{ textAlign: 'left' }}
    >
      {count ? (
        <h4 style={{ margin: 0, color: '#444' }}>Group {count}</h4>
      ) : null}
      <h3
        style={{ fontFamily: 'Segoe UI Bold', fontSize: 18, marginBottom: 2 }}
      >
        {group.title}
      </h3>
      <span style={{ color: '#888' }}>
        {slotCount?.toString()} slot
        {slotCount === 1 ? '' : 's'}, {peopleCount?.toString()}{' '}
        {peopleCount === 1 ? 'person' : 'people'}
      </span>
      {showEditActions() && (
        <div
          style={{
            position: 'absolute',
            top: 12,
            right: 12,
            display: 'block',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Dropdown
            menu={{ items }}
            open={menuVisible}
            trigger={['click']}
            placement="bottomLeft"
            onOpenChange={setMenuVisible}
            arrow
          >
            <Button shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
          <BetaSchemaForm
            {...defaultFormProps(formRef, formLoading)}
            formRef={formRef}
            visible={formModalVisible}
            onVisibleChange={(isVisible: boolean) => {
              setFormModalVisible(isVisible);
            }}
            onFinish={async (values: Group) => {
              setFormLoading(true);
              await updateGroup({ ...group, ...values });
              setTimeout(() => {
                setFormModalVisible(false);
                setFormLoading(false);
                message.success('Group Updated');
              }, 500);
            }}
            columns={fields({ values: group, loading: formLoading }) as any}
          />
        </div>
      )}
    </Card>
  );
};

export default GroupCard;

import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../hooks';
import { getAll } from '../utils/getAll';
import { get } from 'lodash';
import moment from 'moment';
import notifyBugsnag from '../utils/notifyBugsnag';
import { getGroupsByEventId } from '../methods/groups';
import { getEvent, profileEventsByOwnerId } from '../graphql/queries';

var enumerateDaysBetweenDates = function (startDate: any, endDate: any) {
  var now = startDate.clone(),
    dates = [];

  while (now.isBefore(endDate, 'day')) {
    dates.push(now.format('M/D/YYYY'));
    now.add(1, 'days');
  }
  return dates;
};

type ChildrenProps = {
  loading: boolean;
  data: any;
  dateTotals: { [key: string]: number };
  contractedTotal: number;
  groupContractedDays: any;
  groups?: any;
};

type Props = {
  children: (props: ChildrenProps) => any;
  query: string;
  variables?: any;
  subscription?: any;
  updateAction?: any;
  upsertAction?: any;
  stateSelector: string;
  include?: string[];
};
const ReduxDataProvider: React.FC<Props> = ({
  children,
  query,
  variables,
  subscription,
  updateAction,
  upsertAction,
  stateSelector,
  include,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<any>([]);

  const events = useAppSelector((state) => state.events);

  let data = get(events, stateSelector);

  const fetchData = async () => {
    let addedGroups: any = {};
    let allNewGroups: any[] = [];
    try {
      const items: any = await getAll({
        query,
        variables,
      });

      dispatch(updateAction({ items }));
      if (include?.includes('groups')) {
        await Promise.all(
          items.map(async ({ id, eventId, ...rest }: any) => {
            if (eventId && !addedGroups[eventId]) {
              console.log('getGroupsByEventId 1', query, eventId);
              const newGroups: any = await getGroupsByEventId(eventId);
              addedGroups[eventId] = true;
              allNewGroups = [...allNewGroups, ...newGroups];
            }
          })
        );
        setGroups(allNewGroups);
      }
      setLoading(false);
    } catch (e) {
      notifyBugsnag(e);
    }
  };

  useEffect(() => {
    let sub: any;
    setLoading(true);
    fetchData();
    if (subscription) {
      const subscribeToUpdates = async () => {
        const dataQuery: any = API.graphql({ query: subscription });
        sub = dataQuery.subscribe({
          next: async ({ provider, value }: { provider: any; value: any }) => {
            for (const key in value.data) {
              console.log('value', value);
              let itemsToUpdate = value.data[key];
              if (key === 'onUpdateAnyProfile') {
                const profileEventsForUser = (await API.graphql({
                  query: profileEventsByOwnerId,
                  variables: { owner: itemsToUpdate.id },
                })) as any;

                console.log('profileEventsForUser', profileEventsForUser);

                itemsToUpdate.events =
                  profileEventsForUser.data.profileEventsByOwnerId;
              }
              dispatch(upsertAction(itemsToUpdate));
            }
          },
          error: (error: any) => console.warn(error),
        });
      };
      subscribeToUpdates();
    }

    return () => {
      sub?.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables?.slotId]);

  // do accommodation matsh here?
  let dateTotals: { [key: string]: number } = {};
  let unOrderedDateTotals: { [key: string]: number } = {};
  let contractedTotal: number = 0;
  let groupContractedDays: any = {};
  if (data?.items) {
    if (
      [
        'currentGroup.accommodation',
        'currentEvent.accommodation',
        'currentPerson.accommodation',
      ].includes(stateSelector)
    ) {
      data = {
        ...data,
        items: data.items.map((item: any) => {
          // do day maths here
          const contractedDays = enumerateDaysBetweenDates(
            moment(item.checkinDate, 'DD/MM/YYYY HH:mm'),
            moment(item.checkoutDate, 'DD/MM/YYYY HH:mm')
          );

          contractedDays.forEach((contractedDate) => {
            contractedTotal++;
            if (!unOrderedDateTotals[contractedDate]) {
              unOrderedDateTotals[contractedDate] = 0;
            }
            if (!groupContractedDays[item.groupId]) {
              groupContractedDays[item.groupId] = 0;
            }
            groupContractedDays[item.groupId]++;

            unOrderedDateTotals[contractedDate]++;
          });

          return { ...item, contractedDays: contractedDays.length };
        }),
      };
    }
  }

  if (Object.keys(unOrderedDateTotals).length > 0) {
    const orderedKeys = Object.keys(unOrderedDateTotals).sort((a, b) => {
      return moment(a).valueOf() - moment(b).valueOf();
    });

    orderedKeys.forEach((key) => {
      dateTotals[key] = unOrderedDateTotals[key];
    });
  }
  return children({
    loading,
    data,
    groups,
    dateTotals,
    contractedTotal,
    groupContractedDays,
  });
};

export default ReduxDataProvider;

import { ColumnType } from 'antd/lib/table';

import { DataType } from '../globals';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';

const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
  confirm();
};

const handleReset = (
  clearFilters: () => void,
  setSelectedKeys: (val: string[]) => void,
  confirm: (param?: FilterConfirmProps) => void
) => {
  setSelectedKeys([]);
  clearFilters();
  confirm();
};

let inputRef: any = null;
type Overrides = {
  onFilter?: (value: any, record: any) => boolean
}
export const getColumnSearchProps = (
  dataIndex: string[] | null,
  label: string,
  overides: Overrides = {}
): ColumnType<DataType> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ref) => (inputRef = ref)}
          placeholder={`Search ${label}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, setSelectedKeys, confirm)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  },
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => inputRef.select(), 100);
    }
  },
  onFilter:(value: any, record: any) => {
    let targetField = record;

    dataIndex?.forEach((key) => {
      if (targetField) {
        targetField = targetField[key];
      }
    });
    if (targetField) {
      return targetField
        .toString()
        .toLowerCase()
        .replace(/ /g, '')
        .includes((value as string).toLowerCase().replace(/ /g, ''));
    }
    return false;
  },
  ...overides
});

import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, notification, Upload, Space } from 'antd';

import DataTable from '../../components/DataTable';

import {
  columns as peopleColumns,
  fields as peopleFields,
  specification,
} from '../../data/people';
import FormButton from '../../components/FormButton';
import { addPerson, batchAddPeople, updatePerson } from '../../methods/people';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { onUpdateAnyPerson } from '../../graphql/subscriptions';
import { updatePeople, upsertPerson } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { Optional, Person } from '../../globals';
import {
  UploadOutlined,
  PlusOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import Papa from 'papaparse';
import _, { values } from 'lodash';
import { listPeople } from '../../graphql/queries';
import { exportData } from '../../utils/exportData';
import Loading from '../../components/Loading';
type Props = {
  match: any;
};

const PeopleScene: React.FC<Props> = (props) => {
  const events = useAppSelector((state) => state.events);
  const { tablesConfigs } = events;

  return (
    <ReduxDataProvider
      query="listPeople"
      updateAction={updatePeople as any}
      upsertAction={upsertPerson}
      stateSelector="people"
    >
      {({ data, loading }) => (
        <PageHeader
        // className="site-page-header"

        // style={{ padding: 0 }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Space align="center">
              <h1 style={{ margin: 0, padding: 0, fontSize: '1.5em' }}>
                People
              </h1>
              {showEditActions() && (
                <FormButton
                  fields={peopleFields}
                  successMessage="Person created"
                  action={addPerson}
                  renderButton={(setFormModalVisible) => (
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        setFormModalVisible(true);
                      }}
                      icon={<PlusOutlined />}
                    />
                  )}
                />
              )}
            </Space>
            <Space>
              {showEditActions() && (
                <Upload
                  fileList={[]}
                  customRequest={(info) => {
                    return;
                  }}
                  onChange={(info) => {
                    try {
                      var reader = new FileReader();
                      reader.onload = async (event) => {
                        const jsonData = Papa.parse(
                          event?.target?.result as string,
                          {
                            skipEmptyLines: 'greedy',
                            header: true,
                            transformHeader: (label) => {
                              return label.toLowerCase().replace(/ /g, '_');
                            },
                            transform: (value) => {
                              return value.trim();
                            },
                          }
                        );
                        let itemsToAdd: any[] = [];
                        let itemsSkipped = 0;

                        jsonData.data.forEach((item: any) => {
                          const targetItem = data.items.find(
                            ({ email }: any) =>
                              email?.toLowerCase() ===
                              item.email_address.trim().toLowerCase()
                          );
                          if (!targetItem) {
                            itemsToAdd.push({
                              title: item.title,
                              firstName: item.first_name,
                              lastName: item.last_name,
                              email: item.email_address.trim().toLowerCase(),
                              city: item.city,
                              country: item.country,
                              dietaryRequirements: item.dietary_requirements,
                              phone: item.mobile_no,
                              publications: item.publications,
                              vaccinationStatus: item.vaccination_status,
                            });
                          } else {
                            itemsSkipped++;
                          }
                        });

                        const chunks = _.chunk(itemsToAdd, 25);
                        await Promise.all(chunks.map(batchAddPeople));

                        if (itemsToAdd.length > 0) {
                          message.success(`${itemsToAdd.length} items added`);
                        }
                        if (itemsSkipped > 0) {
                          message.warning(
                            `${itemsSkipped} duplicate items skipped`
                          );
                        }
                      };
                      reader.readAsText(
                        info.file.originFileObj as Blob,
                        'ISO-8859-4'
                      );
                    } catch (e: any) {
                      message.error(e.message);
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />} type="dashed" shape="round">
                    Import From CSV
                  </Button>
                </Upload>
              )}
              <Loading>
                {({ loading, setLoading }) => (
                  <Button
                    icon={<DownloadOutlined />}
                    loading={loading}
                    // type="primary"
                    // size="large"
                    type="dashed"
                    shape="round"
                    onClick={async () => {
                      setLoading(true);
                      const pages = [
                        {
                          queryProps: {
                            query: listPeople,
                          },
                          name: 'people',
                          specification: specification,
                          transformKeys: {},
                        },
                      ];
                      await exportData({ pages, fileName: `people.xlsx` });
                      setLoading(false);
                    }}
                  >
                    Export People List
                  </Button>
                )}
              </Loading>
            </Space>
          </div>
          <br />
          <div className="site-card-wrapper">
            <DataTable
              columns={peopleColumns()}
              data={!data.hasFetched ? [] : data?.items}
              tableConfig={tablesConfigs.people}
              tableConfigKey="people"
              loading={loading}
            />
          </div>
        </PageHeader>
      )}
    </ReduxDataProvider>
  );
};

export default PeopleScene;

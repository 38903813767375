import { Select } from 'antd';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import React, { useEffect, useState } from 'react';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import {
  updateAllEvents,
  updatePeople,
  upsertPerson,
} from '../../reducers/eventsSlice';



const { Option } = Select;

type Props = {
  // onChange: (value:string) => void
  onSelect?: (value: any) => void;
  onChange?: <T = any>(item?: T) => void;
  value?: any;
  initialValue?: any;
  fieldProps?: any;
};

const EventSelect: React.FC<Props> = (props) => {
  const [data, setData] = useState<any[]>([]);

  const [value, setValue] = useState<string[]>(props.value);

  const handleChange = (newValue: string[]) => {

    setValue(newValue);
    let actionUpdates: any[] = [];
    // if (newValue) {

    //   // loop through current items and check if they are in the new value - of not they need to be removed
    //   itemsToCheck?.items?.forEach((profileEvent: ProfileEvents) => {
    //     console.log('profileEvent newValue', profileEvent, newValue);
    //     const targetItem = newValue.find(
    //       (id: string) => profileEvent.eventId === id
    //     );

    //     if (!targetItem) {
    //       actionUpdates.push({ id: profileEvent.id, action: 'remove' });
    //     }
    //   });

    //   newValue.forEach((id) => {
    //     const targetItem = itemsToCheck?.items?.find(
    //       (event: any) => event.id === id
    //     );
    //     if (!targetItem) {
    //       console.log('targetItem', targetItem);
    //       actionUpdates.push({id: uuidv4(), eventId: id, action: 'created'});
    //     }
    //   });
    // }

    // if (newValue.length > 0) {
      props.onChange &&
        props.onChange(newValue);
    // }
  };

  const onSearch = (value: string) => {};

  const options = data.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.firstName} {d.lastName}
    </Option>
  ));
  // useEffect(() => {
  //   const searchInitialValue = async () => {
  //     let extra:any = {};
  //     if (props.value) {
  //       extra.variables = {
  //         filter: {
  //           id: { eq: props.value },
  //         },
  //       };
  //     }
  //     const items: any = await API.graphql({
  //       query: searchPeople,
  //       ...extra,
  //       authMode: 'AMAZON_COGNITO_USER_POOLS',
  //     });

  //     setData(
  //       items.data['searchPeople'].items.filter((o: any) => !o.deletedAt)
  //     );
  //     handleChange(props.value, items.data['searchPeople'].items.filter((o: any) => !o.deletedAt));
  //   };
  //   searchInitialValue();
  // }, []);
  return (
    <ReduxDataProvider
      query="listEvents"
      stateSelector="allEvents"
      updateAction={updateAllEvents as any}
    >
      {({ data, loading }) => {
        let valueProps: { value?: any } = {};

        // if (data?.items?.length > 0 && value) {
        //   valueProps.value = value.items.map((event: ProfileEvents) => event.eventId);
        // }

        return (
          <Select
            {...props?.fieldProps}
            mode="multiple"
            allowClear
            showSearch
            value={value}
            placeholder="Select events"
            optionFilterProp="label"
            onChange={(value) => handleChange(value)}
            onSearch={onSearch}
            filterOption={(input, option) => {
              return (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            notFoundContent={null}
            size="large"
            style={{ width: 300 }}
            options={data?.items?.map(({ id, title }: any) => ({
              label: `${title}`,
              value: id,
            }))}

          />
        );
      }}
    </ReduxDataProvider>
  );
};

export default EventSelect;

import { API, Auth } from 'aws-amplify';
import { Person, Profile } from '../API';

import {
  createProfile as createProfileMutation,
  updateProfile as updateProfileMutation,
} from '../graphql/mutations';
import { getProfile as getProfileQuery } from '../graphql/queries';
import { getAll } from '../utils/getAll';

export async function addTeamMember(teamMember: Profile) {
  const now = new Date().toISOString();

  // const response: any = await API.graphql({
  //   query: createProfileMutation,
  //   variables: {
  //     input: { ...teamMember, createdAt: now, updatedAt: now },
  //   },
  // });

  const response = await API.post('GooseRest', `/team`, {
    body: teamMember,
  });


  return response;
}

export async function updateTeamMember(teamMember: Profile) {

  const response = await API.put('GooseRest', `/team`, {
    body: {teamMember},
  });


  return response;
}

export async function removeTeamMember(teamMember: Profile) {

  const response = await API.del('GooseRest', `/team`, {
    body: teamMember,
  });

  return response;
}

export async function getTeamMember(id: string) {
  const response: any = await API.graphql({
    query: getProfileQuery,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    variables: {
      id,
    },
  });

  return response.data.getProfile;
}

export async function getTeam() {
  const items: Profile[] = await getAll({ query: 'listProfiles' });

  return items;
}

import { ProFormColumnsType } from '@ant-design/pro-components';
import { Accommodation, Person, Slot } from '../API';
import type { ColumnsType } from 'antd/es/table';

import FormButton from '../components/FormButton';
import DeleteButton from '../components/DeleteButton';
import {
  removeAccommodation,
  updateAccommodation,
} from '../methods/accommodation';
import { DataType } from '../globals';
import { getColumnSearchProps } from '../utils/getColumnSearchProps';
import showEditActions from '../utils/showEditActions';
import { DatePicker, Space, Popover, Button, Alert } from 'antd';
import moment from 'moment';
import type { Moment } from 'moment';

import SlotSelect from '../components/SlotSelect';
import { defaultSpecificationItem } from '../utils/exportConfig';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import { FileSearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const STRING_TO_FORCE_TO_END = 'xxxxxxxxxxxxxxxxx';
type DataItem = {
  name: string;
  state: string;
};

type Props = {
  initialValues?: any;
  loading: boolean;
  data?: any;
  options?: any;
};

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  initialValues,
  loading,
  data,
  options,
}) => {
  const optionsHolder: any = {};
  if (options) {
    Object.keys(options).forEach((key) => {
      optionsHolder[key] = {};
      options[key]?.forEach((item: any) => {
        optionsHolder[key][item.id] = { text: item.title };
      });
    });
  }

  return [
    {
      title: 'Group',
      dataIndex: 'groupId',
      dependencies: ['slot'],

      fieldProps: (form) => ({
        placeholder: 'Select',
        disabled: loading,
        onChange: (value: any) => {
          form?.setFieldsValue({
            slotId: null,
            slot: null,
            groupId: value,
          });
        },
      }),
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().groupId || initialValues?.groupId,
        rules: [
          {
            required: true,
          },
        ],
      }),
      valueType: 'select',
      valueEnum: optionsHolder.groups,
      width: 200,
    },
    {
      title: 'Slot #',
      key: 'slot',
      dependencies: ['groupId'],
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().slot,
      }),
      renderFormItem: (schema, config, form) => {
        const formValues = form.getFieldsValue();
        return (
          <SlotSelect
            groupId={formValues?.groupId}
            disabled={loading || !formValues?.groupId}
          />
        );
      },
      transform: (value) => {
        return { slotId: value?.id, slot: undefined };
      },
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    },
    {
      title: 'Room Type',
      dataIndex: 'roomType',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.roomType || 'Single',
      }),
    },

    {
      title: 'Notes',
      dataIndex: 'notes',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().notes,
      }),
      valueType: 'textarea',
    },
    {
      valueType: 'dependency',
      name: [
        'checkinDate',
        'checkoutDate',
        'checkinAndOutDate',
        'copiedFromFlightId',
      ],
      dataIndex: 'checkinAndOutDate',

      columns: (stuff) => {
        return [
          {
            title: 'Checkin / Checkout',
            dependencies: ['checkinDate', 'checkoutDate'],
            dataIndex: 'checkinAndOutDate',
            transform: (value) => {
              return {
                checkinDate: moment(value[0])
                  .second(0)
                  .format('DD/MM/YYYY HH:mm'),
                checkoutDate: moment(value[1])
                  .second(0)
                  .format('DD/MM/YYYY HH:mm'),
                copiedFromFlightId: null,
              };
            },
            renderFormItem: (scheam, config, form) => {
              const formValues = form.getFieldsValue();
              return (
                <>
                  {stuff.copiedFromFlightId ? (
                    <Alert
                      showIcon
                      type="warning"
                      message="These dates were set via Flight Dates"
                      style={{ marginBottom: 10 }}
                    />
                  ) : null}
                  <MyDatePicker.RangePicker
                    showTime
                    format={'DD/MM/YYYY HH:mm'}
                    placeholder={['Start', 'End']}
                    onChange={(value) => {
                      form.setFieldsValue({
                        checkinAndOutDate: value,
                        copiedFromFlightId: null,
                      });
                    }}
                    defaultValue={
                      stuff?.checkinDate || stuff?.checkoutDate
                        ? [
                            stuff?.checkinDate
                              ? moment(stuff?.checkinDate, 'DD/MM/YYYY HH:mm')
                              : null,
                            stuff?.checkoutDate
                              ? moment(stuff?.checkoutDate, 'DD/MM/YYYY HH:mm')
                              : null,
                          ]
                        : [null, null]
                    }
                  />
                </>
              );
            },
            width: 'md',
            colProps: {
              xs: 24,
              md: 12,
            },
          },
        ];
      },
    },
  ];
};

export const columns: (params: {
  currentView?: 'grouped' | 'all';
  isPersonView?: boolean;
  options?: any;
  data?: any;
}) => ColumnsType<any> = ({
  currentView = 'grouped',
  options,
  isPersonView,
  data,
}) => {
  const items: ColumnsType<any> = [
    {
      hidden: !isPersonView,
      title: 'Event',
      dataIndex: ['slot', 'event', 'title'],
      sorter: (a: any, b: any) =>
        (a.slot?.event?.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.slot?.event?.title || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['slot', 'event', 'title'], 'Event'),
    },
    {
      hidden: currentView === 'grouped',
      title: 'Group',
      dataIndex: ['group', 'title'],
      sorter: (a: any, b: any) =>
        (a.groupId || STRING_TO_FORCE_TO_END).localeCompare(
          b.groupId || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['group', 'title'], 'Group'),
    },
    {
      title: '#',
      dataIndex: ['slot', 'title'],
      sorter: (a: any, b: any) => {
        let aSlotTitle = '999999999999999999';
        let bSlotTitle = '999999999999999999';

        if (a.slot?.title) {
          aSlotTitle = a.slot.title;
        }
        if (b.slot?.title) {
          bSlotTitle = b.slot.title;
        }
        return parseFloat(aSlotTitle) - parseFloat(bSlotTitle);
      },
      defaultSortOrder: 'ascend',
      // width: 200
      ...getColumnSearchProps(['slot', 'title'], 'Slot #'),
      fixed: 'left',
      width: 75,
    },
    {
      title: 'Title',
      dataIndex: ['slot', 'person', 'title'],
      ...getColumnSearchProps(['slot', 'person', 'title'], 'Title'),
      sorter: (a: any, b: any) =>
        (a.slot.person.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.slot.person.title || STRING_TO_FORCE_TO_END
        ),
      width: 100,
      hidden: isPersonView,
    },
    {
      title: 'Full Name',
      ...getColumnSearchProps(null, 'Full Name'),
      onFilter: (value: any, record: any) => {
        return `${record.slot?.person?.firstName} ${record.slot?.person?.lastName}`
          .toLowerCase()
          .replace(/ /g, '')
          .includes((value as string).toLowerCase().replace(/ /g, ''));
      },
      sorter: (a: any, b: any) =>
        (a.slot?.person
          ? `${a.slot?.person?.firstName} ${a.slot?.person?.lastName}`
          : STRING_TO_FORCE_TO_END
        ).localeCompare(
          a.slot?.person
            ? `${b.slot?.person?.firstName} ${b.slot?.person?.lastName}`
            : STRING_TO_FORCE_TO_END
        ),
      width: 200,
      hidden: isPersonView,
      render(value, record: any, index) {
        return (
          <Link to={`/people/${record?.slot?.person?.id}`}>
            {record?.slot?.person?.firstName} {record?.slot?.person?.lastName}
          </Link>
        );
      },
    },
    {
      title: 'Room Type',
      dataIndex: 'roomType',
      sorter: (a: any, b: any) =>
        (a.roomType || STRING_TO_FORCE_TO_END).localeCompare(
          b.roomType || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      ...getColumnSearchProps(['roomType'], 'Room Type'),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      sorter: (a: any, b: any) =>
        (a.notes || STRING_TO_FORCE_TO_END).localeCompare(
          b.notes || STRING_TO_FORCE_TO_END
        ),
      width: 200,
      ...getColumnSearchProps(['notes'], 'Notes'),
      render: (item) => {
        if (item) {
          return (
            (item.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Popover
                  content={
                    <span style={{ display: 'block', maxWidth: 300 }}>
                      {item}
                    </span>
                  }
                  trigger="click"
                >
                  <Button shape="circle" icon={<FileSearchOutlined />} />
                </Popover>
              </div>
            )) ||
            null
          );
        }
        return null;
      },
    },
    {
      title: 'Checkin',
      dataIndex: 'checkinDate',
      sorter: (a: any, b: any) =>
        moment(a.checkinDate, 'DD/MM/YYYY HH:mm').valueOf() -
        moment(b.checkinDate, 'DD/MM/YYYY HH:mm').valueOf(),
      width: 150,
      ...getColumnSearchProps(['checkinDate'], 'Checkin'),
      render(value, record, index) {
        if (!value) {
          return null;
        }
        const comp = (
          <span>
            {moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
          </span>
        );

        if (record.copiedFromFlightId) {
          return (
            <Popover content="Copied from Flight Dates" placement="top">
              <Space>
                {comp}
                <CalendarOutlined />
              </Space>
            </Popover>
          );
        }

        return comp;
      },
    },
    {
      title: 'Checkout',
      dataIndex: 'checkoutDate',
      sorter: (a: any, b: any) =>
        moment(a.checkoutDate, 'DD/MM/YYYY HH:mm').valueOf() -
        moment(b.checkoutDate, 'DD/MM/YYYY HH:mm').valueOf(),
      width: 150,
      ...getColumnSearchProps(['checkoutDate'], 'Checkout'),
      render(value, record, index) {
        if (!value) {
          return null;
        }
        const comp = (
          <span>
            {moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
          </span>
        );

        if (record.copiedFromFlightId) {
          return (
            <Popover content="Copied from Flight Dates" placement="top">
              <Space>
                {comp}
                <CalendarOutlined />
              </Space>
            </Popover>
          );
        }

        return comp;
      },
    },
    {
      title: 'Room Nights',
      dataIndex: 'contractedDays',
      sorter: (a: any, b: any) => a.contractedDays - b.contractedDays,
      width: 130,
      ...getColumnSearchProps(['contractedDays'], 'Room Nights'),
    },
  ];

  if (showEditActions()) {
    items.push({
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (item) => {
        return (
          <Space style={{ float: 'right' }}>
            <FormButton
              fields={({ values, loading, data, options, form }) =>
                fields({
                  initialValues: { ...values, groupId: item.groupId },
                  loading,
                  data,
                  options,
                })
              }
              item={item}
              data={{ ...data, eventId: item.eventId }}
              successMessage="Accommodation updated"
              action={updateAccommodation}
              options={options}
            />
            <DeleteButton
              item={item}
              successMessage="Accommodation removed"
              action={removeAccommodation}
            />
          </Space>
        );
      },
    });
  }

  return items;
};

export const specification = [
  { key: 'slotNumber', label: 'Slot #', width: 20 },
  { key: 'title', label: 'Title', width: 40 },
  { key: 'firstName', label: 'First Name', width: 40 },
  { key: 'lastName', label: 'Last Name', width: 40 },
  { key: 'roomType', label: 'Room Type', width: 40 },
  { key: 'notes', label: 'Notes', width: 40 },
  { key: 'checkinDate', label: 'Checkin Date', width: 40 },
  { key: 'checkinTime', label: 'Checkin Time', width: 40 },
  { key: 'checkoutDate', label: 'Checkout Date', width: 40 },
  { key: 'checkoutTime', label: 'Checkout Time', width: 40 },
  { key: 'contractedDays', label: 'Room Nights', width: 40 },
];

export const transformKeys = {
  'slot.title': 'slotNumber',
  'slot.person.title': 'title',
  'slot.person.firstName': 'firstName',
  'slot.person.lastName': 'lastName',
};

import DataTable from '../../components/DataTable';

import { columns as teamColumns, fields as teamFields } from '../../data/team';
import FormButton from '../../components/FormButton';
import { addTeamMember, updateTeamMember } from '../../methods/team';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updateTeam, upsertTeamMember } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { PlusOutlined } from '@ant-design/icons';

import { PageHeader } from '@ant-design/pro-layout';

import { Button, Form, Input, message, RadioChangeEvent } from 'antd';
import { Radio, Space, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getProfile } from '../../graphql/queries';
import { Profile } from '../../API';
import { Optional } from '../../globals';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../reducers/userSlice';
import passwordStrengthRule, {
  buildPasswordErrorMessages,
  passwordIconRender,
} from '../../utils/passwordStrengthRule';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 10 },
};

const ChangePasswordScene: React.FC = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const { profile } = user;
  const [passwordError, setPasswordError] = useState([]);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const userDetails = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        userDetails,
        values.password,
        values.newPassword
      );

      form.resetFields();
      setLoading(false);

      message.success('Password Updated');
    } catch (e: any) {
      setLoading(false);
      let errMessage = e.message;

      if (e.code === 'InvalidParameterException') {
        errMessage = 'Your current password is incorrect';
      }
      message.error(errMessage);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={profile}
      >
        <Form.Item
          label="Current Password"
          name="password"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          required
          rules={[
            passwordStrengthRule((err: any) => {
              setPasswordError(err);
            }),
          ]}
          help={buildPasswordErrorMessages(
            passwordError,
            form.isFieldTouched('newPassword')
          )}
        >
          <Input.Password iconRender={passwordIconRender} />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: 'Required',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
        >
          <Input.Password iconRender={passwordIconRender} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <br />
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePasswordScene;

import React, { ReactElement, useState } from 'react';

import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Space,
  Row,
  Col,
  Typography,
  Button,
  Card,
  message,
  Breadcrumb,
} from 'antd';
import { Auth } from 'aws-amplify';
import { getTeamMember } from '../../methods/team';
import { HomeOutlined } from '@ant-design/icons';

const { Title } = Typography;

type ForgotPasswordValues = {
  email: string;
};

type LocationState = {
  from: {
    pathname: string;
  };
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const ForgotPasswordScene: React.FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);

  let locationToUse: LocationState = {
    from: {
      pathname: '/',
    },
  };

  if (location.state) {
    locationToUse = location.state;
  }
  const onFinish = async (values: ForgotPasswordValues) => {
    setLoading(true);
    try {
      values.email = values.email.toLowerCase().trim();

      await Auth.forgotPassword(values.email);

      setLoading(false);
      message.success('Password Reset Link Sent');
    } catch (e:any) {
      setLoading(false);
      message.error(e.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container">
      <Row justify="center">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          <div
            style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}
          ></div>
          <Row>
            <Col span={24}>
            <div style={{ marginBottom: 16, marginLeft: 16, marginTop: 16 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
            <Link to="/auth/login">
                <HomeOutlined />
              </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Forgot Password</Breadcrumb.Item>
            </Breadcrumb>
          </div>
              <Card>
                <div style={{ marginBottom: 30 }}>
               
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Forgot Password
                  </Title>
                  <p>
                    Enter your email address and we'll send you a link to reset
                    your password.
                  </p>
                </div>

                <Form
                  {...layout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Space size="middle" wrap>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Request Reset Link
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordScene;

import { API } from 'aws-amplify';
import { createDispatchHook } from 'react-redux';
import { Accommodation } from '../API';
import { v4 as uuidv4 } from 'uuid';

import {
  createAccommodation as createAccommodationMutation,
  updateAccommodation as updateAccommodationMutation,
  batchAddAccommodation as batchAddAccommodationMutation,
} from '../graphql/mutations';
import { getAccommodation as getAccommodationQuery } from '../graphql/queries';
import { upsertAccommodationItem } from '../reducers/eventsSlice';
import { store } from '../store';
import { getAll } from '../utils/getAll';
import notifyBugsnag from '../utils/notifyBugsnag';
import { Optional } from '../globals';

export async function addAccommodation(accommodation: Optional<Accommodation>) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: createAccommodationMutation,
      variables: {
        input: {
          ...accommodation,
          id: uuidv4(),
          createdBy: store.getState().user.profile.id,
          slot: undefined,
          createdAt: now,
          updatedAt: now,
        },
      },
    });

    return response.data.createAccommodation;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function updateAccommodation(accommodation: Optional<Accommodation>) {
  try {
    const now = new Date().toISOString();

    // if(!accommodation.slotId) {
    //   accommodation.slotId = null; // set to null - otherwise it will not update
    // }

    const response: any = await API.graphql({
      query: updateAccommodationMutation,
      variables: {
        input: {
          ...accommodation,
          lastUpdatedBy: store.getState().user.profile.id,
          contractedDays: undefined,
          updatedAt: now,
          slot: undefined,
          event: undefined,
          group: undefined,
          __typename: undefined
        },
      },
    });

    store.dispatch(
      upsertAccommodationItem({ ...accommodation, updatedAt: now })
    );

    return response.data.updateAccommodation;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function removeAccommodation(accommodation: Accommodation) {
  try {
    const now = new Date().toISOString();

    return updateAccommodation({
      ...accommodation,
      deletedBy: store.getState().user.profile.id,
      deletedAt: now,
    });
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getAccommodation(id: string) {
  try {
    const response: any = await API.graphql({
      query: getAccommodationQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return response.data.getAccommodation;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getAccommodations() {
  try {
    const items = await getAll({ query: 'listAccommodations' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function batchAddAccommodations(
  accommodations: Optional<Accommodation>[]
) {
  try {
    const now = new Date().toISOString();
  
    const response: any = await API.graphql({
      query: batchAddAccommodationMutation,
      variables: {
        accommodations: accommodations.map((item) => ({
          ...item,
          createdBy: store.getState().user.profile.id,
        })),
      },
    });

    return response.data.batchAddAccommodation;
  } catch (err) {
    notifyBugsnag(err);
  }
}

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const batchAddPeople = /* GraphQL */ `mutation BatchAddPeople($people: [CreateBatchedPersonInput]) {
  batchAddPeople(people: $people) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddPeopleMutationVariables,
  APITypes.BatchAddPeopleMutation
>;
export const batchAddFlights = /* GraphQL */ `mutation BatchAddFlights($flights: [CreateBatchedFlightInput]) {
  batchAddFlights(flights: $flights) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddFlightsMutationVariables,
  APITypes.BatchAddFlightsMutation
>;
export const batchAddAccommodation = /* GraphQL */ `mutation BatchAddAccommodation(
  $accommodations: [CreateBatchedAccommodationInput]
) {
  batchAddAccommodation(accommodations: $accommodations) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddAccommodationMutationVariables,
  APITypes.BatchAddAccommodationMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    title
    startDate
    endDate
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createGroupCategory = /* GraphQL */ `mutation CreateGroupCategory(
  $input: CreateGroupCategoryInput!
  $condition: ModelGroupCategoryConditionInput
) {
  createGroupCategory(input: $input, condition: $condition) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupCategoryMutationVariables,
  APITypes.CreateGroupCategoryMutation
>;
export const updateGroupCategory = /* GraphQL */ `mutation UpdateGroupCategory(
  $input: UpdateGroupCategoryInput!
  $condition: ModelGroupCategoryConditionInput
) {
  updateGroupCategory(input: $input, condition: $condition) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupCategoryMutationVariables,
  APITypes.UpdateGroupCategoryMutation
>;
export const deleteGroupCategory = /* GraphQL */ `mutation DeleteGroupCategory(
  $input: DeleteGroupCategoryInput!
  $condition: ModelGroupCategoryConditionInput
) {
  deleteGroupCategory(input: $input, condition: $condition) {
    id
    title
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupCategoryMutationVariables,
  APITypes.DeleteGroupCategoryMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    title
    categoryId
    eventId
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createProfileEvent = /* GraphQL */ `mutation CreateProfileEvent(
  $input: CreateProfileEventInput!
  $condition: ModelProfileEventConditionInput
) {
  createProfileEvent(input: $input, condition: $condition) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileEventMutationVariables,
  APITypes.CreateProfileEventMutation
>;
export const updateProfileEvent = /* GraphQL */ `mutation UpdateProfileEvent(
  $input: UpdateProfileEventInput!
  $condition: ModelProfileEventConditionInput
) {
  updateProfileEvent(input: $input, condition: $condition) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileEventMutationVariables,
  APITypes.UpdateProfileEventMutation
>;
export const deleteProfileEvent = /* GraphQL */ `mutation DeleteProfileEvent(
  $input: DeleteProfileEventInput!
  $condition: ModelProfileEventConditionInput
) {
  deleteProfileEvent(input: $input, condition: $condition) {
    id
    owner
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileEventMutationVariables,
  APITypes.DeleteProfileEventMutation
>;
export const createLoginChallengeToken = /* GraphQL */ `mutation CreateLoginChallengeToken(
  $input: CreateLoginChallengeTokenInput!
  $condition: ModelLoginChallengeTokenConditionInput
) {
  createLoginChallengeToken(input: $input, condition: $condition) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLoginChallengeTokenMutationVariables,
  APITypes.CreateLoginChallengeTokenMutation
>;
export const updateLoginChallengeToken = /* GraphQL */ `mutation UpdateLoginChallengeToken(
  $input: UpdateLoginChallengeTokenInput!
  $condition: ModelLoginChallengeTokenConditionInput
) {
  updateLoginChallengeToken(input: $input, condition: $condition) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLoginChallengeTokenMutationVariables,
  APITypes.UpdateLoginChallengeTokenMutation
>;
export const deleteLoginChallengeToken = /* GraphQL */ `mutation DeleteLoginChallengeToken(
  $input: DeleteLoginChallengeTokenInput!
  $condition: ModelLoginChallengeTokenConditionInput
) {
  deleteLoginChallengeToken(input: $input, condition: $condition) {
    id
    email
    createdAt
    expireAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLoginChallengeTokenMutationVariables,
  APITypes.DeleteLoginChallengeTokenMutation
>;
export const createSlot = /* GraphQL */ `mutation CreateSlot(
  $input: CreateSlotInput!
  $condition: ModelSlotConditionInput
) {
  createSlot(input: $input, condition: $condition) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSlotMutationVariables,
  APITypes.CreateSlotMutation
>;
export const updateSlot = /* GraphQL */ `mutation UpdateSlot(
  $input: UpdateSlotInput!
  $condition: ModelSlotConditionInput
) {
  updateSlot(input: $input, condition: $condition) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSlotMutationVariables,
  APITypes.UpdateSlotMutation
>;
export const deleteSlot = /* GraphQL */ `mutation DeleteSlot(
  $input: DeleteSlotInput!
  $condition: ModelSlotConditionInput
) {
  deleteSlot(input: $input, condition: $condition) {
    id
    title
    personId
    person {
      id
      title
      firstName
      lastName
      email
      publications
      country
      city
      phone
      passportFileUrl
      drivingLicenseFileUrl
      dietaryRequirements
      vaccinationStatus
      createdAt
      updatedAt
      deletedAt
      slots {
        items {
          id
          title
          personId
          prManagerStatus
          gooseStatus
          gooseStatusOther
          newToClient
          additionalAccommodation
          gooseNotes
          onSiteCovidTestRequired
          workTripLetterSent
          groupId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    prManagerStatus
    gooseStatus
    gooseStatusOther
    newToClient
    additionalAccommodation
    gooseNotes
    onSiteCovidTestRequired
    workTripLetterSent
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    event {
      id
      title
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSlotMutationVariables,
  APITypes.DeleteSlotMutation
>;
export const createPerson = /* GraphQL */ `mutation CreatePerson(
  $input: CreatePersonInput!
  $condition: ModelPersonConditionInput
) {
  createPerson(input: $input, condition: $condition) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePersonMutationVariables,
  APITypes.CreatePersonMutation
>;
export const updatePerson = /* GraphQL */ `mutation UpdatePerson(
  $input: UpdatePersonInput!
  $condition: ModelPersonConditionInput
) {
  updatePerson(input: $input, condition: $condition) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePersonMutationVariables,
  APITypes.UpdatePersonMutation
>;
export const deletePerson = /* GraphQL */ `mutation DeletePerson(
  $input: DeletePersonInput!
  $condition: ModelPersonConditionInput
) {
  deletePerson(input: $input, condition: $condition) {
    id
    title
    firstName
    lastName
    email
    publications
    country
    city
    phone
    passportFileUrl
    drivingLicenseFileUrl
    dietaryRequirements
    vaccinationStatus
    createdAt
    updatedAt
    deletedAt
    slots {
      items {
        id
        title
        personId
        person {
          id
          title
          firstName
          lastName
          email
          publications
          country
          city
          phone
          passportFileUrl
          drivingLicenseFileUrl
          dietaryRequirements
          vaccinationStatus
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        prManagerStatus
        gooseStatus
        gooseStatusOther
        newToClient
        additionalAccommodation
        gooseNotes
        onSiteCovidTestRequired
        workTripLetterSent
        groupId
        group {
          id
          title
          categoryId
          eventId
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePersonMutationVariables,
  APITypes.DeletePersonMutation
>;
export const createFlight = /* GraphQL */ `mutation CreateFlight(
  $input: CreateFlightInput!
  $condition: ModelFlightConditionInput
) {
  createFlight(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightMutationVariables,
  APITypes.CreateFlightMutation
>;
export const updateFlight = /* GraphQL */ `mutation UpdateFlight(
  $input: UpdateFlightInput!
  $condition: ModelFlightConditionInput
) {
  updateFlight(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightMutationVariables,
  APITypes.UpdateFlightMutation
>;
export const deleteFlight = /* GraphQL */ `mutation DeleteFlight(
  $input: DeleteFlightInput!
  $condition: ModelFlightConditionInput
) {
  deleteFlight(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    flightType
    leavingFrom
    arrivingAt
    departureDate
    arrivalDate
    flightNumber
    includeInTransferList
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightMutationVariables,
  APITypes.DeleteFlightMutation
>;
export const createAccommodation = /* GraphQL */ `mutation CreateAccommodation(
  $input: CreateAccommodationInput!
  $condition: ModelAccommodationConditionInput
) {
  createAccommodation(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccommodationMutationVariables,
  APITypes.CreateAccommodationMutation
>;
export const updateAccommodation = /* GraphQL */ `mutation UpdateAccommodation(
  $input: UpdateAccommodationInput!
  $condition: ModelAccommodationConditionInput
) {
  updateAccommodation(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccommodationMutationVariables,
  APITypes.UpdateAccommodationMutation
>;
export const deleteAccommodation = /* GraphQL */ `mutation DeleteAccommodation(
  $input: DeleteAccommodationInput!
  $condition: ModelAccommodationConditionInput
) {
  deleteAccommodation(input: $input, condition: $condition) {
    id
    slotId
    slot {
      id
      title
      personId
      person {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          nextToken
          __typename
        }
        __typename
      }
      prManagerStatus
      gooseStatus
      gooseStatusOther
      newToClient
      additionalAccommodation
      gooseNotes
      onSiteCovidTestRequired
      workTripLetterSent
      groupId
      group {
        id
        title
        categoryId
        eventId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      eventId
      event {
        id
        title
        startDate
        endDate
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    groupId
    group {
      id
      title
      categoryId
      eventId
      createdAt
      updatedAt
      deletedAt
      __typename
    }
    eventId
    roomType
    notes
    checkinDate
    checkoutDate
    copiedFromFlightId
    createdAt
    updatedAt
    deletedAt
    createdBy
    lastUpdatedBy
    deletedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccommodationMutationVariables,
  APITypes.DeleteAccommodationMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ModelProfileConditionInput
) {
  createProfile(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ModelProfileConditionInput
) {
  updateProfile(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ModelProfileConditionInput
) {
  deleteProfile(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    role
    emailWaitingVerification {
      id
      token
      email
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    eventIds
    events {
      items {
        id
        owner
        eventId
        event {
          id
          title
          startDate
          endDate
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
export const createVerificationToken = /* GraphQL */ `mutation CreateVerificationToken(
  $input: CreateVerificationTokenInput!
  $condition: ModelVerificationTokenConditionInput
) {
  createVerificationToken(input: $input, condition: $condition) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVerificationTokenMutationVariables,
  APITypes.CreateVerificationTokenMutation
>;
export const updateVerificationToken = /* GraphQL */ `mutation UpdateVerificationToken(
  $input: UpdateVerificationTokenInput!
  $condition: ModelVerificationTokenConditionInput
) {
  updateVerificationToken(input: $input, condition: $condition) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVerificationTokenMutationVariables,
  APITypes.UpdateVerificationTokenMutation
>;
export const deleteVerificationToken = /* GraphQL */ `mutation DeleteVerificationToken(
  $input: DeleteVerificationTokenInput!
  $condition: ModelVerificationTokenConditionInput
) {
  deleteVerificationToken(input: $input, condition: $condition) {
    id
    token
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVerificationTokenMutationVariables,
  APITypes.DeleteVerificationTokenMutation
>;

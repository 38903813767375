import { BetaSchemaForm } from '@ant-design/pro-components';
import { FormInstance, Button, message, Form } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { DataType } from '../../globals';
import { EditOutlined } from '@ant-design/icons';
import { defaultFormProps } from '../../utils/defaultFormProps';
import moment from 'moment';

type FieldProps = {
  values: any;
  loading: boolean;
  data: any;
  form?: any;
  options?: any;
};
type Props = {
  item?: any;
  fields: (props: FieldProps) => any;
  data?: any;
  disabled?: boolean;
  successMessage: string;
  action: (values: any) => Promise<any>;
  renderButton?: (
    setFormModalVisible: (isVisible: boolean) => void,
    props: Props
  ) => React.ReactElement;
  options?: any;
};

const FormButton: React.FC<Props> = (props) => {
  const [formLoading, setFormLoading] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [formMounted, setFormMounted] = useState(true);

  // const formRef = useRef<FormInstance>();
  const [form] = Form.useForm();
  const unmountTimerRef = useRef<any>();
  const [item, setItem] = useState(props.item);

  useEffect(() => {
    if (props.item) {
      form.setFieldsValue(props.item);
    }
  }, [props.item]);



  return (
    <>
      {props.renderButton &&
        props.renderButton((isVisible) => {
          clearTimeout(unmountTimerRef.current);
          setFormMounted(isVisible);
          setFormModalVisible(isVisible);

          form.resetFields();
          form.setFieldsValue({...props.item, copyToAccommodationDates: null});

          if (props.item?.departureDate) {
            form.setFieldsValue({
              departureDateAndArrivalDate: [
                moment(props.item?.departureDate, 'DD/MM/YYYY HH:mm'),
                moment(props.item?.arrivalDate, 'DD/MM/YYYY HH:mm'),
              ],
            });
          }
        }, props)}
      {formMounted && (
        <BetaSchemaForm
          {...defaultFormProps(form, formLoading)}
          form={form}
          shouldUpdate={true}
          // onBlur={(e: any) => {
          //   if (e.target.type === 'text') {
          //     setItem((newItem: any) => {
          //       console.log('onBlur', e.target, e.target.id, e.target.value);

          //       return {
          //         ...newItem,
          //         [e.target.id]: e.target.value,
          //       };
          //     });
          //   }
          // }}
          // onValuesChange={(newValue: any) => {
          //   // if ('gooseStatus' in newValue) {
          //   //   setItem((newItem: any) => {
          //   //     return {
          //   //       ...newItem,
          //   //       gooseStatus: newValue.gooseStatus,
          //   //     };
          //   //   });
          //   // }
          //   // if ('copyToAccommodationDates' in newValue) {
          //   //   setItem((newItem: any) => {
          //   //     return {
          //   //       ...newItem,
          //   //       copyToAccommodationDates: newValue.copyToAccommodationDates,
          //   //     };
          //   //   });
          //   // }
          // }}
          visible={formModalVisible}
          onVisibleChange={(isVisible: boolean) => {
            setFormModalVisible(isVisible);
            unmountTimerRef.current = setTimeout(() => {
              setFormMounted(isVisible);
              if (!isVisible) {
                if (props.data?.isCreateForm) {
                  form.resetFields();
                  form.setFieldsValue({
                    slotId: null,
                    groupId: null,
                    copyToAccommodationDates: null,
                  });
                } else {
                  form.setFieldsValue({
                    ...props.item,
                    copyToAccommodationDates: null,
                  });
                }
              }
            }, 1000);
          }}
          onFinish={async (values: Event) => {
            console.log('finish values', values);

            setFormLoading(true);
            try {
              const actionResponse = await props.action({
                id: props.item?.id,
                ...values,
              });
              setFormModalVisible(false);
              setFormLoading(false);
              unmountTimerRef.current = setTimeout(() => {
                setFormMounted(false);

                if (props.data?.isCreateForm) {
                  form.resetFields();
                  form.setFieldsValue({
                    slotId: null,
                    groupId: null,
                  });
                }
              }, 1000);
              setTimeout(() => {
                message.success(props.successMessage);
                if (actionResponse.error) {
                  message.error(actionResponse.error);
                }
              }, 500);
            } catch (e: any) {
              if (e?.response?.data?.message) {
                message.error(e?.response?.data?.message);
              }
              if (e.errors) {
                message.error(e.errors[0].message);
              }

              setFormLoading(false);
            }
          }}
          columns={props.fields({
            values: form.getFieldsValue(),
            loading: formLoading,
            data: props.data,
            form,
            options: props.options,
          })}
        />
      )}
    </>
  );
};

FormButton.defaultProps = {
  renderButton: (setFormModalVisible, props) => (
    <Button
      onClick={() => {
        setFormModalVisible(true);
      }}
      shape="circle"
      // size="small"
      // type="primary"
      icon={<EditOutlined />}
      disabled={props.disabled}
    />
  ),
};

export default FormButton;

import { API } from 'aws-amplify';
import { Accommodation, Flight, Slot } from '../API';
import { v4 as uuidv4 } from 'uuid';

import {
  createSlot as createSlotMutation,
  updateSlot as updateSlotMutation,
} from '../graphql/mutations';
import { getSlot as getSlotQuery } from '../graphql/queries';
import {
  upsertSlotItem,
  updateCurrentGroupSlots,
} from '../reducers/eventsSlice';
import { store } from '../store';
import { getAll } from '../utils/getAll';
import { search } from '../utils/search';
import { addFlight } from './flights';
import { addAccommodation } from './accommodation';
import notifyBugsnag from '../utils/notifyBugsnag';

export async function addSlot({
  createFlights,
  createAccommodation,
  ...slot
}: any) {

  try {
    const now = new Date().toISOString();

    slot.id = uuidv4();

    const response: any = await API.graphql({
      query: createSlotMutation,
      variables: {
        input: { ...slot, person: undefined, createdAt: now, updatedAt: now },
      },
    });
    if (createFlights) {
      await addFlight({
        slotId: slot.id,
        groupId: slot.groupId,
        eventId: slot.eventId,
        flightType: 'outbound',
      } as Flight);

      await addFlight({
        slotId: slot.id,
        groupId: slot.groupId,
        eventId: slot.eventId,
        flightType: 'return',
      } as Flight);
    }

    if (createAccommodation) {
      await addAccommodation({
        slotId: slot.id,
        groupId: slot.groupId,
        eventId: slot.eventId,
        roomType: 'single',
      } as Accommodation);
    }

    store.dispatch(upsertSlotItem({ ...slot, createdAt: now, updatedAt: now }));

    return response.data.createSlot;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function updateSlot(slot: Slot) {
  try {
    const now = new Date().toISOString();
    let input = {
      ...slot,
      updatedAt: now,
      person: undefined,
      __typename: undefined,
      group: undefined,
      event: undefined,
    };

    if (slot.personId === 'removed') {
      input.personId = null;
    }

    const response: any = await API.graphql({
      query: updateSlotMutation,
      variables: {
        input,
      },
    });

    store.dispatch(upsertSlotItem({ ...slot, updatedAt: now }));

    return response.data.updateSlot;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function removeSlot(slot: Slot) {
  try {
    const now = new Date().toISOString();

    return updateSlot({ ...slot, deletedAt: now });
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getSlot(id: string) {
  try {
    const response: any = await API.graphql({
      query: getSlotQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return response.data.getSlot;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getSlots() {
  try {
    const items = await getAll({ query: 'listSlots' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
}

import { ProFormColumnsType } from '@ant-design/pro-components';
import { Accommodation, Flight, Person, Slot } from '../API';
import type { ColumnsType } from 'antd/es/table';

import FormButton from '../components/FormButton';
import { removeSlot, updateSlot } from '../methods/slots';
import DeleteButton from '../components/DeleteButton';
import PersonSelect from '../components/PersonSelect';
import { DataType } from '../globals';
import { FileSearchOutlined } from '@ant-design/icons';
import { Input, Space, Button, InputRef, Tag, Popover } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useState, useRef } from 'react';
import { getColumnSearchProps } from '../utils/getColumnSearchProps';
import showEditActions from '../utils/showEditActions';
import { Link } from 'react-router-dom';
import ImagePreview from '../components/ImagePreview';
import { defaultSpecificationItem } from '../utils/exportConfig';

const STRING_TO_FORCE_TO_END = 'xxxxxxxxxxxxxxxxx';

type DataItem = {
  name: string;
  state: string;
};

type Props = {
  initialValues?: any;
  loading: boolean;
  data?: any;
  options?: any;
};

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  initialValues,
  loading,
  data,
  options,
}) => {
  let eventId = data?.eventId;

  const optionsHolder: any = {};
  if (options) {
    Object.keys(options).forEach((key) => {
      optionsHolder[key] = {};
      // if (options[key]?.length > 0) { // check if array - default is object for some reason
        options[key]?.forEach((item: any) => {
          if (!optionsHolder[key][item.eventId]) {
            optionsHolder[key][item.eventId] = {};
          }
          optionsHolder[key][item.eventId][item.id] = { text: item.title };
        });
      // }
    });
  }

  return [
    {
      title: 'Group',
      dataIndex: 'groupId',
      dependencies: ['slot'],
      fieldProps: (form) => ({
        placeholder: 'Select',
        disabled: loading,
        onChange: (value: any) => {
          form?.setFieldsValue({
            slotId: null,
            slot: null,
            groupId: value,
            selectedRows: [],
          });
        },
      }),
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().groupId || initialValues?.groupId,
        rules: [
          {
            required: true,
          },
        ],
      }),
      valueType: 'select',
      valueEnum: optionsHolder.groups[eventId],
      width: 200,
    },
    {
      title: 'Slot #',
      dataIndex: 'title',
      dependencies: ['groupId'],
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.title,
        rules: [
          {
            required: true,
          },
        ],
      }),
    },
    {
      title: 'Person',
      key: 'personId',
      initialValue: initialValues?.personId,
      renderFormItem: (schema, config) => <PersonSelect />,
      transform: (value) => {
        return { personId: value };
      },
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    },

    {
      title: 'PR Manager Status',
      dataIndex: 'prManagerStatus',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.prManagerStatus,
      }),
      valueType: 'select',
      valueEnum: {
        invitationSentbyCentral: { text: 'Invitation Sent by Central' },
        acceptedPleaseContact: { text: 'Accepted Please Contact' },
      },
      width: 200,
    },

    {
      valueType: 'dependency',
      name: [
        'gooseStatus',
        'gooseStatusOther',
      ],
      dataIndex: 'gooseStatusHidden',
      columns: (stuff) => {
        return [
          {
            title: 'Goose Status',
            dataIndex: 'gooseStatus',
            fieldProps: { placeholder: '', disabled: loading },
            formItemProps: (form) => ({
              initialValue: form?.getFieldsValue()?.gooseStatus,
            }),
            valueType: 'select',
            valueEnum: {
              acceptanceReceived: { text: 'Acceptance Received' },
              flightsRequested: { text: 'Flights Requested' },
              flightOptionSent: { text: 'Flight Option Sent' },
              flightOptionBooked: { text: 'Flight Option Booked' },
              eticketSent: { text: 'E-ticket Sent' },
              other: { text: 'Other' },
            },
            width: 200,
          },
          {
            title: 'Other Status',
            dataIndex: 'gooseStatusOther',
            hideInForm: stuff?.gooseStatus !== 'other',
            ignoreFormItem: stuff?.gooseStatus !== 'other',
            fieldProps: { placeholder: '', disabled: loading },
            formItemProps: (form) => ({
              initialValue: form?.getFieldsValue()?.gooseStatusOther,
            }),
          },
        ];
      },
    },

    {
      title: 'New to Client?',
      dataIndex: 'newToClient',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.newToClient,
      }),
      valueType: 'switch',
    },
    {
      title: 'Additional Accommodation',
      dataIndex: 'additionalAccommodation',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.additionalAccommodation,
      }),
    },
    {
      title: 'Goose Notes',
      dataIndex: 'gooseNotes',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.gooseNotes,
      }),
      valueType: 'textarea',
    },

    {
      title: 'On Site COVID Test Required?',
      dataIndex: 'onSiteCovidTestRequired',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.onSiteCovidTestRequired,
      }),
      valueType: 'switch',
    },
    {
      title: 'Work Trip Letter Sent?',
      dataIndex: 'workTripLetterSent',
      fieldProps: { placeholder: '', disabled: loading },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.workTripLetterSent,
      }),
    },
    {
      hideInForm: !data?.isCreateForm,
      title: 'Create Flights',
      dataIndex: 'createFlights',
      fieldProps: { placeholder: '', disabled: loading },
      valueType: 'switch',
    },
    {
      hideInForm: !data?.isCreateForm,
      title: 'Create Accommodation',
      dataIndex: 'createAccommodation',
      fieldProps: { placeholder: '', disabled: loading },
      valueType: 'switch',
    },
  ];
};

const gooseStatuses: any = {};
gooseStatuses['acceptanceReceived'] = 'Acceptance Received';
gooseStatuses['flightsRequested'] = 'Flights Requested';
gooseStatuses['flightOptionSent'] = 'Flight Option Sent';
gooseStatuses['flightOptionBooked'] = 'Flight Option Booked';
gooseStatuses['eticketSent'] = 'E-ticket Sent';
gooseStatuses['other'] = 'Other';

const prManagerStatuses: any = {};
prManagerStatuses['invitationSentbyCentral'] = 'Invitation Sent by Central';
prManagerStatuses['acceptedPleaseContact'] = 'Accepted Please Contact';

export const columns: (params: {
  currentView?: 'grouped' | 'all';
  isPersonView?: boolean;
  options?: any;
  form?: any;
  eventId?: string;
  groupId?: string;
  data?:any;
}) => ColumnsType<DataType> = ({
  currentView = 'grouped',
  options,
  isPersonView,
  data
}) => {
  const items: ColumnsType<any> = [
    {
      hidden: !isPersonView,
      title: 'Event',
      dataIndex: ['event', 'title'],
      sorter: (a: any, b: any) =>
        (a.event.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.event.title || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['event', 'title'], 'Event'),
    },
    {
      hidden: currentView === 'grouped' && !isPersonView,
      title: 'Group',
      dataIndex: ['group', 'title'],
      sorter: (a: any, b: any) =>
        (a.groupId || STRING_TO_FORCE_TO_END).localeCompare(
          b.groupId || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['group', 'title'], 'Group'),
    },
    {
      // title: () => (
      //   <div
      //     style={{
      //       display: 'flex',
      //       flex: 1,
      //       flexDirection: 'row',
      //       alignItems:'center',
      //       justifyContent: 'center'
      //     }}
      //   >
      //     <div style={{ flex: 1 }}>Slot # Search</div>
      //     <div style={{ display: 'flex' }}><SearchOutlined /></div>
      //   </div>
      // ),
      title: '#',
      sorter: (a: any, b: any) => {
        let aSlotTitle = a.title || '999999999999999999';
        let bSlotTitle = b.title || '999999999999999999';

        return parseFloat(aSlotTitle) - parseFloat(bSlotTitle);
      },
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps(['title'], 'Title'),
      // filterIcon: (filtered: boolean) => (
      //   <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      // ),
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => {
      //   return (
      //     <div style={{ padding: 8 }}>
      //       <Input
      //         placeholder={`Search`}
      //         style={{ marginBottom: 8, display: 'block' }}
      //       />
      //       <Space>
      //         <Button
      //           type="primary"
      //           icon={<SearchOutlined />}
      //           size="small"
      //           onClick={() => {
      //             setSelectedKeys(['title']);
      //             confirm({ closeDropdown: true });
      //           }}
      //           style={{ width: 90 }}
      //         >
      //           Search
      //         </Button>
      //         <Button size="small" style={{ width: 90 }}>
      //           Reset
      //         </Button>
      //       </Space>
      //     </div>
      //   );
      // },
      dataIndex: 'title',
      fixed: 'left',
      width: 75,

      // width: '20%',
    },
    {
      title: 'Title',
      dataIndex: ['person', 'title'],
      ...getColumnSearchProps(['person', 'title'], 'Title'),
      sorter: (a: any, b: any) =>
        (a.person.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.person.title || STRING_TO_FORCE_TO_END
        ),
      width: 100,
      hidden: isPersonView,
    },
    {
      title: 'Full Name',
      ...getColumnSearchProps(null, 'Full Name'),
      onFilter: (value: any, record: any) => {
        return `${record.person?.firstName} ${record.person?.lastName}`
          .toLowerCase()
          .replace(/ /g, '')
          .includes((value as string).toLowerCase().replace(/ /g, ''));
      },
      sorter: (a: any, b: any) =>
        (a.person
          ? `${a.person?.firstName} ${a.person?.lastName}`
          : STRING_TO_FORCE_TO_END
        ).localeCompare(
          a.person
            ? `${b.person?.firstName} ${b.person?.lastName}`
            : STRING_TO_FORCE_TO_END
        ),
      width: 200,
      hidden: isPersonView,
      render(value, record: any, index) {
        return (
          <Link to={`/people/${record?.person?.id}`}>
            {record?.person?.firstName} {record?.person?.lastName}
          </Link>
        );
      },
    },

    {
      title: 'Publications',
      dataIndex: ['person', 'publications'],
      ...getColumnSearchProps(['person', 'publications'], 'Publications'),
      sorter: (a: any, b: any) =>
        (a.person?.publications || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.publications || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      hidden: isPersonView,
    },
    {
      title: 'PR Manager Status',
      dataIndex: ['prManagerStatus'],
      ...getColumnSearchProps(['prManagerStatus'], 'PR Manager Status'),
      sorter: (a: any, b: any) =>
        (a.prManagerStatus || STRING_TO_FORCE_TO_END).localeCompare(
          b.prManagerStatus || STRING_TO_FORCE_TO_END
        ),
      render(item) {
        return item ? (
          <Tag
            bordered={false}
            color={
              item === 'invitationSentbyCentral'
                ? 'processing'
                : item === 'acceptedPleaseContact'
                ? 'success'
                : 'default'
            }
          >
            {prManagerStatuses[item]}
          </Tag>
        ) : null;
      },
      width: 200,
    },
    {
      title: 'Goose Status',
      dataIndex: 'gooseStatus',
      ...getColumnSearchProps(['gooseStatus'], 'Goose Status'),
      sorter: (a: any, b: any) =>
        (a?.gooseStatus || STRING_TO_FORCE_TO_END).localeCompare(
          b?.gooseStatus || STRING_TO_FORCE_TO_END
        ),
      render(item, record) {
        if (item) {
          if (item === 'other') {
            return record.gooseStatusOther;
          }
          return gooseStatuses[item];
        }
        return null;
      },
      width: 200,
    },
    {
      title: 'Goose Notes',
      dataIndex: 'gooseNotes',
      ...getColumnSearchProps(['gooseNotes'], 'Goose Notes'),
      sorter: (a: any, b: any) =>
        (a.gooseNotes || STRING_TO_FORCE_TO_END).localeCompare(
          b.gooseNotes || STRING_TO_FORCE_TO_END
        ),
      width: 160,
      render: (item) => {
        if (item) {
          return (
            (item.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Popover
                  content={
                    <span style={{ display: 'block', maxWidth: 300 }}>
                      {item}
                    </span>
                  }
                  trigger="click"
                >
                  <Button shape="circle" icon={<FileSearchOutlined />} />
                </Popover>
              </div>
            )) ||
            null
          );
        }
        return null;
      },
    },
    {
      title: 'Country',
      dataIndex: ['person', 'country'],
      ...getColumnSearchProps(['person', 'country'], 'Country'),
      sorter: (a: any, b: any) =>
        (a.person?.country || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.country || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      hidden: isPersonView,
    },
    {
      title: 'City',
      dataIndex: ['person', 'city'],
      ...getColumnSearchProps(['person', 'city'], 'City'),
      sorter: (a: any, b: any) =>
        (a.person?.city || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.city || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      hidden: isPersonView,
    },
    {
      title: 'Email Address',
      dataIndex: ['person', 'email'],
      ...getColumnSearchProps(['person', 'email'], 'Email Address'),
      sorter: (a: any, b: any) =>
        (a.person?.email || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.email || STRING_TO_FORCE_TO_END
        ),
      render(email) {
        return email ? <a href={`mailto:${email}`}>{email}</a> : null;
      },
      width: 250,
      hidden: isPersonView,
    },
    {
      title: 'Mobile No.',
      dataIndex: ['person', 'phone'],
      ...getColumnSearchProps(['person', 'phone'], 'Mobile No.'),
      sorter: (a: any, b: any) =>
        (a.person?.phone || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.phone || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      hidden: isPersonView,
    },
    {
      title: 'Passport',
      dataIndex: ['person', 'passportFileUrl'],
      // ...getColumnSearchProps(['person', 'passportFileUrl'], 'Passport'),
      sorter: (a: any, b: any) =>
        (a.person?.passportFileUrl || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.passportFileUrl || STRING_TO_FORCE_TO_END
        ),
      render(value) {
        if (!value) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImagePreview src={value} />
          </div>
        );
      },
      width: 150,
      hidden: isPersonView,
    },
    {
      title: 'Dietary Requirements',
      dataIndex: ['person', 'dietaryRequirements'],
      ...getColumnSearchProps(
        ['person', 'dietaryRequirements'],
        'Dietary Requirements'
      ),
      sorter: (a: any, b: any) =>
        (a.person?.dietaryRequirements || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.dietaryRequirements || STRING_TO_FORCE_TO_END
        ),
      width: 220,
    },
    {
      title: 'Vaccination Status',
      dataIndex: ['person', 'vaccinationStatus'],
      ...getColumnSearchProps(
        ['person', 'vaccinationStatus'],
        'Vaccination Status'
      ),
      sorter: (a: any, b: any) =>
        (a.person?.vaccinationStatus || STRING_TO_FORCE_TO_END).localeCompare(
          b.person?.vaccinationStatus || STRING_TO_FORCE_TO_END
        ),
      width: 220,
      hidden: isPersonView,
    },

    // {
    //   title: 'Departure Airport',
    //   dataIndex: 'leavingFrom',
    //   ...getColumnSearchProps(['leavingFrom'], 'Departure Airport'),
    //   sorter: true,
    //         width: 150,

    // },
    {
      title: 'New to Client?',
      dataIndex: 'newToClient',
      ...getColumnSearchProps(['newToClient'], 'New to Client?'),
      sorter: (a: any, b: any) =>
        (a.newToClient ? 1 : 0) - (b.newToClient ? 1 : 0),
      render(value, record, index) {
        return value ? 'Yes' : 'No';
      },
      width: 175,
    },
    {
      title: 'Add Accommodation',
      dataIndex: 'additionalAccommodation',
      ...getColumnSearchProps(['additionalAccommodation'], 'Add Accommodation'),
      sorter: (a: any, b: any) =>
        (a.additionalAccommodation || STRING_TO_FORCE_TO_END).localeCompare(
          b.additionalAccommodation || STRING_TO_FORCE_TO_END
        ),
      width: 200,
    },

    {
      title: 'On Site COVID Test Required?',
      dataIndex: 'onSiteCovidTestRequired',
      ...getColumnSearchProps(
        ['onSiteCovidTestRequired'],
        'On Site COVID Test Required?'
      ),
      sorter: (a: any, b: any) =>
        (a.onSiteCovidTestRequired ? 1 : 0) -
        (b.onSiteCovidTestRequired ? 1 : 0),
      render(value, record, index) {
        return value ? 'Yes' : 'No';
      },
      width: 260,
    },
    {
      title: 'Work Trip Letter Sent?',
      dataIndex: 'workTripLetterSent',
      ...getColumnSearchProps(['workTripLetterSent'], 'Work Trip Letter Sent?'),
      sorter: (a: any, b: any) =>
        (a.workTripLetterSent || STRING_TO_FORCE_TO_END).localeCompare(
          b.workTripLetterSent || STRING_TO_FORCE_TO_END
        ),
      width: 210,
    },
  ];

  if (showEditActions()) {
    items.push({
      key: 'operation',
      fixed: 'right',
      width: 102,
      render: (item) => {
        return (
          <Space style={{ float: 'right' }}>
            <FormButton
              fields={fields}
              item={item}
              data={{ ...data, eventId: item.eventId }}
              successMessage="Slot updated"
              action={updateSlot}
              options={options}
            />
            <DeleteButton
              item={item}
              successMessage="Slot removed"
              action={removeSlot}
            />
          </Space>
        );
      },
    });
  }

  return items;
};

export const specification = [
  { key: 'slotNumber', label: 'Slot #', width: 40 },
  { key: 'title', label: 'Title', width: 40 },
  { key: 'firstName', label: 'First Name', width: 40 },
  { key: 'lastName', label: 'Last Name', width: 40 },
  { key: 'publications', label: 'Publications', width: 40 },
  { key: 'country', label: 'Country', width: 40 },
  { key: 'city', label: 'City', width: 40 },
  { key: 'email', label: 'Email Address', width: 40 },
  { key: 'phone', label: 'Mobile No.', width: 40 },
  { key: 'dietaryRequirements', label: 'Dietary Requirements', width: 40 },
  { key: 'vaccinationStatus', label: 'Vaccination Status', width: 40 },
  { key: 'prManagerStatus', label: 'PR Manager Status', width: 40 },
  { key: 'gooseStatus', label: 'Goose Status', width: 40 },
  { key: 'gooseStatusOther', label: 'Goose Status - Other', width: 40 },
  { key: 'newToClient', label: 'New to Client?', width: 40 },
  { key: 'additionalAccommodation', label: 'Add Accommodation', width: 40 },
  { key: 'gooseNotes', label: 'Goose Notes', width: 40 },
  {
    key: 'onSiteCovidTestRequired',
    label: 'On Site COVID Test Required?',
    width: 40,
  },
  { key: 'workTripLetterSent', label: 'Work Trip Letter Sent?', width: 40 },
];

export const transformKeys = {
  title: 'slotNumber',
  'person.publications': 'publications',
  'person.country': 'country',
  'person.city': 'city',
  'person.email': 'email',
  'person.phone': 'phone',
  'person.dietaryRequirements': 'dietaryRequirements',
  'person.vaccinationStatus': 'vaccinationStatus',
  'person.title': 'title',
  'person.firstName': 'firstName',
  'person.lastName': 'lastName',
};

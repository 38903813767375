import { API } from 'aws-amplify';
import { Person } from '../API';
import { Optional } from '../globals';

import {
  createPerson as createPersonMutation,
  updatePerson as updatePersonMutation,
  batchAddPeople as batchAddPeopleMutation,
} from '../graphql/mutations';
import { getPerson as getPersonQuery } from '../graphql/queries';
import { getAll } from '../utils/getAll';
import notifyBugsnag from '../utils/notifyBugsnag';
import { updateCurrentPerson } from '../reducers/eventsSlice';
import { store } from '../store';

export async function addPerson(person: Person) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: createPersonMutation,
      variables: {
        input: { ...person, createdAt: now, updatedAt: now },
      },
    });

    return response.data.createPerson;
  } catch (err) {
    notifyBugsnag(err);
  }
}
export async function batchAddPeople(people: Optional<Person>[]) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: batchAddPeopleMutation,
      variables: {
          people: people.map((item) => ({
            ...item,
          })),
      },
    });

    return response.data.batchAddPeople;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function updatePerson(person: Person) {
  try {
    const now = new Date().toISOString();
    const response: any = await API.graphql({
      query: updatePersonMutation,
      variables: {
        input: { ...person, updatedAt: now , __typename: undefined, slots: undefined},
      },
    });

    
    store.dispatch(updateCurrentPerson(response.data.updatePerson));
    return response.data.updatePerson;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function removePerson(person: Person) {
  try {
    const now = new Date().toISOString();

    return updatePerson({ ...person, deletedAt: now });
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getPerson(id: string) {
  try {
    const response: any = await API.graphql({
      query: getPersonQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return response.data.getPerson;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getPersons() {
  try {
    const items = await getAll({ query: 'listPeople' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
}

import { API } from 'aws-amplify';
import { Event } from '../globals';

import {
  createEvent as createEventMutation,
  updateEvent as updateEventMutation,
} from '../graphql/mutations';
import { getEvent as getEventQuery } from '../graphql/queries';
import { getAll } from '../utils/getAll';
import notifyBugsnag from '../utils/notifyBugsnag';
import { addGroup, getGroupsByEventId } from './groups';
import {updateEventItem,addEventItem} from '../reducers/userSlice';
import { store } from '../store';
import { updateEventMeta } from '../reducers/eventsSlice';

export async function addEvent(event: Event) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: createEventMutation,
      variables: {
        input: { ...event, createdAt: now, updatedAt: now },
      },
    });

    await Promise.all(
      ['Board', 'Host'].map(async (title) => {
        await addGroup({
          title,
          eventId: response.data.createEvent.id as string,
          categoryId: '2d49e07d-4685-42e5-9661-685e85f43b1d',
        });
      })
    );

    const profileEvent = await API.put('GooseRest', `/team/add-event`, {
      body: { eventId: response.data.createEvent.id },
    });
    console.log('profileEvent',profileEvent);

    store.dispatch(updateEventMeta({ id: event.id, meta: { groupCount: 2 } }));
    store.dispatch(addEventItem({...profileEvent, event:response.data.createEvent} as any));
    

    return response.data.createEvent;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function updateEvent(event: Event) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: updateEventMutation,
      variables: {
        input: {
          ...event,
          groupCount: undefined,
          updatedAt: now,
          __typename: undefined,
        },
      },
    });
    store.dispatch(updateEventItem({ ...event, updatedAt: now } as any));
    return response.data.updateEvent;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export const getEvent: (id: string) => Promise<Event> = async (id) => {
  try {
    const response: any = await API.graphql({
      query: getEventQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return { ...response.data.getEvent };
  } catch (err) {
    notifyBugsnag(err);
  }
};

export const getEvents: () => Promise<Event[]> = async () => {
  try {
    const items = await getAll({ query: 'listEvents' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
};

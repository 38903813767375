import { Select } from 'antd';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { slotsByGroupId } from '../../graphql/queries';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updatePeople, upsertPerson } from '../../reducers/eventsSlice';

const { Option } = Select;

type Props = {
  // onChange: (value:string) => void
  onSelect?: (value: any) => void;
  onChange?: <T = any>(item?: T) => void;
  value?: any;
  groupId?: any;
  disabled?: boolean
};

const SlotSelect: React.FC<Props> = (props) => {
  const [data, setData] = useState<any[]>([]);

  const [value, setValue] = useState<any>(props.value);

  const handleChange = (newValue: any, itemsToCheck: any) => {

    setValue(newValue);

    const targetItem = itemsToCheck?.find(({ id }: any) => id === newValue);

    if (targetItem) {
      props.onChange && props.onChange(targetItem);
    }
  };

  const onSearch = (value: string) => {
  };
  useEffect(() => {
    console.log('props.value', props.value, value);
    if(props.value?.id !== value?.id) {
    setValue(props.value);
    }
  }, [props.value]);
  useEffect(() => {
    const getItems = async () => {
      if(props.groupId) {
      const items: any = await API.graphql({
        query: slotsByGroupId,
        variables: {
          groupId: props.groupId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      setData(
        items.data['slotsByGroupId'].items.filter((o: any) => !o.deletedAt)
      );
      handleChange(
        props.value,
        items.data['slotsByGroupId'].items.filter((o: any) => !o.deletedAt)
      );
      }
    };
    getItems();
    
  }, [props.groupId]);
  return (
    <Select
      showSearch
      value={data?.length > 0 ? value?.id : null}
      placeholder="Select a slot"
      optionFilterProp="title"
      onChange={(value) => {
        handleChange(value, data);
      }}
      onSearch={onSearch}
      filterOption={(input, option) => {
        return (option!.label as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      notFoundContent={null}
      size="large"
      disabled={props.disabled}
      style={{ width: 300 }}
      options={data?.map(({ id, title }: any) => ({
        label: title,
        value: id,
      }))}
    />
  );
};

export default SlotSelect;

import _ from 'lodash';

const orderGroups = (groups: any[]) => {
  const mediaGroups = _.orderBy(
    groups.filter(
      (o) => o.categoryId === '0728a64c-2495-459d-b29d-a2addb91cccd'
    ),
    (item) => item.title
  );
  const otherGroups = _.orderBy(
    groups.filter(
      (o) => o.categoryId === '2d49e07d-4685-42e5-9661-685e85f43b1d'
    ),
    (item) => item.title
  );

  return [...mediaGroups, ...otherGroups];
};

export default orderGroups;

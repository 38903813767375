export const listPeopleCustom = /* GraphQL */ `
  query ListPeopleCustom(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        firstName
        lastName
        email
        publications
        country
        city
        phone
        passportFileUrl
        drivingLicenseFileUrl
        dietaryRequirements
        vaccinationStatus
        createdAt
        updatedAt
        deletedAt
        slots {
          items {
            id
            title
            personId
            prManagerStatus
            gooseStatus
            gooseStatusOther
            newToClient
            additionalAccommodation
            gooseNotes
            onSiteCovidTestRequired
            workTripLetterSent
            groupId
            eventId
            event {
              id
              title
              startDate
              endDate
              createdAt
              updatedAt
              deletedAt
              __typename
            }
            createdAt
            updatedAt
            deletedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listProfilesCustom = /* GraphQL */ `
  query ListProfilesCustom(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        role
        emailWaitingVerification {
          id
          token
          email
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        deletedAt
        eventIds
        events {
          items {
            id
            owner
            eventId
            createdAt
            updatedAt
            deletedAt
            __typename
            event {
              id
              title
              startDate
              endDate
              createdAt
              updatedAt
              deletedAt
              __typename
            }
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React, { useState } from 'react';

import { ProFormColumnsType } from '@ant-design/pro-components';
import { Accommodation, Flight, Person, Slot } from '../API';
import type { ColumnsType } from 'antd/es/table';

import FormButton from '../components/FormButton';
import DeleteButton from '../components/DeleteButton';
import { removeFlight, updateFlight } from '../methods/flights';
import { DataType, Optional } from '../globals';
import { getColumnSearchProps } from '../utils/getColumnSearchProps';
import showEditActions from '../utils/showEditActions';
import SlotSelect from '../components/SlotSelect';
import moment from 'moment';
import {
  CheckCircleOutlined,
  CalendarOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Form, Popover, Space, Table, Tag } from 'antd';
import styles, { defaultSpecificationItem } from '../utils/exportConfig';
import dayjs from 'dayjs';
import type { TableColumnsType } from 'antd';

import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import { Link } from 'react-router-dom';
import ReduxDataProvider from '../providers/ReduxDataProvider';
import DataProvider from '../providers/DataProvider';

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const STRING_TO_FORCE_TO_END = 'xxxxxxxxxxxxxxxxx';

type DataItem = {
  name: string;
  state: string;
};

type FlightWithGroup = Flight & { groupId?: string; checkInOutDates?: any[] };

type Props = {
  initialValues?: any;
  loading: boolean;
  data?: any;
  options?: any;
};

const selectColumns: TableColumnsType<any> = [
  { title: 'Slot #', dataIndex: 'slot', render: (item) => item?.title },
  {
    title: 'Room Type',
    dataIndex: 'roomType',
    // render: (text: string) => <a>{text}</a>,
  },
  {
    title: 'Checkin',
    dataIndex: 'checkinDate',
    render(value, record, index) {
      if (!value) {
        return null;
      }
      const comp = (
        <span>
          {moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
        </span>
      );

      if (record.copiedFromFlightId) {
        return (
          <Popover content="Copied from Flight Dates" placement="top">
            <Space>
              {comp}
              <CalendarOutlined />
            </Space>
          </Popover>
        );
      }

      return comp;
    },
  },
  {
    title: 'Checkout',
    dataIndex: 'checkoutDate',
    render(value, record, index) {
      if (!value) {
        return null;
      }
      const comp = (
        <span>
          {moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
        </span>
      );

      if (record.copiedFromFlightId) {
        return (
          <Popover content="Copied from Flight Dates" placement="top">
            <Space>
              {comp}
              <CalendarOutlined />
            </Space>
          </Popover>
        );
      }

      return comp;
    },
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    render: (item) => {
      if (item) {
        return (
          (item.length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Popover
                content={
                  <span style={{ display: 'block', maxWidth: 300 }}>
                    {item}
                  </span>
                }
                trigger="click"
              >
                <Button shape="circle" icon={<FileSearchOutlined />} />
              </Popover>
            </div>
          )) ||
          null
        );
      }
      return null;
    },
  },
];

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  initialValues,
  loading,
  data,
  options,
}) => {
  let eventId = data?.eventId;

  const optionsHolder: any = {};
  if (options) {
    Object.keys(options).forEach((key) => {
      optionsHolder[key] = {};
      // if (options[key]?.length > 0) { // check if array - default is object for some reason
      options[key]?.forEach((item: any) => {
        if (!optionsHolder[key][item.eventId]) {
          optionsHolder[key][item.eventId] = {};
        }
        optionsHolder[key][item.eventId][item.id] = { text: item.title };
      });
      // }
    });
  }
  return [
    {
      title: 'Group',
      dataIndex: 'groupId',
      dependencies: ['slot'],
      fieldProps: (form) => ({
        placeholder: 'Select',
        disabled: loading,
        onChange: (value: any) => {
          form?.setFieldsValue({
            slotId: null,
            slot: null,
            groupId: value,
            selectedRows: [],
          });
        },
      }),
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().groupId || initialValues?.groupId,
        rules: [
          {
            required: true,
          },
        ],
      }),
      valueType: 'select',
      valueEnum: optionsHolder.groups[eventId],
      width: 200,
    },
    {
      title: 'Slot #',
      key: 'slot',
      dependencies: ['groupId'],
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().slot,
      }),
      renderFormItem: (schema, config, form) => {
        const formValues = form.getFieldsValue();
        return (
          <SlotSelect
            groupId={formValues?.groupId}
            disabled={loading || !formValues?.groupId}
          />
        );
      },
      transform: (value) => {
        return { slotId: value?.id, slot: value };
      },
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    },
    {
      title: 'Flight Type',
      dataIndex: 'flightType',
      fieldProps: {
        placeholder: '',
        disabled: loading,
      },
      valueType: 'select',
      valueEnum: {
        outbound: { text: 'Outbound' },
        return: {
          text: 'Return',
        },
      },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue().flightType || 'outbound',
        rules: [
          {
            required: true,
          },
        ],
      }),
    },

    {
      title: 'Flight Number',
      dataIndex: 'flightNumber',
      fieldProps: {
        placeholder: '',
        disabled: loading,
        className: 'uppercaseInput',
      },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.flightNumber,
      }),
    },

    {
      title: 'Leaving From',
      dataIndex: 'leavingFrom',
      fieldProps: {
        placeholder: '',
        disabled: loading,
        className: 'uppercaseInput',
      },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.leavingFrom,
      }),
    },

    {
      title: 'Arriving At',
      dataIndex: 'arrivingAt',
      fieldProps: {
        placeholder: '',
        disabled: loading,
        className: 'uppercaseInput',
      },
      formItemProps: (form) => ({
        initialValue: form?.getFieldsValue()?.arrivingAt,
      }),
    },
    {
      valueType: 'dependency',
      name: [
        'copyToAccommodationDates',
        'flightType',
        'departureDate',
        'arrivalDate',
        'slotId',
        'slot',
        'groupId',
        'checkinDate',
        'checkoutDate',
        'createOrUpdateAccommodation',
        'roomType',
        'notes',
      ],
      dataIndex: 'departureDateAndArrivalDateHidden',

      columns: (stuff) => {
        return [
          {
            title: 'Departure & Arrival Date',
            dataIndex: 'departureDateAndArrivalDate',
            transform: (value) => {
              return {
                departureDate: moment(value[0])
                  .second(0)
                  .format('DD/MM/YYYY HH:mm'),
                arrivalDate: moment(value[1])
                  .second(0)
                  .format('DD/MM/YYYY HH:mm'),
              };
            },
            fieldProps: (form, config) => ({
              onChange: (value: any) => {
                // if(!stuff?.copyToAccommodationDates) {

                let fieldToUpdate = 'checkinDate';
                let index = 1;

                if (stuff.flightType === 'return') {
                  fieldToUpdate = 'checkoutDate';
                  index = 0;
                }

                form?.setFieldsValue({
                  // [fieldToUpdate]: moment(
                  // form.getFieldsValue()?.departureDateAndArrivalDate[index]
                  // ).format('DD/MM/YYYY HH:mm'),
                  checkinAndOutDates: moment(
                    form.getFieldsValue()?.departureDateAndArrivalDate[index]
                  ).format('DD/MM/YYYY HH:mm'),
                });

                // }
              },
            }),

            renderFormItem: () => {
              return (
                <MyDatePicker.RangePicker
                  showTime
                  format={'DD/MM/YYYY HH:mm'}
                  placeholder={['Start', 'End']}
                  defaultValue={
                    stuff?.departureDate
                      ? [
                          moment(stuff?.departureDate, 'DD/MM/YYYY HH:mm'),
                          moment(stuff?.arrivalDate, 'DD/MM/YYYY HH:mm'),
                        ]
                      : [null, null]
                  }
                />
              );
            },
          },
          {
            title: (form) => {
              return `Copy ${
                stuff.flightType === 'outbound' ? 'Arrival' : 'Departure'
              } Date to Accommodation ${
                stuff.flightType === 'outbound' ? 'Check In' : 'Check Out'
              } Date`;
            },
            dataIndex: 'copyToAccommodationDates',
            fieldProps: (form) => {
              console.log('stufffffffffff', stuff);
              return {
                defaultValue: false,
                disabled:
                  loading ||
                  (!(stuff?.departureDate && stuff?.arrivalDate) &&
                    !form.getFieldsValue()?.departureDateAndArrivalDate),
                onChange: (value: any) => {
                  if (value) {
                    let fieldToUpdate = 'checkinDate';
                    let index = 1;

                    if (stuff.flightType === 'return') {
                      fieldToUpdate = 'checkoutDate';
                      index = 0;
                    }

                    form?.setFieldsValue({
                      createOrUpdateAccommodation: 'create',
                      // [fieldToUpdate]: moment(
                      //   form.getFieldsValue()?.departureDateAndArrivalDate[index]
                      // ).format('DD/MM/YYYY HH:mm'),
                      checkinAndOutDates: moment(
                        form.getFieldsValue()?.departureDateAndArrivalDate[
                          index
                        ]
                      ),
                    });
                  }
                },
              };
            },
            valueType: 'switch',
          },
          {
            hideInForm: !stuff.copyToAccommodationDates,
            title: 'Create or update existing accommodation',
            instruction: 'Create or update existing accommodation',
            dataIndex: 'createOrUpdateAccommodation',
            fieldProps: (form) => ({
              defaultValue: 'create',
              disabled: loading,
              placeholder: '',
            }),
            valueType: 'select',
            valueEnum: {
              create: { text: 'Create New' },
              update: {
                text: 'Update Existing',
              },
            },
          },
          {
            hideInForm:
              !stuff.copyToAccommodationDates ||
              stuff.createOrUpdateAccommodation !== 'update',
            title: (form) => {
              return `Replace ${
                stuff.flightType === 'outbound' ? 'Check In' : 'Check Out'
              } Dates`;
            },
            dataIndex: 'selectedRows',
            renderFormItem: (schema, config, form) => {
              let query = 'accommodationByGroupId';
              let variables: any = { groupId: form.getFieldsValue()?.groupId };

              if (stuff?.slotId) {
                query = 'accommodationBySlotId';
                variables.slotId = stuff?.slotId;
              }

              return (
                <DataProvider
                  query={query}
                  variables={variables}
                  keysToWatch={['slotId', 'groupId']}
                >
                  {({ data, loading }) => (
                    <Table
                      rowKey={(record) => record.id}
                      loading={loading}
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (
                          selectedRowKeys: React.Key[],
                          selectedRows: any[]
                        ) => {
                          form?.setFieldsValue({ selectedRows });
                        },
                        getCheckboxProps: (record: any) => ({
                          // disabled: record.name === 'Disabled User', // Column configuration not to be checked
                          name: record.roomType,
                        }),
                      }}
                      columns={selectColumns}
                      dataSource={data}
                      pagination={false}
                    />
                  )}
                </DataProvider>
              );
            },
            // renderFormItem: () => {

            //   return (
            //     <Table
            //       rowSelection={{
            //         type: 'checkbox',
            //         ...rowSelection,
            //       }}
            //       columns={selectColumns}
            //       dataSource={selectData}
            //       pagination={false}
            //     />
            //   );
            // },
          },
          {
            hideInForm: !stuff.copyToAccommodationDates,
            title: (form) => {
              return `New ${
                stuff.flightType === 'outbound' ? 'Check In' : 'Check Out'
              } Date`;
            },
            dataIndex: 'checkinAndOutDates',
            transform: (value, namePath, allValues) => {
              return {
                [allValues.flightType === 'outbound'
                  ? 'checkinDate'
                  : 'checkoutDate']: moment(
                  value,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('DD/MM/YYYY HH:mm'),
              };
            },
            renderFormItem: (scheam, config, form) => {
              const formValues = form.getFieldsValue();
              const defaultValuesObj: any = { defaultValue: [] };

              if (formValues?.departureDateAndArrivalDate) {
                let index = 1;
                if (stuff.flightType === 'return') {
                  index = 0;
                }
                defaultValuesObj.defaultValue =
                  formValues?.departureDateAndArrivalDate[index];
              }
              return (
                <MyDatePicker
                  showTime
                  format={'DD/MM/YYYY HH:mm'}
                  {...defaultValuesObj}
                />
              );
            },
          },
          {
            hideInForm:
              !stuff.copyToAccommodationDates ||
              stuff.createOrUpdateAccommodation === 'update',
            title: 'New Room Type',
            dataIndex: 'roomType',
            fieldProps: { placeholder: '', disabled: loading },
          },
          {
            hideInForm:
              !stuff.copyToAccommodationDates ||
              stuff.createOrUpdateAccommodation === 'update',
            title: 'New Notes',
            dataIndex: 'notes',
            fieldProps: { placeholder: '', disabled: loading },
            valueType: 'textarea',
          },
        ];
      },
    },

    // {
    //   title: 'New Check In Date',
    //   dataIndex: 'checkInOutDates',
    //   dependencies: ['copyToAccommodationDates', 'departureDateAndArrivalDate'],
    //   hideInForm: !values?.copyToAccommodationDates,
    //   ignoreFormItem: !values?.copyToAccommodationDates,
    //   initialValue: values?.checkinDate
    //     ? [
    //         moment(values?.checkinDate, 'DD/MM/YYYY HH:mm'),
    //         moment(values?.checkoutDate, 'DD/MM/YYYY HH:mm'),
    //       ]
    //     : null,
    //   fieldProps: {
    //     placeholder: '',
    //     disabled: loading,
    //     onChange: (value: any) => {
    //       console.log('setting checkingout dates value', value);
    //       form?.setFieldsValue({
    //         checkInDate: value[0],
    //         checkOutDate: value[1],
    //       });
    //     },
    //   },
    //   renderFormItem: () => (
    //     <MyDatePicker.RangePicker
    //       showTime
    //       format={'DD/MM/YYYY HH:mm'}
    //       placeholder={['Start', 'End']}
    //     />
    //   ),
    //   width: 'md',
    //   colProps: {
    //     xs: 24,
    //     md: 12,
    //   },
    //   // transform: (value) => {
    //   //   if (value) {
    //   //     console.log('transforming to this!', value, {
    //   //       checkinDate: moment(value[0]).second(0).format('DD/MM/YYYY HH:mm'),
    //   //       checkoutDate: moment(value[1]).second(0).format('DD/MM/YYYY HH:mm'),
    //   //     });
    //   //     return {
    //   //       checkinDate: moment(value[0]).second(0).format('DD/MM/YYYY HH:mm'),
    //   //       checkoutDate: moment(value[1]).second(0).format('DD/MM/YYYY HH:mm'),
    //   //     };
    //   //   }
    //   //   return [];
    //   // },
    // },
    // {
    //   title: 'Replace Check In Dates',
    //   dataIndex: 'selectedRows',
    //   dependencies: ['copyToAccommodationDates', 'departureDateAndArrivalDate'],
    //   hideInForm: !values?.copyToAccommodationDates,
    //   ignoreFormItem: !values?.copyToAccommodationDates,
    //   renderFormItem: () => (
    //     <DataProvider
    //       query="accommodationBySlotId"
    //       variables={{ slotId: values?.slotId }}
    //       keysToWatch={['slotId']}
    //     >
    //       {({ data, loading }) => (
    //         <Table
    //           rowKey={(record) => record.id}
    //           loading={loading}
    //           rowSelection={{
    //             type: 'checkbox',
    //             ...rowSelection,
    //           }}
    //           columns={selectColumns}
    //           dataSource={data}
    //           pagination={false}
    //         />
    //       )}
    //     </DataProvider>
    //   ),
    //   // renderFormItem: () => {

    //   //   return (
    //   //     <Table
    //   //       rowSelection={{
    //   //         type: 'checkbox',
    //   //         ...rowSelection,
    //   //       }}
    //   //       columns={selectColumns}
    //   //       dataSource={selectData}
    //   //       pagination={false}
    //   //     />
    //   //   );
    //   // },
    // },
    {
      title: 'Include in Transfer List',
      dataIndex: 'includeInTransferList',
      fieldProps: {
        placeholder: '',
        disabled: loading,
      },
      valueType: 'switch',
      formItemProps: (form) => ({
        initialValue:
          data?.isCreateForm || form?.getFieldsValue().includeInTransferList,
      }),
    },
  ];
};
export const columns: (params: {
  currentView?: 'grouped' | 'all';
  isPersonView?: boolean;
  options?: any;
  data?: any;
}) => ColumnsType<DataType> = ({
  currentView = 'grouped',
  options,
  isPersonView,
  data,
}) => {
  const items: ColumnsType<DataType> = [
    {
      hidden: !isPersonView,
      title: 'Event',
      dataIndex: ['slot', 'event', 'title'],
      sorter: (a: any, b: any) =>
        (a.slot?.event?.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.slot?.event?.title || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['slot', 'event', 'title'], 'Event'),
    },
    {
      hidden: currentView === 'grouped' && !isPersonView,
      title: 'Group',
      dataIndex: ['group', 'title'],
      sorter: (a: any, b: any) =>
        (a.groupId || STRING_TO_FORCE_TO_END).localeCompare(
          b.groupId || STRING_TO_FORCE_TO_END
        ),
      width: 150,
      fixed: 'left',
      ...getColumnSearchProps(['group', 'title'], 'Group'),
    },
    {
      title: '#',
      dataIndex: ['slot', 'title'],
      sorter: (a: any, b: any) => {
        let aSlotTitle = '999999999999999999';
        let bSlotTitle = '999999999999999999';

        if (a.slot?.title) {
          aSlotTitle = a.slot.title;
        }
        if (b.slot?.title) {
          bSlotTitle = b.slot.title;
        }
        return parseFloat(aSlotTitle) - parseFloat(bSlotTitle);
      },
      defaultSortOrder: 'ascend',
      width: 75,
      fixed: 'left',

      ...getColumnSearchProps(['slot', 'title'], 'Slot #'),
    },
    {
      title: 'Title',
      dataIndex: ['slot', 'person', 'title'],
      ...getColumnSearchProps(['slot', 'person', 'title'], 'Title'),
      sorter: (a: any, b: any) =>
        (a.slot.person.title || STRING_TO_FORCE_TO_END).localeCompare(
          b.slot.person.title || STRING_TO_FORCE_TO_END
        ),
      width: 100,
      hidden: isPersonView,
    },
    {
      title: 'Full Name',
      ...getColumnSearchProps(null, 'Full Name'),
      onFilter: (value: any, record: any) => {
        return `${record.slot?.person?.firstName} ${record.slot?.person?.lastName}`
          .toLowerCase()
          .replace(/ /g, '')
          .includes((value as string).toLowerCase().replace(/ /g, ''));
      },
      sorter: (a: any, b: any) =>
        (a.slot?.person
          ? `${a.slot?.person?.firstName} ${a.slot?.person?.lastName}`
          : STRING_TO_FORCE_TO_END
        ).localeCompare(
          a.slot?.person
            ? `${b.slot?.person?.firstName} ${b.slot?.person?.lastName}`
            : STRING_TO_FORCE_TO_END
        ),
      width: 200,
      hidden: isPersonView,
      render(value, record: any, index) {
        return (
          <Link to={`/people/${record?.slot?.person?.id}`}>
            {record?.slot?.person?.firstName} {record?.slot?.person?.lastName}
          </Link>
        );
      },
    },
    {
      title: 'Flight Number',
      dataIndex: 'flightNumber',
      sorter: (a: any, b: any) =>
        (a.flightNumber || STRING_TO_FORCE_TO_END).localeCompare(
          b.flightNumber || STRING_TO_FORCE_TO_END
        ),
      width: 140,
      ...getColumnSearchProps(['flightNumber'], 'Flight Number'),
      render: (item) => {
        if (item) {
          return (
            <Tag
              bordered={false}
              color="processing"
              style={{
                textTransform: 'uppercase',
                fontSize: 14,
                letterSpacing: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(`https://uk.flightaware.com/live/flight/${item}`);
              }}
            >
              {item}
            </Tag>
          );
        }

        return null;
      },
    },

    {
      title: 'Leaving From',
      dataIndex: 'leavingFrom',
      sorter: (a: any, b: any) =>
        (a.leavingFrom || STRING_TO_FORCE_TO_END).localeCompare(
          b.leavingFrom || STRING_TO_FORCE_TO_END
        ),
      width: 140,
      ...getColumnSearchProps(['leavingFrom'], 'Leaving From'),
      render: (item) => {
        if (item) {
          return (
            <Tag
              bordered={false}
              color="default"
              style={{
                textTransform: 'uppercase',
                fontSize: 14,
                cursor: 'pointer',

                // letterSpacing: 1,
              }}
              onClick={() => {
                window.open(`http://maps.google.com/?q=${item}+airport`);
              }}
            >
              {item}
            </Tag>
          );
        }

        return null;
      },
    },
    {
      title: 'Departure Date',
      dataIndex: 'departureDate',
      sorter: (a: any, b: any) =>
        moment(a.departureDate, 'DD/MM/YYYY HH:mm').valueOf() -
        moment(b.departureDate, 'DD/MM/YYYY HH:mm').valueOf(),
      width: 140,
      ...getColumnSearchProps(['departureDate'], 'Departure Date'),
    },
    // {
    //   title: 'Departure Time',
    //   dataIndex: 'departureDate',
    //   sorter: true,
    //   width: 200,
    //   render(value, record, index) {
    //     return value ? moment(value).format('HH:mm') : null;
    //   },
    //   ...getColumnSearchProps(['departureDate'], 'Departure Time'),
    // },
    {
      title: 'Arriving At',
      dataIndex: 'arrivingAt',
      sorter: (a: any, b: any) =>
        (a.arrivingAt || STRING_TO_FORCE_TO_END).localeCompare(
          b.arrivingAt || STRING_TO_FORCE_TO_END
        ),
      width: 140,
      ...getColumnSearchProps(['arrivingAt'], 'Arriving At'),
      render: (item) => {
        if (item) {
          return (
            <Tag
              bordered={false}
              color="default"
              style={{
                textTransform: 'uppercase',
                fontSize: 14,
                cursor: 'pointer',

                // letterSpacing: 1,
              }}
              onClick={() => {
                window.open(`http://maps.google.com/?q=${item}+airport`);
              }}
            >
              {item}
            </Tag>
          );
        }

        return null;
      },
    },
    {
      title: 'Arrival Date',
      dataIndex: 'arrivalDate',
      sorter: (a: any, b: any) =>
        moment(a.arrivalDate, 'DD/MM/YYYY HH:mm').valueOf() -
        moment(b.arrivalDate, 'DD/MM/YYYY HH:mm').valueOf(),
      width: 140,
      render(value, record: any, index) {
        const daysDifference = moment(record.arrivalDate, 'DD/MM/YYYY HH:mm')
          .startOf('day')
          .diff(
            moment(record.departureDate, 'DD/MM/YYYY HH:mm').startOf('day'),
            'days'
          );

        return value ? (
          <Space>
            <span>{value}</span>

            {!moment(value, 'DD/MM/YYYY HH:mm').isSame(
              moment(record.departureDate, 'DD/MM/YYYY HH:mm'),
              'day'
            ) && <span style={{ color: '#999' }}>+{daysDifference}</span>}
          </Space>
        ) : null;
      },
      ...getColumnSearchProps(['arrivalDate'], 'Arrival Date'),
    },
    // {
    //   title: 'Arrival Time',
    //   dataIndex: 'arrivalDate',
    //   sorter: true,
    //   width: 200,
    //   render(value, record, index) {
    //     return value ? moment(value).format('HH:mm') : null;
    //   },
    //   ...getColumnSearchProps(['arrivalDate'], 'Arrival Time'),
    // },
    // {
    //   title: 'Checkin Date',
    //   dataIndex: 'checkinDate',
    //   sorter: (a: any, b: any) =>
    //     moment(a.checkinDate, 'DD/MM/YYYY HH:mm').valueOf() -
    //     moment(b.checkinDate, 'DD/MM/YYYY HH:mm').valueOf(),
    //   width: 140,
    //   ...getColumnSearchProps(['checkinDate'], 'Checkin Date'),
    // },
    // {
    //   title: 'Checkout Date',
    //   dataIndex: 'checkoutDate',
    //   sorter: (a: any, b: any) =>
    //     moment(a.checkoutDate, 'DD/MM/YYYY HH:mm').valueOf() -
    //     moment(b.checkoutDate, 'DD/MM/YYYY HH:mm').valueOf(),
    //   width: 140,
    //   ...getColumnSearchProps(['checkoutDate'], 'Checkout Date'),
    // },

    {
      title: 'Transfer List',
      dataIndex: 'includeInTransferList',
      sorter: (a: any, b: any) =>
        (a.includeInTransferList || STRING_TO_FORCE_TO_END).localeCompare(
          b.includeInTransferList || STRING_TO_FORCE_TO_END
        ),
      width: 140,
      // ...getColumnSearchProps(['arrivingAt'], 'Arriving At'),
      render: (item) => {
        if (item) {
          return <CheckCircleOutlined style={{ color: '#777777' }} />;
        }

        return null;
      },
    },
  ];

  if (showEditActions()) {
    items.push({
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (item) => {
        return (
          <Space style={{ float: 'right' }}>
            <FormButton
              fields={({ values, loading, data, options, form }) =>
                fields({
                  loading,
                  data,
                  options,
                })
              }
              data={{ ...data, eventId: item.eventId }}
              item={item}
              successMessage="Flight updated"
              action={updateFlight}
              options={options}
            />
            <DeleteButton
              item={item}
              successMessage="Flight removed"
              action={removeFlight}
            />
          </Space>
        );
      },
    });
  }

  return items;
};

export const specification = [
  { key: 'slotNumber', label: 'Slot #', width: 20 },
  { key: 'title', label: 'Title', width: 40 },
  { key: 'firstName', label: 'First Name', width: 40 },
  { key: 'lastName', label: 'Last Name', width: 40 },
  { key: 'flightType', label: 'Flight Type', width: 40 },
  { key: 'flightNumber', label: 'Flight Number', width: 40 },
  { key: 'leavingFrom', label: 'Leaving From', width: 40 },
  { key: 'departureDate', label: 'Departure Date', width: 40 },
  { key: 'departureTime', label: 'Departure Time', width: 40 },
  { key: 'arrivingAt', label: 'Arriving At', width: 40 },
  { key: 'arrivalDate', label: 'Arrival Date', width: 40 },
  { key: 'arrivalTime', label: 'Arrival Time', width: 40 },
];

export const transformKeys = {
  'slot.title': 'slotNumber',
  'slot.person.title': 'title',
  'slot.person.firstName': 'firstName',
  'slot.person.lastName': 'lastName',
};

export const transferSpecification = [
  { key: 'arrivalDeparture', label: 'ARRIVAL/DEPARTURE', width: 20 },
  { key: 'checked', label: 'Checked', width: 40 },
  { key: 'pickUpTime', label: 'PICK UP TIME', width: 40 },
  { key: 'clientFirstName', label: 'CLIENT FIRST NAME', width: 40 },
  { key: 'clientLastName', label: 'CLIENT LAST NAME', width: 40 },
  { key: 'country', label: 'COUNTRY', width: 40 },
  { key: 'publication', label: 'PUBLICATION', width: 40 },
  { key: 'flightType', label: 'FLIGHT TYPE', width: 40 },
  { key: 'flightNumber', label: 'FLIGHT NO', width: 40 },
  { key: 'leavingFrom', label: 'LEAVING FROM', width: 40 },
  { key: 'arrivingAt', label: 'ARRIVING AT', width: 40 },
  { key: 'arrivalDate', label: 'FLIGHT ARRIVAL DATE', width: 40 },
  { key: 'arrivalTime', label: 'FLIGHT ARRIVAL TIME', width: 40 },
  { key: 'departureDate', label: 'FLIGHT DEPARTURE DATE', width: 40 },
  { key: 'departureTime', label: 'FLIGHT DEPARTURE TIME', width: 40 },
  { key: 'pickUpLocation', label: 'PICK UP LOCATION', width: 40 },
  { key: 'dropOffLocation', label: 'DROP OFF LOCATION', width: 40 },
  { key: 'carReg', label: 'CAR REG', width: 40 },
  { key: 'driver', label: 'DRIVER', width: 40 },
  { key: 'notes', label: 'NOTES', width: 40 },
];

export const transferTransformKeys = {
  'slot.person.publications': 'publication',
  'slot.person.country': 'country',
  'slot.person.firstName': 'clientFirstName',
  'slot.person.lastName': 'clientLastName',
};

import { Space, Button, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { BetaSchemaForm, FormInstance } from '@ant-design/pro-components';
import { defaultFormProps } from '../../utils/defaultFormProps';
import { fields } from '../../data/groups';
import { addGroup } from '../../methods/groups';
import GroupCard from '../../components/GroupCard';
import { Group } from '../../globals';
import showEditActions from '../../utils/showEditActions';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import _ from 'lodash';
import { updateCurrentEventSlots } from '../../reducers/eventsSlice';

type Props = {
  match: any;
  groups: Group[] | undefined;
};

const GroupsScene: React.FC<Props> = (props) => {
  const [formLoading, setFormLoading] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState({
    media: false,
    other: false,
  });

  const formRef = useRef<FormInstance>();

  let mediaGroups: Group[] = [];
  let otherGroups: Group[] = [];

  if (props.groups && props.groups.length > 0) {
    mediaGroups = props.groups.filter(
      ({ categoryId }) => categoryId === '0728a64c-2495-459d-b29d-a2addb91cccd'
    );
    mediaGroups.sort(
      (a, b) =>
        new Date(a.createdAt as string).getTime() -
        new Date(b.createdAt as string).getTime()
    );

    otherGroups = props.groups.filter(
      ({ categoryId }) => categoryId === '2d49e07d-4685-42e5-9661-685e85f43b1d'
    );
    otherGroups.sort(
      (a, b) =>
        new Date(a.createdAt as string).getTime() -
        new Date(b.createdAt as string).getTime()
    );
  }

  return (
    <ReduxDataProvider
      query="slotsByEventId"
      variables={{ eventId: props.match.params.eventId }}
      stateSelector="currentEvent.slots"
      updateAction={updateCurrentEventSlots as any}
      include={['groups']}
    >
      {({ data, loading }) => {
        return (
          <>
            <Space align="center">
              <h2 style={{ margin: 0, padding: 0 }}>Media</h2>
              {showEditActions() && (
                <>
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setFormModalVisible({ ...formModalVisible, media: true });
                    }}
                  />
                  <BetaSchemaForm
                    {...defaultFormProps(formRef, formLoading)}
                    formRef={formRef}
                    visible={formModalVisible.media}
                    onVisibleChange={(isVisible: boolean) => {
                      setFormModalVisible({
                        ...formModalVisible,
                        media: isVisible,
                      });
                    }}
                    onFinish={async (values: Group) => {
                      setFormLoading(true);
                      await addGroup({
                        ...values,
                        categoryId: '0728a64c-2495-459d-b29d-a2addb91cccd',
                        eventId: props.match.params.eventId,
                      });

                      setTimeout(() => {
                        setFormModalVisible({
                          ...formModalVisible,
                          media: false,
                        });

                        setFormLoading(false);
                        message.success('Group Created');
                      }, 500);
                    }}
                    columns={fields({loading:formLoading}) as any}
                  />
                </>
              )}
            </Space>
            <br /> <br />
            <div className="site-card-wrapper">
              <Row gutter={[16, 16]}>
                {mediaGroups.map((group, i) => {
                  const slotItems = data?.items?.filter(
                    (o: any) => group.id === o.groupId && !o.deletedAt
                  );
                  const peopleCount = Object.keys(
                    _.groupBy(slotItems, (o) => o.personId)
                  ).filter(
                    (o: any) => !['null', 'undefined'].includes(o)
                  )?.length;

                  return (
                    <Col span={6} style={{ minWidth: 220 }} key={group.id}>
                      <GroupCard
                        count={i + 1}
                        group={group}
                        slotCount={slotItems?.length}
                        peopleCount={peopleCount}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
            <br />
            {(showEditActions() || otherGroups.length > 0) && (
              <>
                <Space align="center">
                  <h2 style={{ margin: 0, padding: 0 }}>Other</h2>
                  {showEditActions() && (
                    <>
                      <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setFormModalVisible({
                            ...formModalVisible,
                            other: true,
                          });
                        }}
                      />
                      <BetaSchemaForm
                        {...defaultFormProps(formRef, formLoading)}
                        formRef={formRef}
                        visible={formModalVisible.other}
                        onVisibleChange={(isVisible: boolean) => {
                          setFormModalVisible({
                            ...formModalVisible,
                            other: isVisible,
                          });
                        }}
                        onFinish={async (values: Group) => {
                          setFormLoading(true);
                          await addGroup({
                            ...values,
                            categoryId: '2d49e07d-4685-42e5-9661-685e85f43b1d',
                            eventId: props.match.params.eventId,
                          });

                          setTimeout(() => {
                            setFormModalVisible({
                              ...formModalVisible,
                              other: false,
                            });

                            setFormLoading(false);
                            message.success('Group Created');
                          }, 500);
                        }}
                        columns={fields({loading:formLoading}) as any}
                      />
                    </>
                  )}
                </Space>
                <br /> <br />
                <div className="site-card-wrapper">
                  <Row gutter={16}>
                    {otherGroups.map((group, i) => {
                      const slotItems = data?.items?.filter(
                        (o: any) => group.id === o.groupId && !o.deletedAt
                      );
                      const peopleCount = Object.keys(
                        _.groupBy(slotItems, (o) => o.personId)
                      ).filter(
                        (o: any) => !['null', 'undefined'].includes(o)
                      )?.length;

                      return (
                        <Col span={6} style={{ minWidth: 220 }} key={group.id}>
                          <GroupCard
                            group={group}
                            slotCount={slotItems?.length}
                            peopleCount={peopleCount}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </>
            )}
          </>
        );
      }}
    </ReduxDataProvider>
  );
};

export default GroupsScene;

import { Select } from 'antd';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updatePeople, upsertPerson } from '../../reducers/eventsSlice';

const { Option } = Select;

type Props = {
  // onChange: (value:string) => void
  onSelect?: (value: any) => void;
  onChange?: <T = any>(item?: T) => void;
  value?: any;
};

const PersonSelect: React.FC<Props> = (props) => {
  const [data, setData] = useState<any[]>([]);
  const [value, setValue] = useState<string>(props.value);

  // const handleSearch = async (newValue: string) => {
  //   if (newValue) {
  //     const items: any = await API.graphql({
  //       query: searchPeople,
  //       variables: {
  //         filter: {
  //           or: [
  //             { firstName: { matchPhrasePrefix: newValue } },
  //             { lastName: { matchPhrasePrefix: newValue } },
  //           ],
  //         },
  //       },
  //       authMode: 'AMAZON_COGNITO_USER_POOLS',
  //     });

  //     setData(
  //       items.data['searchPeople'].items.filter((o: any) => !o.deletedAt)
  //     );
  //   } else {
  //     setData([]);
  //   }
  // };

  const handleChange = (newValue: string, itemsToCheck: any) => {
    setValue(newValue);
    if (newValue) {
      const targetItem = itemsToCheck?.items?.find(
        ({ id }: any) => id === newValue
      );

      if (targetItem) {
        props.onChange && props.onChange(targetItem.id);
      }
    } else {
      props.onChange && props.onChange('removed');
    }
  };

  const onSearch = (value: string) => {};

  const options = data.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.firstName} {d.lastName}
    </Option>
  ));
  // useEffect(() => {
  //   const searchInitialValue = async () => {
  //     let extra:any = {};
  //     if (props.value) {
  //       extra.variables = {
  //         filter: {
  //           id: { eq: props.value },
  //         },
  //       };
  //     }
  //     const items: any = await API.graphql({
  //       query: searchPeople,
  //       ...extra,
  //       authMode: 'AMAZON_COGNITO_USER_POOLS',
  //     });

  //     setData(
  //       items.data['searchPeople'].items.filter((o: any) => !o.deletedAt)
  //     );
  //     handleChange(props.value, items.data['searchPeople'].items.filter((o: any) => !o.deletedAt));
  //   };
  //   searchInitialValue();
  // }, []);
  return (
    <ReduxDataProvider
      query="listPeople"
      stateSelector="people"
      updateAction={updatePeople as any}
      upsertAction={upsertPerson}
    >
      {({ data, loading }) => (
        <Select
          allowClear
          showSearch
          value={data?.items?.length > 0 ? value : ''}
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={(value) => handleChange(value, data)}
          onSearch={onSearch}
          filterOption={(input, option) => {
            return (option!.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          notFoundContent={null}
          size="large"
          style={{ width: 300 }}
          options={data?.items?.map(({ id, firstName, lastName }: any) => ({
            label: `${firstName} ${lastName}`,
            value: id,
          }))}
        />
      )}
    </ReduxDataProvider>
  );
};

export default PersonSelect;

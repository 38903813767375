import { PageHeader } from '@ant-design/pro-layout';
import {
  Statistic,
  Tabs,
  Spin,
  Button,
  Collapse,
  Table,
  Space,
  Upload,
  message,
  Result,
} from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './styles.less';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import { Route, Switch, useHistory } from 'react-router-dom';
import { getEvent } from '../../methods/events';
import GroupsScene from '../GroupsScene';
import GroupScene from '../GroupScene';
import { API } from 'aws-amplify';
import type { ColumnsType } from 'antd/es/table';
import {
  onUpdateAnyEvent,
  onUpdateAnyGroup,
  onUpdateAnySlot,
  onUpdateEventWithId,
} from '../../graphql/subscriptions';

import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  updateCurrentEvent,
  updateCurrentEventAccommodation,
  updateCurrentEventFlights,
  updateCurrentEventSlots,
  updateCurrentGroupSlots,
  upsertGroupItem,
  upsertAccommodationItem,
  upsertFlightItem,
  upsertSlotItem,
} from '../../reducers/eventsSlice';
import { getGroupsByEventId } from '../../methods/groups';
import { Group } from '../../API';
import DataTable from '../../components/DataTable';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import {
  columns as flightColumns,
  fields as flightFields,
  specification as flightSpecification,
  transferSpecification,
  transferTransformKeys,
  transformKeys as flightTransformKeys,
} from '../../data/flights';
import moment from 'moment';
import FormButton from '../../components/FormButton';
import {
  addAccommodation,
  batchAddAccommodations,
} from '../../methods/accommodation';

import {
  columns as slotColumns,
  fields as slotFields,
  specification as slotSpecification,
  transformKeys as slotTransformKeys,
} from '../../data/slots';

import {
  columns as accommodationColumns,
  fields as accommodationFields,
  specification as accommodationSpecification,
  transformKeys as accommodationTransformKeys,
} from '../../data/accommodation';

import { addSlot } from '../../methods/slots';
import { addFlight, batchAddFlights } from '../../methods/flights';
import PersonScene from '../PersonScene';
import { exportAccommodationData, exportData } from '../../utils/exportData';
import {
  accommodationByGroupId,
  flightsByGroupId,
  slotsByGroupId,
} from '../../graphql/queries';
import _, { now } from 'lodash';
import Loading from '../../components/Loading';
import { getAll } from '../../utils/getAll';
import { createSlot } from '../../graphql/mutations';
import orderGroups from '../../utils/orderGroups';
import showEditActions from '../../utils/showEditActions';

import ViewPicker from '../../components/ViewPicker';
import { ViewType } from '../../globals';
interface DataType {
  key: string;
  date: string;
  people: number;
}
const { Panel } = Collapse;

const { TabPane } = Tabs;

// const parseDate = (dateAndTimeString: string) => {
//   var [dateString, timeString] = dateAndTimeString.split(' ');

//   const dateParts = dateString.split('/');
//   const timeParts = timeString.split(':');

//   return new Date(
//     parseInt(dateParts[2], 10),
//     parseInt(dateParts[1], 10) - 1,
//     parseInt(dateParts[0], 10),
//     parseInt(timeParts[0], 10),
//     parseInt(timeParts[1], 10)
//   ).toISOString();
// };

const MainContent: React.FC<{
  children?: React.ReactNode;
  extra?: React.ReactNode;
}> = ({ children, extra }) => (
  <div className="content">
    <div className="main">{children}</div>
    <div className="extra">{extra}</div>
  </div>
);

type Props = {
  match: any;
};

const EventScene: React.FC<Props> = React.memo((props) => {
  const user = useAppSelector((state) => state.user);

  const events = useAppSelector((state) => state.events);
  const { list, currentEvent, tablesConfigs } = events;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(
    currentEvent?.id !== props.match.params.eventId
  );
  const [currentTab, setCurrentTab] = useState<any>();

  const [currentView, setCurrentView] = useState<any>(
    localStorage.getItem('currentView') || 'grouped'
  );

  useEffect(() => {
    const getItem = async () => {
      const targetEvent = list.find(
        ({ id }) => id === props.match.params.eventId
      );
      if (targetEvent) {
        dispatch(updateCurrentEvent(targetEvent));
        setLoading(false);
      }
      const targetItem = await getEvent(props.match.params.eventId);
      console.log('getGroupsByEventId 3', props.match.params.eventId);

      const groups = await getGroupsByEventId(props.match.params.eventId);

      dispatch(
        updateCurrentEvent({ ...targetItem, groups: { items: groups } })
      );

      setLoading(false);
    };
    getItem();

    let eventSubscription: any;
    const subscribeToEvents = async () => {
      const dataQuery: any = API.graphql({
        query: onUpdateEventWithId,
        variables: { id: props.match.params.eventId },
      });
      eventSubscription = dataQuery.subscribe({
        next: async ({ provider, value }: { provider: any; value: any }) => {
          dispatch(updateCurrentEvent(value.data.onUpdateEventWithId));
        },
        error: (error: any) => console.warn(error),
      });
    };
    subscribeToEvents();

    let groupSubscription: any;
    const subscribeToGroups = async () => {
      const dataQuery: any = API.graphql({ query: onUpdateAnyGroup });
      groupSubscription = dataQuery.subscribe({
        next: async ({ provider, value }: { provider: any; value: any }) => {
          dispatch(upsertGroupItem(value.data.onUpdateAnyGroup));
        },
        error: (error: any) => console.warn(error),
      });
    };
    subscribeToGroups();

    // let slotSubscription: any;
    // const subscribeToSlots = async () => {
    //   const dataQuery: any = API.graphql({ query: onUpdateAnySlot });
    //   slotSubscription = dataQuery.subscribe({
    //     next: async ({ provider, value }: { provider: any; value: any }) => {
    //       dispatch(updateSlotItem(value.data.onUpdateAnySlot));
    //     },
    //     error: (error: any) => console.warn(error),
    //   });
    // };
    // subscribeToSlots();

    return () => {
      groupSubscription?.unsubscribe();
      eventSubscription?.unsubscribe();
      // slotSubscription?.unsubscribe();
    };
  }, [dispatch, props.match.params.eventId]);

  const onViewTypeChanged = (newView: ViewType) => {
    localStorage.setItem('currentView', newView);
    setCurrentView(newView);
  };

  const extraContent = (
    <div
      style={{
        display: 'flex',
        width: 'max-content',
        justifyContent: 'flex-end',
      }}
    >
      <Statistic
        title="Groups"
        value={currentEvent?.groups?.total}
        style={{
          marginRight: 32,
        }}
      />
      <Statistic
        title="People"
        value={38}
        style={{
          marginRight: 32,
        }}
      />
    </div>
  );

  if (loading) {
    return (
      <div className="spinContainer">
        <Spin />
      </div>
    );
  }

  if (!user || !user?.profile?.eventIds.includes(props.match.params.eventId)) {
    return (
      <Result
        status="error"
        title="Unauthorised"
        subTitle="You do not have permission to access this Event"
      />
    );
  }

  return (
    <PageHeader
      className="site-page-header-responsive"
      onBack={() => history.push('/events')}
      title={currentEvent?.title}
      subTitle={
        currentEvent?.startDate
          ? moment(currentEvent?.startDate, 'DD/MM/YYYY HH:mm').format(
              'dddd Do MMM YY'
            )
          : null
      }
      // extra={
      // currentTab === 'flights' ? (
      //   <Space>
      //     <Button
      //       icon={<DownloadOutlined />}
      //       // type="primary"
      //       // size="large"
      //       type="dashed"
      //       shape="round"
      //     >
      //       Export Transfer List
      //     </Button>
      //     <Button
      //       icon={<DownloadOutlined />}
      //       // type="primary"
      //       // size="large"
      //       type="dashed"
      //       shape="round"
      //     >
      //       Export Flights
      //     </Button>
      //   </Space>
      // ) : currentTab === 'people' ? (
      //   <Space>
      //     <Button
      //       icon={<DownloadOutlined />}
      //       // type="primary"
      //       // size="large"
      //       type="dashed"
      //       shape="round"
      //     >
      //       Export People List
      //     </Button>
      //   </Space>
      // ) : currentTab === 'accommodation' ? (
      //   <Space>
      //     <Button
      //       icon={<DownloadOutlined />}
      //       // type="primary"
      //       // size="large"
      //       type="dashed"
      //       shape="round"
      //     >
      //       Export Accommodation List
      //     </Button>
      //   </Space>
      // ) : null
      // }
      // subTitle="Monday 6th - Tuesday 7th September ‘22"
      // extra={[
      //   <Button key="3">Operation</Button>,
      //   <Button key="2">Operation</Button>,
      //   <Button key="1" type="primary">
      //     Primary
      //   </Button>,
      // ]}
      // breadcrumbRender={() => (
      //   <Breadcrumb separator="">
      //     <Breadcrumb.Item>Events</Breadcrumb.Item>
      //     <Breadcrumb.Separator />
      //     <Breadcrumb.Item href="">{currentEvent?.title}</Breadcrumb.Item>
      //   </Breadcrumb>
      // )}
      footer={
        <Switch>
          <Route
            path="/events/:eventId/groups/:groupId/people/:personId"
            exact
            component={PersonScene}
          />
          <Route
            path="/events/:eventId/people/:personId"
            exact
            component={PersonScene}
          />
          <Route
            path="/events/:eventId/:tab?/:id?"
            render={({ match, history }) => {
              if (!currentTab) {
                setCurrentTab(match.params.tab || 'people');
              }

              const groupsTabContent = (
                // Content for "Groups" Tab
                <>
                  {!match.params.id && (
                    <GroupsScene
                      match={match}
                      groups={currentEvent?.groups?.items}
                    />
                  )}
                  {match.params.id && <GroupScene match={match} />}
                </>
              );
              const peopleTabContent = (
                // Content for "Flights & Transfers" Tab
                <ReduxDataProvider
                  query="slotsByEventId"
                  variables={{ eventId: match.params.eventId }}
                  updateAction={updateCurrentEventSlots as any}
                  upsertAction={upsertSlotItem}
                  stateSelector="currentEvent.slots"
                  include={['groups']}
                >
                  {({ data, loading }) => {
                    const slotItems = data?.items?.filter(
                      (o: any) =>
                        !o.deletedAt &&
                        !currentEvent?.groups?.items.find(
                          ({ id }) => id === o.groupId
                        )?.deletedAt
                    );
                    const peopleCount = Object.keys(
                      _.groupBy(slotItems, (o) => o?.personId)
                    ).filter(
                      (o: any) => !['null', 'undefined'].includes(o)
                    )?.length;

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 20,
                            marginTop: 4,
                          }}
                        >
                          <Space wrap style={{ alignSelf: 'flex-start' }}>
                            <ViewPicker
                              value={currentView}
                              onChange={onViewTypeChanged}
                            />{' '}
                            <h3 style={{ margin: 0, color: '#777' }}>
                              {currentEvent?.groups?.items?.length?.toString()}{' '}
                              group
                              {currentEvent?.groups?.items?.length === 1
                                ? ''
                                : 's'}
                              , {slotItems?.length?.toString()} slot
                              {slotItems?.length === 1 ? '' : 's'},{' '}
                              {
                                _.groupBy(slotItems, (o) => o.slot?.personId)
                                  ?.length
                              }{' '}
                              {peopleCount?.toString()}{' '}
                              {peopleCount === 1 ? ' person' : 'people'}
                            </h3>
                          </Space>
                          <Space
                            wrap
                            size={'middle'}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <Loading>
                              {({ loading, setLoading }) => (
                                <Button
                                  icon={<DownloadOutlined />}
                                  loading={loading}
                                  // type="primary"
                                  // size="large"
                                  type="dashed"
                                  shape="round"
                                  onClick={async () => {
                                    if (currentEvent?.groups?.items) {
                                      setLoading(true);
                                      const pages = orderGroups(
                                        currentEvent?.groups?.items
                                      ).map(({ id, title }) => ({
                                        queryProps: {
                                          query: slotsByGroupId,
                                          variables: {
                                            groupId: id,
                                          },
                                        },
                                        name: title,
                                        specification: slotSpecification,
                                        transformKeys: slotTransformKeys,
                                      }));
                                      if (pages) {
                                        await exportData({
                                          pages,
                                          fileName: `${currentEvent?.title}-slots.xlsx`,
                                        });
                                      }

                                      setLoading(false);
                                    }
                                  }}
                                >
                                  Export People List
                                </Button>
                              )}
                            </Loading>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                              }}
                            >
                              <FormButton
                                data={{
                                  isCreateForm: true,
                                  eventId: match.params.eventId,
                                }}
                                fields={slotFields}
                                options={{
                                  groups: currentEvent?.groups?.items,
                                }}
                                successMessage="Slot created"
                                action={(values) =>
                                  addSlot({
                                    ...values,
                                    eventId: match.params.eventId,
                                  })
                                }
                                renderButton={(setFormModalVisible) => (
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFormModalVisible(true);
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                  >
                                    Add Slot
                                  </Button>
                                )}
                              />
                            </div>
                          </Space>
                        </div>

                        {currentView === 'all' ? (
                          <DataTable
                            columns={slotColumns({
                              eventId: match.params.eventId,
                              currentView,
                              options: { groups: currentEvent?.groups?.items },
                            })}
                            data={data?.items}
                            loading={loading}
                          />
                        ) : (
                          <Collapse className="groupedCollapse" ghost>
                            {_.orderBy(
                              currentEvent?.groups?.items,
                              (item) => item.title
                            ).map(({ id, title }) => {
                              const groupedItems = data?.items.filter(
                                ({ groupId }: any) => groupId === id
                              );
                              let extraProps = {};
                              if (showEditActions()) {
                                extraProps = {
                                  extra: (
                                    <div
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return false;
                                      }}
                                    >
                                      <FormButton
                                        data={{
                                          isCreateForm: true,
                                          eventId: match.params.eventId,
                                        }}
                                        fields={({
                                          values,
                                          loading,
                                          data,
                                          options,
                                        }) =>
                                          slotFields({
                                            initialValues: {
                                              ...values,
                                              groupId: id,
                                            },
                                            loading,
                                            data,
                                            options,
                                          })
                                        }
                                        options={{
                                          groups: currentEvent?.groups?.items,
                                        }}
                                        successMessage="Slot created"
                                        action={(values) =>
                                          addSlot({
                                            ...values,
                                            eventId: match.params.eventId,
                                            groupId: id,
                                          })
                                        }
                                        renderButton={(setFormModalVisible) => (
                                          <Button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFormModalVisible(true);
                                            }}
                                            icon={<PlusOutlined />}
                                          >
                                            Add Slot
                                          </Button>
                                        )}
                                      />
                                    </div>
                                  ),
                                };
                              }

                              return (
                                <Panel
                                  header={
                                    <Space>
                                      {title}
                                      <span className="groupedCollapseCount">
                                        {groupedItems?.length || 0} Slots
                                      </span>
                                    </Space>
                                  }
                                  key={id}
                                  style={{ backgroundColor: 'white' }}
                                  {...extraProps}
                                >
                                  <DataTable
                                    columns={slotColumns({
                                      eventId: match.params.eventId,
                                      groupId: id,
                                      currentView,
                                      options: {
                                        groups: currentEvent?.groups?.items,
                                      },
                                    })}
                                    data={groupedItems}
                                    loading={loading}
                                  />
                                </Panel>
                              );
                            })}
                          </Collapse>
                        )}
                      </>
                    );
                  }}
                </ReduxDataProvider>
              );
              const flightsTransfersTabContent = (
                // Content for "People" Tab
                <ReduxDataProvider
                  query="flightsByEventId"
                  variables={{ eventId: match.params.eventId }}
                  updateAction={updateCurrentEventFlights as any}
                  upsertAction={upsertFlightItem}
                  stateSelector="currentEvent.flights"
                  include={['groups']}
                >
                  {({ data, loading }) => {
                    const flightItems = data?.items?.filter(
                      (o: any) =>
                        !o.deletedAt &&
                        !currentEvent?.groups?.items.find(
                          ({ id }) => id === o.groupId
                        )?.deletedAt
                    );
                    const peopleCount = Object.keys(
                      _.groupBy(flightItems, (o) => o.slot?.personId)
                    ).filter(
                      (o: any) => !['null', 'undefined'].includes(o)
                    )?.length;

                    const allOutboundFlights = data?.items?.filter(
                      (o: any) => o.flightType === 'outbound'
                    );

                    const allReturnFlights = data?.items?.filter(
                      (o: any) => o.flightType === 'return'
                    );

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 20,
                            marginTop: 4,
                          }}
                        >
                          <Space wrap style={{ alignSelf: 'flex-start' }}>
                            <ViewPicker
                              value={currentView}
                              onChange={onViewTypeChanged}
                            />{' '}
                            <h3 style={{ margin: 0, color: '#777' }}>
                              {flightItems?.length} flight
                              {flightItems?.length === 1 ? '' : 's'},{' '}
                              {
                                _.groupBy(flightItems, (o) => o.slot?.personId)
                                  ?.length
                              }{' '}
                              {peopleCount}{' '}
                              {peopleCount === 1 ? ' person' : 'people'}
                            </h3>
                          </Space>
                          <Space
                            wrap
                            size={'middle'}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {showEditActions() && (
                              <Upload
                                fileList={[]}
                                customRequest={(info) => {
                                  return;
                                }}
                                onChange={(info) => {
                                  try {
                                    var reader = new FileReader();
                                    reader.onload = async (event) => {
                                      const jsonData = Papa.parse(
                                        event?.target?.result as string,
                                        {
                                          skipEmptyLines: 'greedy',
                                          header: true,
                                          transformHeader: (label) => {
                                            return label
                                              .toLowerCase()
                                              .replace(/ /g, '_');
                                          },
                                        }
                                      );
                                      let itemsToAdd: any[] = [];

                                      const slots: any = await getAll({
                                        query: 'slotsByEventId',
                                        variables: {
                                          eventId: match.params.eventId,
                                        },
                                      });

                                      await Promise.all(
                                        jsonData.data.map(async (item: any) => {
                                          let targetSlot = null;
                                          let targetGroup = null;

                                          let newFlight: any = {
                                            eventId: match.params.eventId,
                                            flightType:
                                              item.flight_type.toLowerCase(),
                                            flightNumber: item.flight_number,
                                            leavingFrom: item.leaving_from,
                                            departureDate: item.departure_date,
                                            arrivingAt: item.arriving_at,
                                            arrivalDate: item.arrival_date,
                                          };

                                          if (item.group_name) {
                                            targetGroup =
                                              currentEvent?.groups?.items.find(
                                                ({ title }) => {
                                                  return (
                                                    title
                                                      ?.toLowerCase()
                                                      .replace(/ /g, '') ===
                                                    item.group_name
                                                      .toLowerCase()
                                                      .replace(/ /g, '')
                                                  );
                                                }
                                              );

                                            if (targetGroup) {
                                              newFlight.groupId =
                                                targetGroup.id;

                                              if (item.slot_id) {
                                                targetSlot = slots.find(
                                                  ({
                                                    title,
                                                    groupId,
                                                    eventId,
                                                  }: any) =>
                                                    title
                                                      ?.toLowerCase()
                                                      .replace(/ /g, '') ===
                                                      item.slot_id
                                                        .toLowerCase()
                                                        .replace(/ /g, '') &&
                                                    eventId ===
                                                      newFlight.eventId &&
                                                    groupId ===
                                                      newFlight.groupId
                                                );

                                                if (!targetSlot) {
                                                  const newSlotId = uuidv4();
                                                  const now =
                                                    new Date().toISOString();

                                                  await API.graphql({
                                                    query: createSlot,
                                                    variables: {
                                                      input: {
                                                        id: newSlotId,
                                                        title: item.slot_id,
                                                        eventId:
                                                          match.params.eventId,
                                                        groupId: targetGroup.id,
                                                        createdAt: now,
                                                        updatedAt: now,
                                                      },
                                                    },
                                                  });

                                                  targetSlot = {
                                                    id: newSlotId,
                                                  }; // create new slot
                                                }
                                                newFlight.slotId =
                                                  targetSlot.id;
                                              }

                                              itemsToAdd.push(newFlight);
                                            }
                                          }
                                        })
                                      );

                                      if (itemsToAdd.length > 0) {
                                        const chunks = _.chunk(itemsToAdd, 25);
                                        await Promise.all(
                                          chunks.map(batchAddFlights)
                                        );

                                        message.success(
                                          `${itemsToAdd.length} items added`
                                        );
                                      } else {
                                        message.error(`No items added`);
                                      }
                                    };
                                    reader.readAsText(
                                      info.file.originFileObj as Blob,
                                      'ISO-8859-4'
                                    );
                                  } catch (e: any) {
                                    message.error(e.message);
                                  }
                                }}
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  type="dashed"
                                  shape="round"
                                >
                                  Import From CSV
                                </Button>
                              </Upload>
                            )}
                            <Loading>
                              {({ loading, setLoading }) => (
                                <Button
                                  icon={<DownloadOutlined />}
                                  loading={loading}
                                  // type="primary"
                                  // size="large"
                                  type="dashed"
                                  shape="round"
                                  onClick={async () => {
                                    if (currentEvent?.groups?.items) {
                                      setLoading(true);

                                      const pages = orderGroups(
                                        currentEvent?.groups?.items
                                      ).map(({ id, title }) => ({
                                        queryProps: {
                                          query: flightsByGroupId,
                                          variables: {
                                            groupId: id,
                                          },
                                        },
                                        name: title,
                                        specification: transferSpecification,
                                        transformKeys: transferTransformKeys,
                                      }));
                                      if (pages) {
                                        await exportData({
                                          pages,
                                          fileName: `${currentEvent?.title}-transfer-schedule.xlsx`,
                                          isTransferList: true,
                                        });
                                      }
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  Export Transfer List
                                </Button>
                              )}
                            </Loading>
                            {/* <Loading>
                                  {({ loading, setLoading }) => (
                                    <Button
                                      icon={<DownloadOutlined />}
                                      loading={loading}
                                      // type="primary"
                                      // size="large"
                                      type="dashed"
                                      shape="round"
                                      onClick={async () => {
                                        if (currentEvent?.groups?.items) {
                                          setLoading(true);

                                          const pages = orderGroups(
                                            currentEvent?.groups?.items
                                          ).map(({ id, title }) => ({
                                            queryProps: {
                                              query: flightsByGroupId,
                                              variables: {
                                                groupId: id,
                                              },
                                            },
                                            name: title,
                                            specification:
                                              transferSpecification,
                                            transformKeys:
                                              transferTransformKeys,
                                          }));
                                          if (pages) {
                                            await exportData({
                                              pages,
                                              fileName: `${currentEvent?.title}-transfer-schedule-yvr.xlsx`,
                                              isTransferList: true,
                                              leavingFrom: 'YVR',
                                              arrivingAt: 'YVR'
                                            });
                                          }
                                          setLoading(false);
                                        }
                                      }}
                                    >
                                      Export Transfer List (YVR)
                                    </Button>
                                  )}
                                </Loading> */}
                            <Loading>
                              {({ loading, setLoading }) => (
                                <Button
                                  icon={<DownloadOutlined />}
                                  loading={loading}
                                  // type="primary"
                                  // size="large"
                                  type="dashed"
                                  shape="round"
                                  onClick={async () => {
                                    if (currentEvent?.groups?.items) {
                                      setLoading(true);
                                      const pages = orderGroups(
                                        currentEvent?.groups?.items
                                      ).map(({ id, title }) => ({
                                        queryProps: {
                                          query: flightsByGroupId,
                                          variables: {
                                            groupId: id,
                                          },
                                        },
                                        name: title,
                                        specification: flightSpecification,
                                        transformKeys: flightTransformKeys,
                                      }));
                                      if (pages) {
                                        await exportData({
                                          pages,
                                          fileName: `${currentEvent?.title}-flights.xlsx`,
                                        });
                                      }
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  Export Flights
                                </Button>
                              )}
                            </Loading>

                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                              }}
                            >
                              <FormButton
                                fields={flightFields}
                                successMessage="Flight created"
                                action={(values) =>
                                  addFlight({
                                    ...values,
                                    eventId: match.params.eventId,
                                  })
                                }
                                options={{
                                  groups: currentEvent?.groups?.items,
                                }}
                                data={{
                                  isCreateForm: true,
                                  eventId: match.params.eventId,
                                }}
                                renderButton={(setFormModalVisible) => (
                                  <Button
                                    onClick={() => {
                                      setFormModalVisible(true);
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                  >
                                    Add Flight
                                  </Button>
                                )}
                              />
                            </div>
                          </Space>
                        </div>

                        {currentView === 'all' ? (
                          <>
                            <DataTable
                              header={(total) => (
                                <div className="tableHeader">
                                  Outbound ({total})
                                </div>
                              )}
                              columns={flightColumns({
                                currentView,
                                options: {
                                  groups: currentEvent?.groups?.items,
                                },
                              })}
                              data={allOutboundFlights}
                              loading={loading}
                            />
                            <DataTable
                              header={(total) => (
                                <div className="tableHeader">
                                  Return ({total})
                                </div>
                              )}
                              columns={flightColumns({
                                currentView,
                                options: {
                                  groups: currentEvent?.groups?.items,
                                },
                              })}
                              data={allReturnFlights}
                              loading={loading}
                            />
                          </>
                        ) : (
                          <Collapse className="groupedCollapse" ghost>
                            {_.orderBy(
                              currentEvent?.groups?.items,
                              (item) => item.title
                            ).map(({ id, title }) => {
                              const outboundFlights =
                                allOutboundFlights?.filter(
                                  (o: any) => o.groupId === id
                                );

                              const returnFlights = allReturnFlights?.filter(
                                (o: any) => o.groupId === id
                              );

                              let extraProps = {};
                              if (showEditActions()) {
                                extraProps = {
                                  extra: (
                                    <>
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          return false;
                                        }}
                                      >
                                        <FormButton
                                          fields={({
                                            loading,
                                            data,
                                            options,
                                          }) =>
                                            flightFields({
                                              initialValues: {
                                                groupId: id,
                                              },
                                              loading,
                                              data,
                                              options,
                                            })
                                          }
                                          successMessage="Flight created"
                                          action={(values) =>
                                            addFlight({
                                              ...values,
                                              eventId: match.params.eventId,
                                            })
                                          }
                                          options={{
                                            groups: currentEvent?.groups?.items,
                                          }}
                                          data={{
                                            isCreateForm: true,
                                            eventId: match.params.eventId,
                                          }}
                                          renderButton={(
                                            setFormModalVisible
                                          ) => (
                                            <Button
                                              onClick={() => {
                                                setFormModalVisible(true);
                                              }}
                                              icon={<PlusOutlined />}
                                            >
                                              Add Flight
                                            </Button>
                                          )}
                                        />
                                      </div>
                                      {/* </div> */}
                                    </>
                                  ),
                                };
                              }

                              return (
                                <Panel
                                  header={
                                    <Space wrap>
                                      {title}
                                      <span className="groupedCollapseCount">
                                        {(outboundFlights?.length || 0) +
                                          (returnFlights?.length || 0)}{' '}
                                        Flights
                                      </span>
                                    </Space>
                                  }
                                  key={id}
                                  style={{ backgroundColor: 'white' }}
                                  {...extraProps}
                                >
                                  <DataTable
                                    header={(total) => (
                                      <div className="tableHeader">
                                        Outbound ({total})
                                      </div>
                                    )}
                                    columns={flightColumns({
                                      currentView,
                                      options: {
                                        groups: currentEvent?.groups?.items,
                                      },
                                    })}
                                    data={outboundFlights}
                                    loading={loading}
                                  />
                                  <DataTable
                                    header={(total) => (
                                      <div className="tableHeader">
                                        Return ({total})
                                      </div>
                                    )}
                                    columns={flightColumns({
                                      currentView,
                                      options: {
                                        groups: currentEvent?.groups?.items,
                                      },
                                    })}
                                    data={returnFlights}
                                    loading={loading}
                                  />
                                </Panel>
                              );
                            })}
                          </Collapse>
                        )}
                      </>
                    );
                  }}
                </ReduxDataProvider>
              );

              const accommodationTabContent = (
                // Content for "Accommodation" Tab
                <ReduxDataProvider
                  query="accommodationByEventId"
                  variables={{ eventId: match.params.eventId }}
                  updateAction={updateCurrentEventAccommodation as any}
                  upsertAction={upsertAccommodationItem}
                  stateSelector="currentEvent.accommodation"
                  include={['groups']}
                >
                  {({
                    data,
                    loading,
                    dateTotals,
                    contractedTotal,
                    groupContractedDays,
                  }) => {
                    const accommodationItems = data?.items?.filter(
                      (o: any) =>
                        !o.deletedAt &&
                        !currentEvent?.groups?.items.find(
                          ({ id }) => id === o.groupId
                        )?.deletedAt
                    );
                    const peopleCount = Object.keys(
                      _.groupBy(accommodationItems, (o) => o.slot?.personId)
                    ).filter(
                      (o: any) => !['null', 'undefined'].includes(o)
                    )?.length;

                    const contractedDayColumns: ColumnsType<DataType> = [
                      {
                        title: 'Date',
                        dataIndex: 'date',
                        key: 'date',
                        // render: (text) => <a>{text}</a>,
                      },
                      {
                        title: 'People',
                        dataIndex: 'people',
                        key: 'people',
                      },
                    ];

                    const contractedDaydata: DataType[] = Object.keys(
                      dateTotals
                    ).map((dateKey, i) => ({
                      key: i + 'key',
                      date: moment(dateKey).format('dddd Do MMM YY'),
                      people: dateTotals[dateKey],
                    }));

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 20,
                            marginTop: 4,
                          }}
                        >
                          {' '}
                          <Space wrap style={{ alignSelf: 'flex-start' }}>
                            <ViewPicker
                              value={currentView}
                              onChange={onViewTypeChanged}
                            />{' '}
                            <h3 style={{ margin: 0, color: '#777' }}>
                              {accommodationItems?.length} room
                              {accommodationItems?.length === 1
                                ? ''
                                : 's'},{' '}
                              {
                                _.groupBy(
                                  accommodationItems,
                                  (o) => o.slot?.personId
                                )?.length
                              }{' '}
                              {peopleCount}{' '}
                              {peopleCount === 1 ? ' person' : 'people'},{' '}
                              {contractedTotal} contracted day
                              {contractedTotal === 1 ? '' : 's'}
                            </h3>
                          </Space>
                          <Space
                            wrap
                            size={'middle'}
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {showEditActions() && (
                              <Upload
                                fileList={[]}
                                customRequest={(info) => {
                                  return;
                                }}
                                onChange={(info) => {
                                  try {
                                    var reader = new FileReader();
                                    reader.onload = async (event) => {
                                      const jsonData = Papa.parse(
                                        event?.target?.result as string,
                                        {
                                          skipEmptyLines: 'greedy',
                                          header: true,
                                          transformHeader: (label) => {
                                            return label
                                              .toLowerCase()
                                              .replace(/ /g, '_');
                                          },
                                        }
                                      );
                                      let itemsToAdd: any[] = [];

                                      const slots: any = await getAll({
                                        query: 'slotsByEventId',
                                        variables: {
                                          eventId: match.params.eventId,
                                        },
                                      });

                                      await Promise.all(
                                        jsonData.data.map(async (item: any) => {
                                          let targetSlot = null;
                                          let targetGroup = null;

                                          let newAccommodation: any = {
                                            eventId: match.params.eventId,
                                            roomType:
                                              item.room_type.toLowerCase(),
                                            notes: item.notes,
                                            checkinDate: item.checkin_date,
                                            checkoutDate: item.checkout_date,
                                          };

                                          if (item.group_name) {
                                            targetGroup =
                                              currentEvent?.groups?.items.find(
                                                ({ title }) => {
                                                  return (
                                                    title
                                                      ?.toLowerCase()
                                                      .replace(/ /g, '') ===
                                                    item.group_name
                                                      .toLowerCase()
                                                      .replace(/ /g, '')
                                                  );
                                                }
                                              );

                                            if (targetGroup) {
                                              newAccommodation.groupId =
                                                targetGroup.id;

                                              if (item.slot_id) {
                                                targetSlot = slots.find(
                                                  ({
                                                    title,
                                                    groupId,
                                                    eventId,
                                                  }: any) =>
                                                    title
                                                      ?.toLowerCase()
                                                      .replace(/ /g, '') ===
                                                      item.slot_id
                                                        .toLowerCase()
                                                        .replace(/ /g, '') &&
                                                    eventId ===
                                                      newAccommodation.eventId &&
                                                    groupId ===
                                                      newAccommodation.groupId
                                                );

                                                if (!targetSlot) {
                                                  const newSlotId = uuidv4();
                                                  const now =
                                                    new Date().toISOString();

                                                  await API.graphql({
                                                    query: createSlot,
                                                    variables: {
                                                      input: {
                                                        id: newSlotId,
                                                        title: item.slot_id,
                                                        eventId:
                                                          match.params.eventId,
                                                        groupId: targetGroup.id,
                                                        createdAt: now,
                                                        updatedAt: now,
                                                      },
                                                    },
                                                  });

                                                  targetSlot = {
                                                    id: newSlotId,
                                                  }; // create new slot
                                                }
                                                newAccommodation.slotId =
                                                  targetSlot.id;
                                              }

                                              itemsToAdd.push(newAccommodation);
                                            }
                                          }
                                        })
                                      );

                                      if (itemsToAdd.length > 0) {
                                        const chunks = _.chunk(itemsToAdd, 25);
                                        await Promise.all(
                                          chunks.map(batchAddAccommodations)
                                        );

                                        message.success(
                                          `${itemsToAdd.length} items added`
                                        );
                                      } else {
                                        message.error(`No items added`);
                                      }
                                    };
                                    reader.readAsText(
                                      info.file.originFileObj as Blob,
                                      'ISO-8859-4'
                                    );
                                  } catch (e: any) {
                                    message.error(e.message);
                                  }
                                }}
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  type="dashed"
                                  shape="round"
                                >
                                  Import From CSV
                                </Button>
                              </Upload>
                            )}
                            <Loading>
                              {({ loading, setLoading }) => (
                                <Button
                                  icon={<DownloadOutlined />}
                                  loading={loading}
                                  // type="primary"
                                  // size="large"
                                  type="dashed"
                                  shape="round"
                                  onClick={async () => {
                                    if (currentEvent?.groups?.items) {
                                      setLoading(true);
                                      const pages = orderGroups(
                                        currentEvent?.groups?.items
                                      ).map(({ id, title }) => ({
                                        queryProps: {
                                          query: accommodationByGroupId,
                                          variables: {
                                            groupId: id,
                                          },
                                        },
                                        name: title,
                                        specification:
                                          accommodationSpecification,
                                        transformKeys:
                                          accommodationTransformKeys,
                                      }));
                                      if (pages) {
                                        await exportAccommodationData(
                                          pages,
                                          `${currentEvent?.title}-accommodation.xlsx`
                                        );
                                      }
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  Export Accommodation List
                                </Button>
                              )}
                            </Loading>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                              }}
                            >
                              <FormButton
                                fields={accommodationFields}
                                successMessage="Accommodation created"
                                action={(values) =>
                                  addAccommodation({
                                    ...values,
                                    eventId: match.params.eventId,
                                  })
                                }
                                options={{
                                  groups: currentEvent?.groups?.items,
                                }}
                                renderButton={(setFormModalVisible) => (
                                  <Button
                                    onClick={() => {
                                      setFormModalVisible(true);
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                  >
                                    Add Accommodation
                                  </Button>
                                )}
                              />
                            </div>
                          </Space>
                        </div>
                        {currentView === 'all' ? (
                          <>
                            <DataTable
                              columns={accommodationColumns({
                                currentView,
                                options: {
                                  groups: currentEvent?.groups?.items,
                                },
                              })}
                              data={data?.items}
                              loading={loading}
                            />
                          </>
                        ) : (
                          <Collapse className="groupedCollapse" ghost>
                            {_.orderBy(
                              currentEvent?.groups?.items,
                              (item) => item.title
                            ).map(({ id, title }) => {
                              let extraProps = {};
                              if (showEditActions()) {
                                extraProps = {
                                  extra: (
                                    <>
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          return false;
                                        }}
                                      >
                                        <FormButton
                                          fields={({ loading, data }) =>
                                            accommodationFields({
                                              initialValues: {
                                                groupId: id,
                                              },
                                              loading,
                                              data,
                                              options: {
                                                groups:
                                                  currentEvent?.groups?.items,
                                              },
                                            })
                                          }
                                          successMessage="Accommodation created"
                                          action={(values) =>
                                            addAccommodation({
                                              ...values,
                                              eventId: match.params.eventId,
                                              groupId: id,
                                            })
                                          }
                                          options={{
                                            groups: currentEvent?.groups?.items,
                                          }}
                                          renderButton={(
                                            setFormModalVisible
                                          ) => (
                                            <Button
                                              onClick={() => {
                                                setFormModalVisible(true);
                                              }}
                                              icon={<PlusOutlined />}
                                              // type="primary"
                                            >
                                              Add Accommodation
                                            </Button>
                                          )}
                                        />
                                      </div>
                                      {/* </div> */}
                                    </>
                                  ),
                                };
                              }
                              return (
                                <Panel
                                  header={
                                    <Space>
                                      {title}
                                      <span className="groupedCollapseCount">
                                        {groupContractedDays[id] || 0} Room
                                        Nights
                                      </span>
                                    </Space>
                                  }
                                  key={id}
                                  style={{ backgroundColor: 'white' }}
                                  {...extraProps}
                                >
                                  <DataTable
                                    columns={accommodationColumns({
                                      currentView,
                                      options: {
                                        groups: currentEvent?.groups?.items,
                                      },
                                    })}
                                    data={data?.items.filter(
                                      ({ groupId }: any) => groupId === id
                                    )}
                                    loading={loading}
                                  />
                                </Panel>
                              );
                            })}
                          </Collapse>
                        )}
                        <Collapse style={{ marginTop: 20 }}>
                          <Panel
                            header="Room Nights"
                            key="1"
                            extra={
                              <span style={{ fontFamily: 'Segoe UI Bold' }}>
                                Total: {contractedTotal}
                              </span>
                            }
                          >
                            <Table
                              columns={contractedDayColumns}
                              dataSource={contractedDaydata}
                            />
                          </Panel>
                        </Collapse>
                      </>
                    );
                  }}
                </ReduxDataProvider>
              );

              const tabsItems = [
                { label: 'Groups', key: 'groups', children: groupsTabContent },
                { label: 'People', key: 'people', children: peopleTabContent },
                {
                  label: 'Flights & Transfers',
                  key: 'flights',
                  children: flightsTransfersTabContent,
                },
                {
                  label: 'Accommodation',
                  key: 'accommodation',
                  children: accommodationTabContent,
                },
              ];

              return (
                <div className="card-container">
                  <Tabs
                    defaultActiveKey={match.params.tab}
                    onChange={(key) => {
                      // history.push(
                      //   `/events/${match.params.eventId}/${key}`
                      // );
                      window.history.replaceState(
                        null,
                        document.title,
                        `/events/${match.params.eventId}/${key}`
                      );
                      setCurrentTab(key);
                    }}
                    type="card"
                    size="large"
                    items={tabsItems}
                  />
                </div>
              );
            }}
          />
        </Switch>
      }
    >
      <MainContent
      // extra={extraContent}
      ></MainContent>
    </PageHeader>
  );
});

export default EventScene;

import React from 'react';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Space, Button } from 'antd';

const passwordStrengthRule = (updateErrors: any) => ({
  validator(rule: any, value: any) {
    const errors = [];

    if (value.length < 8) {
      errors.push('minLength');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('includeUppercaseLetter');
    }

    if (!/[a-z]/.test(value)) {
      errors.push('includeLowercaseLetter');
    }

    if (!/[0-9]/.test(value)) {
      errors.push('includeNumber');
    }

    if (!/[^A-Za-z0-9 ]/.test(value)) {
      errors.push('includeSpecialCharacter');
    }
    updateErrors(errors);

    if (errors.length > 0) {
      return Promise.reject(errors);
    }

    return Promise.resolve();
  },
});

export default passwordStrengthRule;

export const passwordIconRender = (visible: boolean) =>
  visible ? (
    <div>
      <EyeTwoTone />
    </div>
  ) : (
    <div>
      <EyeInvisibleOutlined />
    </div>
  );

export const buildPasswordErrorMessages = (
  errors: any,
  showCompleted: boolean = false
) => {
  const allErrors: any = {
    minLength: 'be at least 8 characters long',
    includeUppercaseLetter: 'include an uppercase letter',
    includeLowercaseLetter: 'include a lowercase letter',
    includeNumber: 'include a number',
    includeSpecialCharacter: 'include a special character (!?#)',
  };

  const returnErrors = [];

  for (const key in allErrors) {
    const currentError = allErrors[key];

    returnErrors.push(
      <>
        <span
          style={{
            color: !showCompleted
              ? '#888'
              : errors.includes(key)
              ? '#ff4d4f'
              : '#377F63',
          }}
        >
          <Space>
            {showCompleted ? (
              errors.includes(key) ? (
                <WarningOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            ) : (
              <InfoCircleOutlined />
            )}

            {currentError}
          </Space>
        </span>
        <br />
      </>
    );
  }

  return returnErrors;
};

import { API } from 'aws-amplify';
import { Group, Optional } from '../globals';

import {
  createGroup as createGroupMutation,
  updateGroup as updateGroupMutation,
} from '../graphql/mutations';
import { getGroup as getGroupQuery } from '../graphql/queries';
import { upsertGroupItem } from '../reducers/eventsSlice';
import { store } from '../store';
import { getAll } from '../utils/getAll';
import notifyBugsnag from '../utils/notifyBugsnag';
import { search } from '../utils/search';

export async function addGroup(group: Optional<Group>) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: createGroupMutation,
      variables: {
        input: { ...group, createdAt: now, updatedAt: now },
      },
    });

    return response.data.createGroup;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function updateGroup(group: Group) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: updateGroupMutation,
      variables: {
        input: { ...group, updatedAt: now, __typename: undefined},
      },
    });

    store.dispatch(upsertGroupItem({ ...group, updatedAt: now }));

    return response.data.updateGroup;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getGroupById(id: string) {
  try {
    const response: any = await API.graphql({
      query: getGroupQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return response.data.getGroup;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getGroups() {
  try {
    const items: Group[] = await getAll({ query: 'listGroups' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
}
export async function getGroupsByEventId(eventId: string) {
  try {
    const items: Group[] = await getAll({
      query: 'groupsByEventId',
      variables: { eventId },
    });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
  return [];
}

export async function getGroupCountByEventId(eventId: string) {
  try {
    

    const groupdResults: Group[] = await getAll({
      query: 'groupsByEventId',
      variables: { eventId },
    });

    return groupdResults.length || 0;
  } catch (err) {
    notifyBugsnag(err);
  }
}

import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Affix,
  Tabs,
  Tag,
  Space,
} from 'antd';

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PhoneOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Route, Switch, useHistory } from 'react-router-dom';
import DataTable from '../../components/DataTable';

import { columns as slotColumns, fields as slotFields } from '../../data/slots';
import {
  columns as accommodationColumns,
  fields as accommodationFields,
} from '../../data/accommodation';
import './styles.less';
import {
  columns as flightColumns,
  fields as flightFields,
} from '../../data/flights';
import { fields as peopleFields } from '../../data/people';

import FormButton from '../../components/FormButton';
import { addFlight } from '../../methods/flights';
import { useEffect, useState } from 'react';
import { getGroupById } from '../../methods/groups';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  updateCurrentPersonFlights,
  updateCurrentPerson,
  updateCurrentPersonSlots,
  upsertAccommodationItem,
  upsertFlightItem,
  upsertSlotItem,
  updateCurrentPersonAccommodation,
} from '../../reducers/eventsSlice';

import { addSlot } from '../../methods/slots';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import showEditActions from '../../utils/showEditActions';
import { addAccommodation } from '../../methods/accommodation';
import moment from 'moment';
import { getPerson, removePerson, updatePerson } from '../../methods/people';
import ImagePreview from '../../components/ImagePreview';
import { DataType } from '../../globals';
import DeleteButton from '../../components/DeleteButton';
import DataWithSlots from '../../providers/DataWithSlots';

const { TabPane } = Tabs;

type Props = {
  match: any;
};

const PersonScene: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState<any>();

  useEffect(() => {
    const getItem = async () => {
      const person = await getPerson(props.match.params.personId);
      dispatch(updateCurrentPerson(person));
    };

    getItem();
  }, []);

  const events = useAppSelector((state) => state.events);
  const { currentPerson, tablesConfigs, searchQueries } = events;

  const renderTabs = ({ match, history }: any) => {
    if (!currentTab) {
      setCurrentTab(match.params.tab || 'people');
    }

    let operations: any = null;

    return (
      <ReduxDataProvider
        query="slotsByPersonId"
        variables={{ personId: match.params.personId }}
        updateAction={updateCurrentPersonSlots as any}
        upsertAction={upsertSlotItem}
        stateSelector="currentPerson.slots"
        include={['groups']}
      >
        {({ data, groups, loading }) => (
          <div className="card-container" style={{ marginTop: 16 }}>
            <Tabs
              type="card"
              size="large"
              defaultActiveKey={match.params.tab}
              tabBarExtraContent={operations}
              onChange={(key) => {
                if (!match.params.groupId) {
                  window.history.replaceState(
                    null,
                    document.title,
                    `/people/${match.params.personId}/${key}`
                  );
                } else {
                  window.history.replaceState(
                    null,
                    document.title,
                    `/events/${match.params.eventId}/groups/${match.params.groupId}/${key}`
                  );
                }
                setCurrentTab(key);
              }}
            >
              <TabPane tab="Slots" key="events">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginTop: 4,
                  }}
                >
                  <h3 style={{ margin: 0, color: '#777' }}>
                    {data?.items?.length ?? 0} slots
                  </h3>
                  {/* <Button icon={<PlusOutlined />} type="primary">
                    Add Slot
                  </Button>{' '} */}
                </div>
                <DataTable
                  columns={slotColumns({
                    isPersonView: true,
                    data: { isPersonView: true },
                    options: {
                      groups,
                    },
                  } as any)}
                  data={data?.items}
                  tableConfig={tablesConfigs.slots}
                  tableConfigKey="slots"
                  loading={loading}
                  sticky={{ offsetHeader: 124 }}
                />
              </TabPane>
              <TabPane tab="Flights &amp; Transfers" key="flights">
                <DataWithSlots
                  slots={data?.items}
                  query="flightsBySlotId"
                  updateAction={updateCurrentPersonFlights as any}
                  upsertAction={upsertFlightItem}
                  stateSelector="currentPerson.flights"
                >
                  {({ data, groups, loading }) => (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: 20,
                          marginTop: 4,
                        }}
                      >
                        <h3 style={{ margin: 0, color: '#777' }}>
                          {data?.items?.length ?? 0} flights
                        </h3>
                        {/* <FormButton
                          fields={({ loading, data, options }) =>
                            flightFields({
                              loading,
                              data,
                              options,
                            })
                          }
                          successMessage="Flight created"
                          action={(values) =>
                            addFlight({
                              ...values,
                            })
                          }
                          options={{
                            groups,
                          }}
                          data={{
                            isCreateForm: true,
                            // eventId: match.params.eventId,
                          }}
                          renderButton={(setFormModalVisible) => (
                            <Button
                              onClick={() => {
                                setFormModalVisible(true);
                              }}
                              type="primary"
                              icon={<PlusOutlined />}
                            >
                              Add Flight
                            </Button>
                          )}
                        /> */}
                      </div>
                      <DataTable
                        header={(total) => (
                          <div className="tableHeaderInner">
                            Outbound ({total})
                          </div>
                        )}
                        columns={flightColumns({
                          currentView: 'all',
                          isPersonView: true,
                          data: { allSlots: data?.items, isPersonView: true },
                          options: {
                            groups,
                          },
                        })}
                        data={data?.items?.filter(
                          (o: any) => o.flightType === 'outbound'
                        )}
                        tableConfig={tablesConfigs.outboundFlights}
                        tableConfigKey="outboundFlights"
                        loading={loading}
                        sticky={{ offsetHeader: 124 }}
                      />

                      <DataTable
                        header={(total) => (
                          <div className="tableHeader">Return ({total})</div>
                        )}
                        columns={flightColumns({
                          currentView: 'all',
                          isPersonView: true,
                          data: { allSlots: data?.items, isPersonView: true },
                          options: {
                            groups,
                          },
                        })}
                        data={data?.items?.filter(
                          (o: any) => o.flightType === 'return'
                        )}
                        tableConfig={tablesConfigs.returnFlights}
                        tableConfigKey="returnFlights"
                        loading={loading}
                        sticky={{ offsetHeader: 124 }}
                      />
                    </>
                  )}
                </DataWithSlots>
              </TabPane>
              <TabPane tab="Accommodation" key="accommodation">
                <DataWithSlots
                  slots={data?.items}
                  query="accommodationBySlotId"
                  updateAction={updateCurrentPersonAccommodation as any}
                  upsertAction={upsertAccommodationItem}
                  stateSelector="currentPerson.accommodation"
                >
                  {({ data, groups, loading }) => (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: 20,
                          marginTop: 4,
                        }}
                      >
                        <h3 style={{ margin: 0, color: '#777' }}>
                          {data?.items?.length ?? 0} bookings
                        </h3>
                        {/* <Button icon={<PlusOutlined />} type="primary">
                          Add Accommodation
                        </Button>{' '} */}
                      </div>
                      <DataTable
                        columns={accommodationColumns({
                          currentView: 'all',
                          isPersonView: true,
                          data: { allSlots: data?.items, isPersonView: true },
                          options: {
                            groups,
                          },
                        })}
                        data={data?.items}
                        tableConfig={tablesConfigs.slots}
                        tableConfigKey="accommodation"
                        loading={loading}
                        sticky={{ offsetHeader: 124 }}
                      />
                    </>
                  )}
                </DataWithSlots>
              </TabPane>
              <TabPane tab="Details" key="details">
                <Descriptions
                  bordered
                  column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
                  items={items}
                />
              </TabPane>
              {/* <TabPane tab="Checks" key="checks" /> */}
            </Tabs>
          </div>
        )}
      </ReduxDataProvider>
    );
  };
  const items: DescriptionsProps['items'] = [
    {
      label: 'Dietary Requirements',
      children: currentPerson?.dietaryRequirements,
    },
    {
      label: 'Vaccination Status',
      children: currentPerson?.vaccinationStatus,
    },
    {
      label: 'Passport',
      children: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ImagePreview src={currentPerson?.passportFileUrl} />
        </div>
      ),
    },
    {
      label: 'Driving License',
      children: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ImagePreview src={currentPerson?.drivingLicenseFileUrl} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Affix>
        <PageHeader
          className="site-page-header-responsive personPageHeader"
          onBack={() => history.goBack()}
          title={
            <>
              <span>
                {currentPerson?.title +
                  ' ' +
                  currentPerson?.firstName +
                  ' ' +
                  currentPerson?.lastName}
              </span>{' '}
              <span className="personHeaderDetails">
                {currentPerson?.publications}{' '}
                {currentPerson?.city || currentPerson?.country ? (
                  <span className="personFrom">
                    {currentPerson?.city}, {currentPerson?.country}
                  </span>
                ) : null}
                {currentPerson?.phone || currentPerson?.email ? (
                  <div>
                    <Space size={'middle'}>
                      {currentPerson?.phone ? (
                        <span className="personContact">
                          <PhoneOutlined style={{ marginRight: 8 }} />
                          {currentPerson?.phone}
                        </span>
                      ) : null}
                      {currentPerson?.email ? (
                        <span className="personContact">
                          <MailOutlined style={{ marginRight: 8 }} />
                          <a href={`mailto:${currentPerson?.email}`}>
                            {currentPerson?.email}
                          </a>
                        </span>
                      ) : null}
                    </Space>
                  </div>
                ) : null}
              </span>
            </>
          }
          extra={[
            <DeleteButton
              key={'removePerson'}
              item={currentPerson as DataType}
              successMessage="Person removed"
              action={async (values) => {
                await removePerson(values);
                history.push('/people');
              }}
              renderButton={(setFormModalVisible) => (
                <Button danger icon={<DeleteOutlined />}>
                  Remove Person
                </Button>
              )}
            />,
            <FormButton
              key={'updatePerson'}
              fields={peopleFields}
              item={currentPerson as DataType}
              successMessage="Person updated"
              action={updatePerson}
              renderButton={(setFormModalVisible) => (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setFormModalVisible(true);
                  }}
                  icon={<EditOutlined />}
                >
                  Edit Details
                </Button>
              )}
            />,
          ]}
        ></PageHeader>
      </Affix>

      <div className="personPageDetailHolder">
        <Switch>
          <Route path="/people/:personId/:tab?" exact render={renderTabs} />
          <Route
            path="/events/:eventId/people/:personId"
            exact
            render={renderTabs}
          />
          {/* <Route
          path="/events/:eventId/groups/:groupId/people/:personId/:tab?"
          exact
          render={renderTabs}
        /> */}
        </Switch>
      </div>
    </>
  );
};

export default PersonScene;

import React, { ReactElement, useEffect, useState } from 'react';

import { useHistory, useLocation, Link, RouteComponentProps } from 'react-router-dom';
import {
  Form,
  Input,
  Space,
  Row,
  Col,
  Typography,
  Button,
  Card,
  message,
  Modal,
} from 'antd';
import { Auth } from 'aws-amplify';
import { getTeamMember } from '../../methods/team';
import notifyBugsnag from '../../utils/notifyBugsnag';
import Bugsnag from '@bugsnag/js';
import { updateProfile } from '../../reducers/userSlice';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

type LoginValues = {
  email: string;
  password: string;
};

type CodeValues = {
  code: string;
};

type LocationState = {
  from: {
    pathname: string;
  };
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const LoginScene = (props: RouteComponentProps) => {
  // const history = useHistory();
  // const location = useLocation<LocationState>();
  const { history, location, match } = props;

  const [loading, setLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);

  const dispatch = useDispatch();

  let locationToUse: LocationState = {
    from: {
      pathname: '/',
    },
  };

  // useEffect(() => {
  //   if (match.params.code) {
  //     onFinishCode({ code: match.params.code });
  //   }
  // }, []);
  // if (location.state) {
  //   locationToUse = location.state;
  // }

  const onFinish = async (values: LoginValues) => {
    values.email = values.email.toLowerCase().trim();

    Bugsnag.leaveBreadcrumb('Login', {
      email: values.email,
    });

    setLoading(true);
    try {
      await Auth.signOut();
      localStorage.removeItem('goose:userToken');

      const userItem = await Auth.signIn(values.email, values.password);

      if (userItem.challengeName === 'CUSTOM_CHALLENGE') {
        Modal.success({
          title: 'Check your email',
          content: (
            <p>
              We have sent a login link to your email.
              <br />
              Please click on the link to login.
            </p>
          ),
          okButtonProps: {
            style: { display: 'none' },
          },
        });
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      message.error(e.message);
      notifyBugsnag(e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container">
      <Row justify="center">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}></div>
          <Row>
            <Col span={24}>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Log in
                  </Title>
                </div>

                <Form
                  {...layout}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password',
                      },
                    ]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Space size="middle" wrap>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Login
                      </Button>

                      <Link to="/auth/forgot-password">Forgot your password?</Link>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LoginScene;

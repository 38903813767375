import DataTable from '../../components/DataTable';

import { columns as teamColumns, fields as teamFields } from '../../data/team';
import FormButton from '../../components/FormButton';
import { addTeamMember, updateTeamMember } from '../../methods/team';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updateTeam, upsertTeamMember } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { PlusOutlined } from '@ant-design/icons';

import { PageHeader } from '@ant-design/pro-layout';

import { Button, Form, Input, message, RadioChangeEvent } from 'antd';
import { Radio, Space, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getProfile } from '../../graphql/queries';
import { Profile } from '../../API';
import { Optional } from '../../globals';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../reducers/userSlice';
import UpdateProfileScene from '../UpdateProfileScene';
import ChangePasswordScene from '../ChangePasswordScene';
import ChangeEmailScene from '../ChangeEmailScene';
import { Route, Switch } from 'react-router-dom';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};


const { TabPane } = Tabs;


type Props = {
  match: any;
};

const ProfileScene: React.FC<Props> = React.memo((props) => {
  return (
    <>
      <PageHeader>
        <div className="site-card-wrapper">
          <Switch>
            <Route
              path="/profile/:tab?"
              render={({ match, history }) => {
                return (
                  <Tabs tabPosition={'left'} defaultActiveKey={match.params.tab || 'update-profile'} onChange={(key) => {
                   
                    window.history.replaceState(
                      null,
                      document.title,
                      `/profile/${key}`
                    );
                  }}>
                    <TabPane tab="Profile" key="update-profile">
                      <UpdateProfileScene />
                    </TabPane>
                    <TabPane tab="Change Email" key="change-email">
                      <ChangeEmailScene />
                    </TabPane>
                    <TabPane tab="Change Password" key="change-password">
                      <ChangePasswordScene />
                    </TabPane>
                  </Tabs>
                );
              }}
            />
          </Switch>
        </div>
      </PageHeader>
    </>
  );
});

export default ProfileScene;

import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Storage } from 'aws-amplify';
import ImagePreview from '../ImagePreview';

const FileUpload = ({ directory = 'passports', onChange, value }: any) => {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(value);
  return (
    <>
      {!imageSrc && (
        <Upload
          fileList={[]}
          customRequest={({ onSuccess, onError, file, onProgress }) => {
            const actionUpload = async (item: any) => {
              let fileName = item.name;
              try {
                const ext = item.name.split('.').pop();
                fileName = `${item.uid}.${ext}`;
                await Storage.put(`${directory}/${fileName}`, file, {
                  level: 'public',
                  progressCallback(progress) {
                    if (onProgress) {
                      const prog = (progress.loaded / progress.total) * 100;
                      onProgress({
                        percent: prog,
                      });
                     
                    }
                  },
                });

                setLoading(false);
                setImageSrc(
                  `https://goose-cdn95937-staging.s3.eu-west-2.amazonaws.com/public/${directory}/${fileName}`
                );
                onChange(
                  `https://goose-cdn95937-staging.s3.eu-west-2.amazonaws.com/public/${directory}/${fileName}`
                );
                if (onSuccess) {
                  onSuccess('Ok');
                }


              } catch (err: any) {
                console.log(err);
                // if (onError) {
                //   onError({err});
                // }

                message.error(err.reason);
              }
            };
            actionUpload(file);
            return;
          }}
          onChange={(info) => {
            setLoading(true);
          }}
        >
          <Button icon={<UploadOutlined />} loading={loading}>
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        </Upload>
      )}
      {imageSrc && (
        <>
          <ImagePreview src={imageSrc} />
          <Button
            icon={<UploadOutlined />}
            onClick={() => {
              setImageSrc('');
              onChange(
                ''
              );
            }}
          >
            Remove
          </Button>
        </>
      )}
    </>
  );
};

export default FileUpload;

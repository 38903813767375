import React, { useEffect, useRef, useState } from 'react';
import './App.less';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  type RouteProps,
  Link,
} from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Layout,
  Menu,
  Result,
  Button,
  Modal,
  notification,
  Spin,
  ConfigProvider,
} from 'antd';
import en_GB from 'antd/locale/en_GB';
import LoginScene from './scenes/LoginScene';
import { Amplify, API, Auth } from 'aws-amplify';
import EventsScene from './scenes/EventsScene';

import awsExports from './aws-exports';
import { Content } from 'antd/lib/layout/layout';
import EventScene from './scenes/EventScene';
import PeopleScene from './scenes/PeopleScene';
import { useDispatch } from 'react-redux';
import {
  upsertAccommodationItem,
  upsertAccommodations,
  upsertFlightItem,
  upsertFlights,
  upsertPeople,
  upsertPerson,
  upsertSlotItem,
} from './reducers/eventsSlice';
import {
  onBatchPeopleAdded,
  onUpdateAnyAccommodation,
  onUpdateAnyFlight,
  onUpdateAnyPerson,
  onUpdateAnySlot,
  onBatchFlightsAdded,
  onBatchAccommodationAdded,
  onUpdateProfileWithId,
} from './graphql/subscriptions';
import PersonScene from './scenes/PersonScene';
import TeamScene from './scenes/TeamScene';
import { getTeamMember } from './methods/team';
import { Profile } from './API';
import ForgotPasswordScene from './scenes/ForgotPasswordScene';
import NewPasswordScene from './scenes/NewPasswordScene';
import ProfileScene from './scenes/ProfileScene';
import { updateProfile } from './reducers/userSlice';
import { useAppSelector } from './hooks';
import Bugsnag from '@bugsnag/js';
import SetupAccountScene from './scenes/SetupAccountScene';
import notifyBugsnag from './utils/notifyBugsnag';
import showEditActions from './utils/showEditActions';
import { profileEventsByOwnerId } from './graphql/queries';
import VerifyAuthScene from './scenes/VerifyAuthScene';

const LoadingScene = () => (
  <div className="spinContainer">
    <Spin />
  </div>
);
const { Header } = Layout;

Amplify.configure({ ...awsExports, authenticationFlowType: 'CUSTOM_AUTH' });

interface PrivateRouteProps extends RouteProps {}
const PrivateRoute = ({ component, children, ...rest }: PrivateRouteProps) => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // let isLoggedIn = !!localStorage.getItem('goose:userToken');
  let RouteComponent: any;
  if (children) {
    RouteComponent = () => component || children;
  }

  useEffect(() => {
    const getAuthState = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
        setLoading(false);
      } catch (e) {
        setIsLoggedIn(false);
        setLoading(false);
      }
    };
    getAuthState();
  }, []);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (loading) {
          return (
            <div className="spinContainer">
              <Spin />
            </div>
          );
        }

        return isLoggedIn && RouteComponent ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

const HeaderWithRouter = ({ user }: { user: Profile }) => {
  const history = useHistory();
  const headerButtonPressBusy = useRef(true);

  useEffect(() => {
    setTimeout(() => {
      headerButtonPressBusy.current = false;
    }, 500);
  }, []);

  let menuItems = [
    { key: 'events', label: 'Events' },
    { key: 'people', label: 'People' },
  ];
  if (user?.role && ['admin', 'manager'].includes(user?.role)) {
    menuItems.push({ key: 'team', label: 'Team' });
  }

  return (
    <Header>
      <div style={{ display: 'flex' }}>
        <div
          className="logo"
          onClick={() => {
            history.push('/');
          }}
          style={{ cursor: 'pointer' }}
        >
          <img src="/img/logo-white.png" height="31" alt="Goose UK" />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ flex: 1 }}
          defaultSelectedKeys={['events']}
          selectedKeys={[history.location.pathname.split('/')[1]]}
          onClick={({ key }) => {
            if (
              !headerButtonPressBusy.current &&
              key !== history.location.pathname.split('/')[1]
            ) {
              history.push(`/${key}`);
            }
          }}
          items={menuItems}
        />

        <div style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
          <Link to="/profile" style={{ color: '#fff', marginRight: 20 }}>
            <UserOutlined /> Hi {user?.firstName || 'There'}
          </Link>
          <Button
            type="link"
            icon={<LogoutOutlined />}
            key="logout"
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure you want to log out?',
                okText: 'Yes',
                cancelText: 'Cancel',
                onOk: () => {
                  // use function inside to prevent spinner on "onOk" button
                  const actionLogout = async () => {
                    localStorage.removeItem('goose:userToken');
                    localStorage.removeItem('goose:isAppManager');
                    localStorage.removeItem('goose:isAdmin');
                    localStorage.removeItem('goose:user');

                    Auth.signOut();
                    history.push('/auth/login');
                  };
                  actionLogout();
                },
              });
            }}
          ></Button>
        </div>
      </div>
    </Header>
  );
};

const NotFound = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.push('/');
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

function App() {
  const dispatch = useDispatch();
  const subscriptionsSetUp = useRef(false);
  const showingSubError = useRef(false);
  const subs = useRef([]);
  const [loading, setLoading] = useState(true);

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    // need to move this to own function so can fire when logging in
    const fetchUser = async () => {
      try {
        const currentUserInfo = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        currentUserInfo.refreshSession(
          // @ts-ignore
          currentSession.refreshToken,
          async () => {
            const userInfo = await Auth.currentAuthenticatedUser();
            console.log('userInfo', userInfo);

            if (userInfo?.attributes?.sub) {
              const profile = await getTeamMember(userInfo?.attributes?.sub);

              dispatch(updateProfile(profile));
              const dataQuery: any = API.graphql({
                query: onUpdateProfileWithId,
                variables: { id: userInfo?.attributes?.sub },
              });
              dataQuery.subscribe({
                next: async ({ provider, value }: { provider: any; value: any }) => {
                  const profile = await getTeamMember(userInfo?.attributes?.sub);

                  dispatch(updateProfile(profile));
                },
                error: (error: any) => {
                  if (!showingSubError.current) {
                    showingSubError.current = true;
                    notification.warning({
                      message: 'Connection Timeout',
                      description: (
                        <span>
                          Please{' '}
                          <a href={window.location.pathname}>refresh your browser</a>
                        </span>
                      ),
                      onClick: () => {
                        showingSubError.current = false;
                      },
                    });
                  }
                },
              });
              setLoading(false);
            }

            if (!subscriptionsSetUp.current) {
              subscriptionsSetUp.current = true;
              const typesToSubscribeTo: any = [
                // { query: onUpdateProfile, action: async (...actionProps) => {

                //   const profileEventsForUser =  await API.graphql({
                //     query: profileEventsByOwnerId,
                //     variables: { owner: itemsToUpdate.id },
                //   }) as any;

                //   console.log('profileEventsForUser', profileEventsForUser);

                //   itemsToUpdate.events = profileEventsForUser.data.profileEventsByOwnerId;

                //   actionProps.events =

                //   return updateProfile(...actionProps);
                // }},
                { query: onUpdateAnyPerson, action: upsertPerson },
                { query: onBatchPeopleAdded, action: upsertPeople },
                { query: onBatchFlightsAdded, action: upsertFlights },
                {
                  query: onBatchAccommodationAdded,
                  action: upsertAccommodations,
                },
                { query: onUpdateAnySlot, action: upsertSlotItem },
                { query: onUpdateAnyFlight, action: upsertFlightItem },
                {
                  query: onUpdateAnyAccommodation,
                  action: upsertAccommodationItem,
                },
              ];

              const addSubscription = async ({
                query,
                action,
              }: {
                query: string;
                action: any;
              }) => {
                const dataQuery: any = API.graphql({ query });
                return dataQuery.subscribe({
                  next: async ({ provider, value }: { provider: any; value: any }) => {
                    for (const key in value.data) {
                      dispatch(action(value.data[key]));
                    }
                  },
                  error: (error: any) => {
                    if (!showingSubError.current) {
                      showingSubError.current = true;
                      notification.warning({
                        message: 'Connection Timeout',
                        description: (
                          <span>
                            Please{' '}
                            <a href={window.location.pathname}>refresh your browser</a>
                          </span>
                        ),
                        onClick: () => {
                          showingSubError.current = false;
                        },
                      });
                    }
                  },
                });
              };

              const subscribeToItems = async () => {
                subs.current = (await Promise.all(
                  typesToSubscribeTo.map(addSubscription)
                )) as any;
              };

              subscribeToItems();
            }
          }
        );
      } catch (e) {
        setLoading(false);
        notifyBugsnag(e);
      }
    };
    fetchUser();

    return () => {
      subs.current?.forEach((sub: any) => {
        sub.unsubscribe();
      });
    };
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#0082c2',
          borderRadius: 5,
          fontFamily: 'Segoe UI Regular',
        },
      }}
      locale={en_GB}
    >
      <Router>
        <Switch>
          <Route path="/auth/login" exact component={LoginScene} />
          <Route path="/auth/code" exact component={LoginScene} />
          <Route path="/auth/verify/:token" exact component={VerifyAuthScene} />
          <Route path="/auth/forgot-password" exact component={ForgotPasswordScene} />
          <Route path="/auth/reset-password/:token" exact component={NewPasswordScene} />
          <Route path="/account-setup/:token" exact component={SetupAccountScene} />

          <PrivateRoute path="/">
            <Layout className="layout">
              <HeaderWithRouter user={user?.profile} />
              <Content style={{ padding: '0 0px' }}>
                <Switch>
                  <Redirect exact from="/" to="/events" />
                  <Route path="/people" exact component={PeopleScene} />
                  <Route path="/people/:personId/:tab?" exact component={PersonScene} />
                  <Route path="/events" exact component={EventsScene} />
                  <Route path="/events/:eventId" component={EventScene} />
                  {loading && <Route path="/team" exact component={LoadingScene} />}
                  {!loading && ['admin', 'manager'].includes(user?.profile?.role) && (
                    <Route path="/team" exact component={TeamScene} />
                  )}
                  <Route path="/profile" component={ProfileScene} />
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Content>
            </Layout>
          </PrivateRoute>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

export default React.memo(App);

import { useState } from 'react';

type ChildrenParams = {
  loading: boolean,
  setLoading: (loading: boolean) => void
};

type Props = {
  loadingOnStart?: boolean;
  children: (params: ChildrenParams) => React.ReactElement;
};

const Loading: React.FC<Props> = ({ children, loadingOnStart }) => {
  const [loading, setLoading] = useState(!!loadingOnStart);
  return children({ loading, setLoading });
};

export default Loading;

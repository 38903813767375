import { Button, Image, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
const ImagePreview = ({ src }: any) => {
  const [visible, setVisible] = useState(false);
  const [signedSrc, setSignedSrc] = useState('');

  useEffect(() => {
    const getSrc = async () => {
      if (src) {
        const signedURL = await Storage.get(
          src.replace(
            'https://goose-cdn95937-staging.s3.eu-west-2.amazonaws.com/public/',
            ''
          )
        ); // get key from Storage.list
        setSignedSrc(signedURL);
      }
    };
    getSrc();
  }, [src]);

  return src ? (
    <>
      <Tooltip title="View">
        <Button
          shape="circle"
          icon={<FileSearchOutlined />}
          onClick={() => setVisible(true)}
        />
      </Tooltip>


        <Image
          width={200}
          style={{ display: 'none' }}
          src={signedSrc}
          preview={{
            visible: visible,
            src: signedSrc,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />

    </>
  ) : null;
};

export default ImagePreview;

import {
  ActionCreatorWithPayload,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { EventType } from '@testing-library/react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataType, Query, ResponseType, SearchQuery } from '../../globals';
import {
  updateSearchQuery,
  updateTableConfig,
} from '../../reducers/eventsSlice';
import { getAll } from '../../utils/getAll';
import { search } from '../../utils/search';

type Props = {
  query?: string;
  columns: ColumnsType<DataType>;
  baseQuery?: any;
  header?: (count?: number) => React.ReactElement;
  data?: DataType[];
  updateAction?: any;
  subscription?: any;
  upsertAction?: any;
  tableConfig?: any;
  tableConfigKey?: string;
  loading: boolean;
  sticky?: any;
};
interface Params {
  pagination?: TablePaginationConfig;
  sorter?: SorterResult<any> | SorterResult<any>[];
  sortField?: string;
  sortOrder?: string;
}

const DataTable: React.FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const filterQuery = {};

  return (
    <>
      {props.header && props.header(props.data?.length)}
      <Table
        scroll={{ x: 0 }}
        columns={props.columns}
        rowKey={(record) => record.id}
        dataSource={props.data}
        // style={{ width: '100%' }}
        pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
        loading={props.loading}
        showSorterTooltip={false}
        sticky={props.sticky ? props.sticky : true}
        onChange={(newPagination, filters, sorter: any) => {
          // dispatch(
          //   updateTableConfig({
          //     [props.tableConfigKey]: {
          //       pagination: {
          //         ...props.tableConfig.pagination,
          //         ...newPagination,
          //         total: props.data?.length,
          //       },
          //     },
          //   })
          // );
          // fetchData({
          //   sortField: sorter.field as string,
          //   sortOrder: sorter.order as string,
          //   pagination: newPagination,
          // });
        }}
      />
    </>
  );
});

export default DataTable;

import { Button, message, Popconfirm } from 'antd';

import { Accommodation, Flight, Group, Slot } from '../../API';
import { DataType } from '../../globals';
import { DeleteOutlined } from '@ant-design/icons';

type Props = {
  item: DataType;
  successMessage: string;
  action: (values: any) => Promise<any>;
  renderButton?: (props: Props) => React.ReactElement;
  disabled?: boolean;
};

const DeleteButton: React.FC<Props> = (props) => {
  return (
    <>
      <Popconfirm
        disabled={props.disabled}
        title="Are you sure?"
        onConfirm={async () => {
          await props.action(props.item);

          message.success(props.successMessage);
        }}
      >
        {props.renderButton && props.renderButton(props)}
      </Popconfirm>
    </>
  );
};

DeleteButton.defaultProps = {
  renderButton: (props) => (
    <Button shape="circle" icon={<DeleteOutlined />} danger disabled={props.disabled} />
  ),
};

export default DeleteButton;

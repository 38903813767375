import DataTable from '../../components/DataTable';

import { columns as teamColumns, fields as teamFields } from '../../data/team';
import FormButton from '../../components/FormButton';
import { addTeamMember, getTeam, updateTeamMember } from '../../methods/team';
import ReduxDataProvider from '../../providers/ReduxDataProvider';
import { updateTeam, upsertTeamMember } from '../../reducers/eventsSlice';
import { useAppSelector } from '../../hooks';
import showEditActions from '../../utils/showEditActions';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Form, Input, message, RadioChangeEvent } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getProfile } from '../../graphql/queries';
import { Profile } from '../../API';
import { Optional } from '../../globals';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../reducers/userSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 10 },
};

const ChangeEmailScene: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const { profile } = user;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      values.email = values.email.toLowerCase().trim();

      const team = await getTeam();

      if (
        !team.find(({ email }) => email?.toLowerCase().trim() === values.email)
      ) {
        try {
          const response = await API.put('GooseRest', `/team/change-email`, {
            body: { id: profile.id, email: values.email },
          });
          message.success('Email Updated');
        } catch (e: any) {
          if (e?.response?.data?.message) {
            message.error(e?.response?.data?.message);
          }
        }

        setLoading(false);
      } else {
        setLoading(false);
        message.error('Email already in use');
      }
    } catch (e: any) {
      setLoading(false);
      message.error(e.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={profile}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <br />
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});

export default ChangeEmailScene;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface UserState {
  profile?: any;
}

// Define the initial state using that type
const initialState: UserState = {
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateProfile: (state, action: PayloadAction<any>) => {
      console.log('updateProfile action.payload', action.payload);
      state.profile = action.payload;
      state.profile.events.items = state.profile.events.items.filter((o:any) => !o.event.deletedAt);

      localStorage.setItem('goose:user', JSON.stringify(action.payload));
    },
    addEventItem: (state, action: PayloadAction<any>) => {


      if (!state.profile?.events?.items) {
        state.profile.events.items = [action.payload];
        state.profile.eventIds = [action.payload.event.id];

      } else {
        state.profile.events.items.push(action.payload);
        state.profile.eventIds.push(action.payload.event.id);
      }
      state.profile.events.items = state.profile.events.items.filter((o:any) => !o.event.deletedAt);

    },
    updateEventItem: (state, action: PayloadAction<any>) => {
      if (state.profile?.events?.items?.length > 0) {
        const targetIndex = state.profile.events.items.findIndex(
          ({ eventId }: any) => eventId === action.payload.id
        );
        state.profile.events.items[targetIndex].event = {
          ...state.profile.events.items[targetIndex].event,
          ...action.payload,
        };

        state.profile.events.items = state.profile.events.items.filter((o:any) => !o.event.deletedAt);
      }
    },
  },
});

export const { updateProfile, updateEventItem, addEventItem } = userSlice.actions;

export default userSlice.reducer;

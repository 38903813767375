import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  Event,
  Group,
  Optional,
  Person,
  ResponseType,
  SearchQueries,
  TableConfigs,
} from '../globals';
import { Accommodation, Flight, Profile, Slot } from '../API';

// Define a type for the slice state

interface EventsState {
  list: any[];
  team: { items: any[]; hasFetched: boolean };
  people: { items: any[]; hasFetched: boolean };
  allEvents: { items: any[]; hasFetched: boolean };
  eventMeta: { [eventId: string]: { groupCount: number } };
  currentEvent: Event | null;
  currentGroup: Group | null;
  currentPerson: Person | null;
  tablesConfigs: TableConfigs;
  searchQueries: SearchQueries;
}

const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};
// Define the initial state using that type
const initialState: EventsState = {
  list: [],
  team: { items: [], hasFetched: false },
  people: { items: [], hasFetched: false },
  allEvents: { items: [], hasFetched: false },
  eventMeta: {},
  currentEvent: null,
  currentGroup: null,
  currentPerson: null,
  tablesConfigs: {
    slots: {
      pagination: defaultPagination,
      sortField: 'id',
      sortOrder: 'desc',
    },
    outboundFlights: { pagination: defaultPagination },
    returnFlights: { pagination: defaultPagination },
    people: { pagination: defaultPagination },
  },
  searchQueries: {
    slots: {
      query: 'searchSlots',
      variables: {
        filter: {
          deletedAt: { exists: false },
        },
      },
    },
    outboundFlights: {
      query: 'searchFlights',
      variables: {
        filter: {
          deletedAt: { exists: false },
          flightType: { eq: 'outbound' },
        },
      },
    },
    returnFlights: {
      query: 'searchFlights',
      variables: {
        filter: {
          deletedAt: { exists: false },
          flightType: { eq: 'return' },
        },
      },
    },
  },
};

export const eventsSlice = createSlice({
  name: 'events',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateAllEvents: (state, action: PayloadAction<ResponseType<Event>>) => {
      state.allEvents = { ...action.payload, hasFetched: true };
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateTeam: (state, action: PayloadAction<ResponseType<Profile>>) => {
      state.team = { ...action.payload, hasFetched: true };

    },
    upsertTeamMember: (state, action: PayloadAction<Profile>) => {
      if (state.team) {
        if (action.payload.deletedAt) {
          state.team.items = state.team.items.filter(({ id }) => {
            return id !== action.payload.id;
          });
        } else {
          const foundItemIndex = state.team.items.findIndex(
            ({ id }) => id === action.payload.id
          );

          if (state.team && foundItemIndex > -1) {
            state.team.items[foundItemIndex] = action.payload;
          } else {
            state.team.items.push(action.payload);
          }
        }
      }
    },
    updatePeople: (state, action: PayloadAction<ResponseType<Person>>) => {
      state.people = { ...action.payload, hasFetched: true };
    },
    upsertPeople: (state, action: PayloadAction<Person[]>) => {
      if (state.people) {
        state.people.items = [...state.people.items, ...action.payload];
      }
    },
    upsertPerson: (state, action: PayloadAction<Person>) => {
      if (state.people) {
        if (action.payload.deletedAt) {
          state.people.items = state.people.items.filter(({ id }) => {
            return id !== action.payload.id;
          });
        } else {
          const foundItemIndex = state.people.items.findIndex(
            ({ id }) => id === action.payload.id
          );

          if (state.people && foundItemIndex > -1) {
            state.people.items[foundItemIndex] = action.payload;
          } else {
            state.people.items.push(action.payload);
          }
        }
      }

      if (state.currentGroup) {
        // update slots

        if (
          state.currentGroup.slots?.items &&
          state.currentGroup?.slots?.items.length > 0
        ) {
          state.currentGroup.slots.items = state.currentGroup.slots?.items.map(
            (item) => {
              if (item.personId === action.payload.id) {
                item.person = { ...item.person, ...action.payload };

                if (action.payload.deletedAt) {
                  item.person = null;
                  item.personId = null;
                }
              }
              return item;
            }
          );
        }
      }

      if (state.currentEvent?.slots) {
        // update slots

        if (
          state.currentEvent.slots?.items &&
          state.currentEvent?.slots?.items.length > 0
        ) {
          state.currentEvent.slots.items = state.currentEvent.slots?.items.map(
            (item) => {
              if (item.personId === action.payload.id) {
                item.person = { ...item.person, ...action.payload };

                if (action.payload.deletedAt) {
                  item.person = null;
                  item.personId = null;
                }
              }
              return item;
            }
          );
        }
      }

      if (state.currentEvent?.flights) {
        // update slots

        if (
          state.currentEvent.flights?.items &&
          state.currentEvent?.flights?.items.length > 0
        ) {
          state.currentEvent.flights.items =
            state.currentEvent.flights?.items.map((item) => {
              if (item.slot?.personId === action.payload.id) {
                item.slot.person = { ...item.slot.person, ...action.payload };

                if (action.payload.deletedAt) {
                  item.slot.person = null;
                  item.slot.personId = null;
                }
              }
              return item;
            });
        }
      }
    },
    updateCurrentPerson: (state, action: PayloadAction<Person>) => {
      if (state.currentPerson && state.currentPerson.id === action.payload.id) {
        action.payload = {
          slots: state.currentPerson.slots,
          flights: state.currentPerson.flights,
          accommodation: state.currentPerson.accommodation,
          ...action.payload,
        };
      }

      state.currentPerson = action.payload;
    },
    updateCurrentPersonSlots: (
      state,
      action: PayloadAction<ResponseType<Slot>>
    ) => {
      // if (state.currentPerson) {
      //   state.currentPerson.slots = {
      //     ...state.currentPerson.slots,
      //     items: action.payload.items.map((item) => {
      //       if (item.person?.deletedAt) {
      //         item.personId = null;
      //         item.person = null;
      //       }
      //       return item;
      //     }),
      //   };
      // }
    },
    updateTableConfig: (state, action: PayloadAction<TableConfigs>) => {
      for (const key in action.payload) {
        state.tablesConfigs[key] = {
          ...state.tablesConfigs[key],
          ...action.payload[key],
          sortField:
            action.payload[key].sortField || state.tablesConfigs[key].sortField,
          sortOrder:
            action.payload[key].sortOrder || state.tablesConfigs[key].sortOrder,
          pagination: {
            ...state.tablesConfigs[key].pagination,
            ...action.payload[key].pagination,
          },
        };
      }
    },
    updateSearchQuery: (state, action: PayloadAction<SearchQueries>) => {
      for (const key in action.payload) {
        state.searchQueries[key] = action.payload[key];
      }
    },
    updateList: (state, action: PayloadAction<Event[]>) => {
      state.list = action.payload;
    },
    updateCurrentEvent: (state, action: PayloadAction<Event>) => {
      const groupdSlots = state.currentEvent?.slots?.items?.filter(
        ({ eventId }) => eventId === action.payload.id
      );
      const flights = state.currentEvent?.flights?.items?.filter(
        ({ eventId }) => eventId === action.payload.id
      );

      const accommodation = state.currentEvent?.accommodation?.items?.filter(
        ({ eventId }) => eventId === action.payload.id
      );

      if (
        (groupdSlots && groupdSlots.length > 0) ||
        (flights && flights.length > 0) ||
        (accommodation && accommodation.length > 0)
      ) {
        // keeps slots
        state.currentEvent = { ...state.currentEvent, ...action.payload };
      } else {
        // will clear slots
        state.currentEvent = action.payload;
      }
    },
    updateCurrentEventSlots: (
      state,
      action: PayloadAction<ResponseType<Slot>>
    ) => {
      if (state.currentEvent) {
        state.currentEvent.slots = {
          ...state.currentEvent.slots,
          items: action.payload.items.map((item) => {
            if (item.person?.deletedAt) {
              item.personId = null;
              item.person = null;
            }
            return item;
          }),
        };
      }
    },
    updateCurrentEventFlights: (
      state,
      action: PayloadAction<ResponseType<Flight>>
    ) => {
      if (state.currentEvent) {
        state.currentEvent.flights = action.payload;

        state.currentEvent.flights.items = state.currentEvent.flights.items.map(
          (item) => {
            if (item.slot?.deletedAt) {
              item.slot = null;
              item.slotId = null;
            }
            return item;
          }
        );
      }
    },
    updateCurrentEventAccommodation: (
      state,
      action: PayloadAction<ResponseType<Accommodation>>
    ) => {
      if (state.currentEvent) {
        state.currentEvent.accommodation = action.payload;

        state.currentEvent.accommodation.items =
          state.currentEvent.accommodation.items.map((item) => {
            if (item.slot?.deletedAt) {
              item.slot = null;
              item.slotId = null;
            }
            return item;
          });
      }
    },
    upsertGroupItem: (state, action: PayloadAction<Group>) => {
      if (state.currentEvent) {
        if (state.currentEvent.id === action.payload.eventId) {
          if (!state.currentEvent.groups?.items) {
            state.currentEvent.groups = { items: [] };
          }

          const targetGroupIndex = state.currentEvent?.groups?.items.findIndex(
            ({ id }) => id === action.payload.id
          );

          if (targetGroupIndex > -1) {
            state.currentEvent.groups.items[targetGroupIndex] = action.payload;
            if (state.currentEvent.groups.items[targetGroupIndex].deletedAt) {
              state.currentEvent.groups.items.splice(targetGroupIndex, 1);
            }
          } else {
            if (!action.payload.deletedAt) {
              state.currentEvent.groups.items.push(action.payload);
            }
          }
        }
      }

      if (state.currentGroup?.id === action.payload.id) {
        state.currentGroup = { ...state.currentGroup, ...action.payload };
      }
    },

    updateEventMeta: (
      state,
      action: PayloadAction<{ id: string; meta: { groupCount?: number } }>
    ) => {
      state.eventMeta[action.payload.id] = {
        ...state.eventMeta[action.payload.id],
        ...action.payload.meta,
      };
    },
    upsertSlotItem: (state, action: PayloadAction<Slot>) => {
      const actionUpdates = (key: string) => {
        const targetState = (state as any)[key] as any;
        if (!targetState?.slots?.items) {
          targetState.slots = { items: [] };
        }
        if (action.payload.deletedAt) {
          targetState.slots.items = targetState.slots.items.filter(
            ({ id }: any) => {
              return id !== action.payload.id;
            }
          );
        } else {
          const foundItemIndex = targetState.slots.items.findIndex(
            ({ id }: any) => id === action.payload.id
          );

          if (targetState.slots && foundItemIndex > -1) {
            targetState.slots.items[foundItemIndex] = action.payload;
          } else {
            targetState.slots?.items.push(action.payload);
          }
        }

        if (!targetState?.flights?.items) {
          targetState.flights = { items: [] };
        }

        targetState.flights.items = targetState.flights.items.map(
          (item: any) => {
            if (item.slotId === action.payload.id) {
              if (action.payload.deletedAt) {
                item.slot = null;
                item.slotId = null;
              } else {
                item.slot = { ...item.slot, ...action.payload };
              }
            }
            return item;
          }
        );

        if (!targetState?.accommodation?.items) {
          targetState.accommodation = { items: [] };
        }
        targetState.accommodation.items = targetState.accommodation.items.map(
          (item: any) => {
            if (item.slotId === action.payload.id) {
              if (action.payload.deletedAt) {
                item.slot = null;
                item.slotId = null;
              } else {
                item.slot = { ...item.slot, ...action.payload };
              }
            }
            return item;
          }
        );
      };
      if (state.currentEvent?.id === action.payload.eventId) {
        actionUpdates('currentEvent');
      }

      if (state.currentGroup?.id === action.payload.groupId) {
        actionUpdates('currentGroup');
      }
    },
    updateCurrentGroup: (state, action: PayloadAction<Group>) => {
      const groupdSlots = state.currentGroup?.slots?.items?.filter(
        ({ groupId }) => groupId === action.payload.id
      );
      const flights = state.currentGroup?.flights?.items?.filter(
        ({ groupId }) => groupId === action.payload.id
      );

      const accommodation = state.currentGroup?.accommodation?.items?.filter(
        ({ groupId }) => groupId === action.payload.id
      );

      if (
        (groupdSlots && groupdSlots.length > 0) ||
        (flights && flights.length > 0) ||
        (accommodation && accommodation.length > 0)
      ) {
        // keeps slots
        state.currentGroup = { ...state.currentGroup, ...action.payload };
      } else {
        // will clear slots
        state.currentGroup = action.payload;
      }
    },
    updateCurrentGroupSlots: (
      state,
      action: PayloadAction<ResponseType<Slot>>
    ) => {
      if (state.currentGroup) {
        state.currentGroup.slots = {
          ...state.currentGroup.slots,
          items: action.payload.items.map((item) => {
            if (item.person?.deletedAt) {
              item.personId = null;
              item.person = null;
            }
            return item;
          }),
        };
      }
    },
    updateCurrentGroupFlights: (
      state,
      action: PayloadAction<ResponseType<Flight>>
    ) => {
      if (state.currentGroup) {
        state.currentGroup.flights = action.payload;

        state.currentGroup.flights.items = state.currentGroup.flights.items.map(
          (item) => {
            if (item.slot?.deletedAt) {
              item.slot = null;
              item.slotId = null;
            }
            return item;
          }
        );
      }
    },
    upsertFlights: (state, action: PayloadAction<Flight[]>) => {
      if (state.currentEvent?.flights) {
        state.currentEvent.flights.items = [
          ...state.currentEvent.flights.items,
          ...action.payload,
        ];
      }
      if (state.currentGroup?.flights) {
        state.currentGroup.flights.items = [
          ...state.currentGroup.flights.items,
          ...action.payload,
        ];
      }
    },

    upsertFlightItem: (state, action: PayloadAction<Flight>) => {
      const actionUpdates = (key: string) => {
        const targetState = (state as any)[key] as any;
        console.log('targetState',targetState,action.payload);
        if (targetState.flights?.items) {
          if (action.payload.deletedAt) {
            targetState.flights.items = targetState.flights.items.filter(
              ({ id }: any) => {
                return id !== action.payload.id;
              }
            );
          } else {
            const foundItemIndex = targetState.flights.items.findIndex(
              ({ id }: any) => id === action.payload.id
            );

            if (targetState.flights && foundItemIndex > -1) {
              targetState.flights.items[foundItemIndex] = action.payload;
            } else {
              targetState.flights?.items.push(action.payload);
            }
          }
        }
      };
      console.log('state.currentEvent?.id',state.currentEvent?.id,action.payload.eventId);
      console.log('state.currentGroup?.id',state.currentGroup?.id,action.payload.groupId);
      if (state.currentEvent && state.currentEvent?.id === action.payload.eventId) {
        actionUpdates('currentEvent');
      }

      if (state.currentGroup && state.currentGroup?.id === action.payload.groupId) {
        actionUpdates('currentGroup');
      }

      if (state.currentPerson && state.currentPerson?.id === action.payload?.slot?.personId) {
        actionUpdates('currentPerson');
      }
    },

    updateCurrentPersonFlights: (
      state,
      action: PayloadAction<ResponseType<Flight>>
    ) => {
      if (state.currentPerson) {
        state.currentPerson.flights = {
          ...state.currentPerson.flights,
          items: action.payload.items.map((item) => {
            if (item.slot?.person?.deletedAt) {
              item.slot.personId = null;
              item.slot.person = null;
            }
            if (item.slot?.deletedAt) {
              item.slotId = null;
              item.slot = null;
            }
            return item;
          }),
        };
      }
    },
    upsertAccommodations: (state, action: PayloadAction<Accommodation[]>) => {
      if (state.currentEvent?.accommodation) {
        state.currentEvent.accommodation.items = [
          ...state.currentEvent.accommodation.items,
          ...action.payload,
        ];
      }
      if (state.currentGroup?.accommodation) {
        state.currentGroup.accommodation.items = [
          ...state.currentGroup.accommodation.items,
          ...action.payload,
        ];
      }
    },
    updateCurrentPersonAccommodation: (
      state,
      action: PayloadAction<ResponseType<Accommodation>>
    ) => {
      if (state.currentPerson) {
        state.currentPerson.accommodation = {
          ...state.currentPerson.accommodation,
          items: action.payload.items.map((item) => {
            if (item.slot?.person?.deletedAt) {
              item.slot.personId = null;
              item.slot.person = null;
            }
            if (item.slot?.deletedAt) {
              item.slotId = null;
              item.slot = null;
            }
            return item;
          }),
        };
      }
    },

    updateCurrentGroupAccomodation: (
      state,
      action: PayloadAction<ResponseType<Accommodation>>
    ) => {
      if (state.currentGroup) {
        state.currentGroup.accommodation = action.payload;

        state.currentGroup.accommodation.items =
          state.currentGroup.accommodation.items.map((item) => {
            if (item.slot?.deletedAt) {
              item.slot = null;
              item.slotId = null;
            }
            return item;
          });
      }
    },
    upsertAccommodationItem: (
      state,
      action: PayloadAction<Optional<Accommodation>>
    ) => {
      const actionUpdates = (key: string) => {
        const targetState = (state as any)[key] as any;

        if (!targetState?.accommodation?.items) {
          targetState.accommodation = { items: [] };
        }

        if (action.payload.deletedAt) {
          targetState.accommodation.items =
            targetState.accommodation.items.filter(({ id }: any) => {
              return id !== action.payload.id;
            });
        } else {
          const foundItemIndex = targetState.accommodation.items.findIndex(
            ({ id }: any) => id === action.payload.id
          );

          if (targetState.accommodation && foundItemIndex > -1) {
            targetState.accommodation.items[foundItemIndex] = action.payload;
          } else {
            targetState.accommodation?.items.push(action.payload);
          }
        }
      };

      if (state.currentEvent && state.currentEvent?.id === action.payload.eventId) {
        actionUpdates('currentEvent');
      }

      if (state.currentGroup && state.currentGroup?.id === action.payload.groupId) {
        actionUpdates('currentGroup');
      }

      if (state.currentPerson && state.currentPerson?.id === action.payload?.slot?.personId) {
        actionUpdates('currentPerson');
      }


    },
  },
});

export const {
  updateAllEvents,
  updateEventMeta,
  updateTeam,
  upsertTeamMember,
  updatePeople,
  upsertPerson,
  upsertPeople,
  upsertFlights,
  updateCurrentPerson,
  updateCurrentPersonSlots,
  updateList,
  updateCurrentEventSlots,
  updateCurrentEventFlights,
  updateCurrentEventAccommodation,
  upsertAccommodations,
  updateSearchQuery,
  updateTableConfig,
  updateCurrentEvent,
  upsertSlotItem,
  updateCurrentGroup,
  updateCurrentGroupSlots,
  updateCurrentGroupFlights,
  updateCurrentGroupAccomodation,
  upsertFlightItem,
  upsertAccommodationItem,
  upsertGroupItem,
  updateCurrentPersonFlights,
  updateCurrentPersonAccommodation,
} = eventsSlice.actions;

export default eventsSlice.reducer;

import { API } from 'aws-amplify';
import { createDispatchHook } from 'react-redux';
import { Accommodation, Flight } from '../API';
import { v4 as uuidv4 } from 'uuid';

import {
  createFlight as createFlightMutation,
  updateFlight as updateFlightMutation,
  batchAddFlights as batchAddFlightsMutation,
} from '../graphql/mutations';
import { getFlight as getFlightQuery } from '../graphql/queries';
import { upsertFlightItem } from '../reducers/eventsSlice';
import { store } from '../store';
import { getAll } from '../utils/getAll';
import notifyBugsnag from '../utils/notifyBugsnag';
import { Optional } from '../globals';
import { addAccommodation, updateAccommodation } from './accommodation';
import moment from 'moment';

type FlightItem = Flight & {
  selectedRows?: Accommodation[];
  checkinDate?: string;
  checkoutDate?: string;
  createOrUpdateAccommodation?: 'create' | 'update';
  roomType?: string;
  notes?: string;
};
export async function addFlight(flightItem: Optional<FlightItem>) {
  try {
    console.log('addFlight', flightItem);
    const {
      selectedRows = [],
      checkinDate,
      checkoutDate,
      createOrUpdateAccommodation,
      roomType,
      notes,
      ...flight
    } = flightItem;
    const now = new Date().toISOString();
    // console.log('addFlight', flight);
    const response: any = await API.graphql({
      query: createFlightMutation,
      variables: {
        input: {
          ...flight,
          id: uuidv4(),
          createdBy: store.getState().user.profile.id,
          slot: undefined,
          group: undefined,
          copyToAccommodationDates: undefined,
          createdAt: now,
          updatedAt: now,
        },
      },
    });

    if (createOrUpdateAccommodation === 'create') {
      console.log('flight for accomo!', flight);
      await addAccommodation({
        checkinDate,
        checkoutDate,
        eventId: flight.eventId,
        slotId: flight.slotId,
        groupId: flight.groupId,
        roomType,
        notes,
        copiedFromFlightId: response.data.createFlight.id,
        slot: flight.slot,
      });
    } else if (selectedRows.length > 0) {
      // update
      selectedRows.map(async ({ id, slotId }) => {
        await updateAccommodation({
          id,
          checkinDate,
          checkoutDate,
          roomType,
          notes,
          copiedFromFlightId: response.data.createFlight.id,
          slot: flight.slot,
        });
      });
    }

    store.dispatch(upsertFlightItem({ ...flight, id: response.data.createFlight.id, updatedAt: now} as Flight));

    return response.data.createFlight;
  } catch (err) {
    console.log('error adding flight', err);
    notifyBugsnag(err);
  }
}

export async function updateFlight(flightItem: Optional<FlightItem>) {
  try {
    const {
      selectedRows = [],
      checkinDate,
      checkoutDate,
      createOrUpdateAccommodation,
      roomType,
      notes,
      ...flight
    } = flightItem;
    const now = new Date().toISOString();
    console.log('updateFlight', flightItem);

    const response: any = await API.graphql({
      query: updateFlightMutation,
      variables: {
        input: {
          ...flight,
          slot: undefined,
          group: undefined,
          copyToAccommodationDates: undefined,
          updatedAt: now,
          lastUpdatedBy: store.getState().user.profile.id,
          __typename: undefined,
        },
      },
    });

    if (createOrUpdateAccommodation === 'create') {
      console.log('flight for accomo!', flight);
      await addAccommodation({
        checkinDate,
        checkoutDate,
        eventId: flight.eventId,
        slotId: flight.slotId,
        groupId: flight.groupId,
        roomType,
        notes,
        copiedFromFlightId: flight.id,
        slot: flight.slot,
      });
    } else if (selectedRows.length > 0) {
      // update
      selectedRows.map(async ({ id, slotId }) => {
        await updateAccommodation({
          id,
          checkinDate,
          checkoutDate,
          roomType,
          notes,
          copiedFromFlightId: flight.id,
          slot: flight.slot,
        });
      });
    }

    store.dispatch(upsertFlightItem({ ...flight, updatedAt: now } as Flight));

    return response.data.updateFlight;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function removeFlight(flight: Flight) {
  try {
    const now = new Date().toISOString();

    return updateFlight({
      ...flight,
      deletedBy: store.getState().user.profile.id,
      deletedAt: now,
    });
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getFlight(id: string) {
  try {
    const response: any = await API.graphql({
      query: getFlightQuery,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        id,
      },
    });

    return response.data.getFlight;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function getFlights() {
  try {
    const items = await getAll({ query: 'listFlights' });

    return items;
  } catch (err) {
    notifyBugsnag(err);
  }
}

export async function batchAddFlights(flights: Optional<Flight>[]) {
  try {
    const now = new Date().toISOString();

    const response: any = await API.graphql({
      query: batchAddFlightsMutation,
      variables: {
        flights: flights.map((item) => ({
          ...item,
          createdBy: store.getState().user.profile.id,
        })),
      },
    });

    return response.data.batchAddFlights;
  } catch (err) {
    notifyBugsnag(err);
  }
}

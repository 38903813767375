import { PageHeader } from '@ant-design/pro-layout';
import { Col, Row, Button, Space, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.less';
import { PlusOutlined } from '@ant-design/icons';
import { Event, ProfileEvent } from '../../API';

import { BetaSchemaForm, FormInstance } from '@ant-design/pro-components';

import { useRef } from 'react';
import { defaultFormProps } from '../../utils/defaultFormProps';
import { addEvent, getEvents } from '../../methods/events';
import EventCard from '../../components/EventCard';
import { fields as eventFields } from '../../data/events';
import { API } from 'aws-amplify';
import { onUpdateAnyEvent, onUpdateProfile } from '../../graphql/subscriptions';
import { exportData } from '../../utils/exportData';

import { useAppSelector, useAppDispatch } from '../../hooks';
import {
  updateList,
  updateEventMeta,
} from '../../reducers/eventsSlice';


import {
  getGroupCountByEventId,
  getGroupsByEventId,
} from '../../methods/groups';
import { store } from '../../store';
import showEditActions from '../../utils/showEditActions';
import moment from 'moment';

const EventsScene: React.FC = React.memo(() => {

  const [exportLoading, setExportLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);

  const formRef = useRef<FormInstance>();
const profile = useAppSelector((state) => state.user?.profile);
  const events = useAppSelector(
    (state) =>
      state.user?.profile?.events?.items?.map((profileEvent: ProfileEvent) => ({
        ...profileEvent.event,
        groupCount:
          state.events?.eventMeta &&
          profileEvent?.event?.id &&
          state.events?.eventMeta[profileEvent?.event?.id]?.groupCount,
      })) || []
  );
  // const user = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  useEffect(() => {

    const getAllEvents = async () => {
      // const allEvents = await getEvents();

      // dispatch(
      //   updateList(
      //     allEvents.map((eventItem) => ({
      //       ...eventItem,
      //       groupCount:
      //         store.getState().events.list.find(({ id }) => id === eventItem.id)
      //           ?.groupCount || undefined,
      //     }))
      //   )
      // );



      events.forEach(async (event: Event) => {
        const groupCount = await getGroupCountByEventId(event.id);

        dispatch(updateEventMeta({ id: event.id, meta: { groupCount } }));
      });


    };
    getAllEvents();

   
  }, []);

  if (!profile) {
    return (
      <div className="spinContainer">
        <Spin />
      </div>
    );
  }

  const sortedEvents = [...events];
  sortedEvents.sort(
    (a, b) =>
      moment(a.startDate, 'DD/MM/YYYY HH:mm').valueOf() -
      moment(b.startDate, 'DD/MM/YYYY HH:mm').valueOf()
  );



  return (
    <PageHeader>
      <Space align="center">
        <h1 style={{ margin: 0, padding: 0, fontSize: '1.5em' }}>Events</h1>

        {showEditActions() && (
          <Button
            type="primary"
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
            onClick={() => {
              setFormModalVisible(true);
            }}
          />
        )}

        {/* <Button
          type="primary"
          size="large"
          loading={exportLoading}
          onClick={async () => {
            setExportLoading(true);

            
            setExportLoading(false);
          }}
        >
          Export
        </Button> */}

        {showEditActions() && (
          <BetaSchemaForm
            {...defaultFormProps(formRef, formLoading)}
            formRef={formRef}
            visible={formModalVisible}
            onVisibleChange={(isVisible: boolean) => {
              setFormModalVisible(isVisible);
            }}
            onFinish={async (values: Event) => {
              setFormLoading(true);
              await addEvent(values);

              setTimeout(() => {
                setFormModalVisible(false);
                setFormLoading(false);
                message.success('Event Created');
              }, 500);
            }}
            columns={eventFields({values:null, loading:formLoading}) as any}
          />
        )}
      </Space>
      <br />
      <br />
      <div className="site-card-wrapper">
        <Row gutter={[16, 16]}>
          {sortedEvents.map((event) => (
            <Col span={6} key={event.id} style={{ minWidth: 220 }}>
              <EventCard event={event} />
            </Col>
          ))}
        </Row>
      </div>
    </PageHeader>
  );
});

export default EventsScene;

import { ProFormColumnsType } from '@ant-design/pro-components';
import { Accommodation, Flight, Person, Profile, Slot } from '../API';
import type { ColumnsType } from 'antd/es/table';

import FormButton from '../components/FormButton';
import DeleteButton from '../components/DeleteButton';
import { removePerson, updatePerson } from '../methods/people';
import { DataType } from '../globals';
import { Link, useHistory } from 'react-router-dom';
import { Space, Tag } from 'antd';
import { removeTeamMember, updateTeamMember } from '../methods/team';
import showEditActions from '../utils/showEditActions';
import EventSelect from '../components/EventSelect';
import { getColumnSearchProps } from '../utils/getColumnSearchProps';

const STRING_TO_FORCE_TO_END = 'xxxxxxxxxxxxxxxxx';

type DataItem = {
  name: string;
  state: string;
};

type Props = {
  values: Profile | null;
  loading: boolean;
  data?: any;
  options?: any;
};

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  values,
  loading,
  data,
  options,
}) => [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.firstName,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.lastName,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.email,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    fieldProps: { placeholder: '', disabled: loading },
    valueType: 'select',
    valueEnum: {
      manager: { text: 'Manager' },
      editor: { text: 'Editor' },
      viewer: {
        text: 'Viewer',
      },
    },
    formItemProps: {
      initialValue: values?.role || 'viewer',
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Events',
    key: 'eventIds',
    fieldProps: {
      disabled: loading,
    },
    initialValue: values?.eventIds,
    renderFormItem: (schema, config) => <EventSelect {...schema} />,
    transform: (value, namePath, allValues) => {
      return { eventIds: value };
    },
    width: 'md',
    colProps: {
      xs: 24,
      md: 24,
    },
  },
];

const roles: any = {};
roles['editor'] = 'Editor';
roles['viewer'] = 'Viewer';
roles['manager'] = 'Manager';
roles['admin'] = 'Admin';

const roleColor: any = {
  admin: 'gold',
  manager: 'green',
  editor: 'blue',
  viewing: 'default',
};

export const columns: () => ColumnsType<DataType> = () => {
  const items: ColumnsType<DataType> = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      ...getColumnSearchProps(['firstName'], 'First Name'),
      sorter: (a: any, b: any) =>
        (a.firstName || STRING_TO_FORCE_TO_END).localeCompare(
          b.firstName || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      ...getColumnSearchProps(['lastName'], 'Last Name'),

      sorter: (a: any, b: any) =>
        (a.lastName || STRING_TO_FORCE_TO_END).localeCompare(
          b.lastName || STRING_TO_FORCE_TO_END
        ),
      width: 150,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      ...getColumnSearchProps(['email'], 'Email Address'),

      sorter: (a: any, b: any) =>
        (a.email || STRING_TO_FORCE_TO_END).localeCompare(
          b.email || STRING_TO_FORCE_TO_END
        ),
      render(email) {
        return email ? <a href={`mailto:${email}`}>{email}</a> : null;
      },
      width: 250,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      ...getColumnSearchProps(['role'], 'Role'),

      sorter: (a: any, b: any) =>
        (a.role || STRING_TO_FORCE_TO_END).localeCompare(
          b.role || STRING_TO_FORCE_TO_END
        ),
      width: 100,
      render(item) {
        return <Tag color={roleColor[item]}>{roles[item]}</Tag>;
      },
    },
    {
      title: 'Events',
      dataIndex: 'events',
      ...getColumnSearchProps(['events'], 'Events'),

      sorter: (a: any, b: any) =>
        (a.event || STRING_TO_FORCE_TO_END).localeCompare(
          b.event || STRING_TO_FORCE_TO_END
        ),
      // width: 100,
      render(renderProps) {
        if (renderProps?.items) {
          return renderProps.items.map((item: any) => (
            <Tag key={item.id}>
              <Link to={`/events/${item.event.id}/groups`}>
                {item.event.title}
              </Link>
            </Tag>
          ));
        }
        return null;
      },
    },
  ];

  if (showEditActions()) {
    items.push({
      key: 'operation',
      fixed: 'right',
      width: 102,
      render: (item) => {
        return (
          <Space style={{ float: 'right' }}>
            <FormButton
              fields={fields}
              item={item}
              successMessage="Team member updated"
              action={updateTeamMember}
              disabled={item.id === '003c677d-b140-44b9-b683-a1c595d4a467'}
            />
            <DeleteButton
              item={item}
              successMessage="Team member removed"
              action={removeTeamMember}
              disabled={item.id === '003c677d-b140-44b9-b683-a1c595d4a467'}
            />
          </Space>
        );
      },
    });
  }

  return items;
};

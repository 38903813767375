import React, { ReactElement, useState } from 'react';

import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Space,
  Row,
  Col,
  Typography,
  Button,
  Card,
  message,
  Breadcrumb,
} from 'antd';
import { Auth } from 'aws-amplify';
import { getTeamMember } from '../../methods/team';
import { HomeOutlined } from '@ant-design/icons';

import passwordStrengthRule, {
  buildPasswordErrorMessages,
  passwordIconRender,
} from '../../utils/passwordStrengthRule';

const { Title } = Typography;

type NewPasswordValues = {
  newPassword: string;
  confirmPassword: string;
};

type LocationState = {
  from: {
    pathname: string;
  };
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const NewPasswordScene: React.FC = ({ match }: any): ReactElement => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [passwordError, setPasswordError] = useState([]);

  const parts = match.params.token.split('-');
  const code = parts.pop();
  const userName = parts.join('-');

  let locationToUse: LocationState = {
    from: {
      pathname: '/',
    },
  };

  if (location.state) {
    locationToUse = location.state;
  }
  const onFinish = async (values: NewPasswordValues) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(userName, code, values.newPassword);

      setLoading(false);
      message.success('Password Reset');
      history.push('/auth/login');
    } catch (e: any) {
      setLoading(false);
      message.error(e.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="container">
      <Row justify="center">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          <div
            style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}
          ></div>
          <Row>
            <Col span={24}>
              <div style={{ marginBottom: 16, marginLeft: 16, marginTop: 16 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/auth/login">
                      <HomeOutlined />
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Create New Password</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Create New Password
                  </Title>
                  <p>
                    Please create a strong password that&apos;s unique to your
                    Goose account.
                  </p>
                </div>

                <Form
                  {...layout}
                  name="basic"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    required
                    rules={[
                      passwordStrengthRule((err: any) => {
                        setPasswordError(err);
                      }),
                    ]}
                    help={buildPasswordErrorMessages(
                      passwordError,
                      form.isFieldTouched('newPassword')
                    )}
                  >
                    <Input.Password iconRender={passwordIconRender} />
                  </Form.Item>

                  <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    dependencies={['newPassword']}
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue('newPassword') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            'The two passwords that you entered do not match!'
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password iconRender={passwordIconRender} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Space size="middle" wrap>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewPasswordScene;

import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../hooks';
import { getAll } from '../utils/getAll';
import { get, set } from 'lodash';
import moment from 'moment';
import notifyBugsnag from '../utils/notifyBugsnag';

var enumerateDaysBetweenDates = function (startDate: any, endDate: any) {
  var now = startDate.clone(),
    dates = [];

  while (now.isBefore(endDate,'day')) {
    dates.push(now.format('M/D/YYYY'));
    now.add(1, 'days');
  }
  return dates;
};

type ChildrenProps = {
  loading: boolean;
  data: any;
};

type Props = {
  children: (props: ChildrenProps) => any;
  query: string;
  variables?: any;
  keysToWatch?: string[];
};
const DataProvider: React.FC<Props> = ({
  children,
  query,
  variables,
  keysToWatch = [],
}) => {


  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>(null);

  const fetchData = async () => {
    try {
      let hasValue = false;
      for(const key in variables) {
        if (variables[key] !== undefined) {
          hasValue = true;
        }
      }
      if (hasValue) {
        const remoteItems: any = await getAll({
          query,
          variables,
        });

        setItems(remoteItems);
      } else {
        setItems([]);
      }
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      notifyBugsnag(e);
    }
  };

  useEffect(
    () => {
      setLoading(true);
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    keysToWatch.map((key) => variables[key])
  );

  // do accommodation matsh here?

  return children({
    loading,
    data: items,
  });
};

export default DataProvider;

import { API } from 'aws-amplify';

import axios from 'axios';
function s2ab(s: any) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

var saveByteArray = (function () {
  var a: any = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (data: any, name: any) {
    var blob = new Blob([s2ab(window.atob(data))], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

type ExportData = {
  pages: any[];
  fileName: string;
  isTransferList?: boolean;
  leavingFrom?: string;
  arrivingAt?: string;
};
export const exportData = async ({ fileName, ...body }: ExportData) => {
  const response = await API.post('GooseRest', `/export`, {
    body,
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });

  saveByteArray([response.body], fileName);
};

// export const exportTransferData = async (data: any, fileName: string) => {
//   const response = await API.post('GooseRest', `/export/transfer-list`, {
//     body: {
//       data,
//     },
//     headers: {
//       Accept:
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     },
//   });

//   saveByteArray([response.body], fileName);
// };

export const exportAccommodationData = async (
  pages: any[],
  fileName: string
) => {
  const response = await API.post('GooseRest', `/export/accommodation`, {
    body: {
      pages,
    },
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });

  // const response: any = await axios.post(
  //   'http://localhost:3003/export',
  //   {
  //     pages
  //   },
  //   {
  //     headers: {
  //       Accept:
  //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     },
  //   }
  // );
  saveByteArray([response.body], fileName);
};

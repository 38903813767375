import { API } from 'aws-amplify';
import { Query } from '../globals';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql/customQueries';



const querieItems: any = queries;
const customQueriesItems: any = customQueries;
export const getAll = async ({ query, variables }: Query) => {
  let allItems: any = [];

  const getItemBatch: (nextToken?: string) => any = async (nextToken) => {
 
    const items: any = await API.graphql({
      query: customQueriesItems[`${query}Custom`] || querieItems[query],
      variables: {
        nextToken,
        ...variables
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });


    allItems = [...allItems, ...items.data[query].items];
    if (items.data[query].nextToken) {
      return await getItemBatch(items.data[query].nextToken);
    }
  };

  await getItemBatch();

  return allItems.filter((o: any) => !o.deletedAt);
};

const showEditActions = () => {

  const user = localStorage.getItem('goose:user')
    ? JSON.parse(localStorage.getItem('goose:user') as string)
    : null;
  if (user) {
    if (user.role) {
      return ['admin', 'manager', 'editor'].includes(user.role);
    }
  }

  return false;
};

export default showEditActions;

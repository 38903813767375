import { ProFormColumnsType } from '@ant-design/pro-components';
import moment from 'moment';
import { Group } from '../API';

type DataItem = {
  name: string;
  state: string;
};


type Props = {
  values?: Group | null,
  loading: boolean,
  data?: any,
  options?: any
}

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({values, loading, data, options}) => [
  {
    title: 'Group Title',
    dataIndex: 'title',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.title,
      rules: [
        {
          required: true,
        },
      ],
    },
  }
];

import { Segmented } from 'antd';
import './styles.less';

import { ViewType } from '../../globals';
import { BarsOutlined, BlockOutlined } from '@ant-design/icons';

type Props = {
  value: ViewType;
  onChange?: (newView: ViewType) => void;
  disabled?: boolean;
};

const ViewPicker: React.FC<Props> = ({ value = 'all', onChange }) => {
  return (
    <>
      <Segmented
        value={value}
        options={[
          {
            label: <span className="view-picker">All</span>,
            value: 'all',
            icon: <BarsOutlined />,
          },
          {
            label: <span className="view-picker">Grouped</span>,
            value: 'grouped',
            icon: <BlockOutlined />,
          },
        ]}
        onChange={onChange}
      />
    </>
  );
};

ViewPicker.defaultProps = {};

export default ViewPicker;

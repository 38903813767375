import { ProFormColumnsType } from '@ant-design/pro-components';
import moment from 'moment';
import { Event } from '../API';
import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

type DataItem = {
  name: string;
  state: string;
};

type Props = {
  values: Event | null;
  loading: boolean;
  data?: any;
  options?: any;
};

export const fields: (props: Props) => ProFormColumnsType<DataItem>[] = ({
  values,
  loading,
  data,
  options,
}) => [
  {
    title: 'Title',
    dataIndex: 'title',
    fieldProps: { placeholder: '', disabled: loading },
    formItemProps: {
      initialValue: values?.title,
      rules: [
        {
          required: true,
        },
      ],
    },
  },
  {
    title: 'Date / Time',
    key: 'dateTime',
    initialValue: values?.startDate
      ? [
          moment(values?.startDate, 'DD/MM/YYYY HH:mm').second(0),
          moment(values?.endDate, 'DD/MM/YYYY HH:mm').second(0),
        ]
      : null,
    renderFormItem: () => (
      <MyDatePicker.RangePicker
        showTime
        placeholder={['Start', 'End']}
        format={'DD/MM/YYYY HH:mm'}
      />
    ),
    width: 'md',
    colProps: {
      xs: 24,
      md: 12,
    },
    transform: (value) => {
      return {
        startDate: moment(value[0]).second(0).format('DD/MM/YYYY HH:mm'),
        endDate: moment(value[1]).second(0).format('DD/MM/YYYY HH:mm'),
      };
    },
  },
];
